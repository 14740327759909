import { useState } from 'react';

import UpgradeToCofeeModal from '@features/subscription/components/UpgradeToCofeeModal';
import { Icon, Modal } from '@components';
import { OrgStatus } from '@constants/generic';
import { Event, sendMixpanelEvent } from '@core/analytics';
import { getLocalStorageItem } from '@utils/storage';

import theme from '../../../../../themes/colors';

interface KycInfoToastProps {
  status: OrgStatus;
}

const KycInfoToast = ({ status }: KycInfoToastProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onClick = () => {
    setIsModalOpen(true);
    sendMixpanelEvent(Event.ADD_KYC_INFO_CLICKED, {
      user_id: getLocalStorageItem('userId'),
      organizationId: getLocalStorageItem('organizationId')
    });
  };

  return (
    <>
      {status === OrgStatus.VERIFICATION_PENDING && (
        <div className="w-full rounded-xl border-l-4 border-l-warningYellow bg-white p-4 shadow-sm">
          <div
            className="flex cursor-pointer items-center gap-1"
            onClick={onClick}
            role="presentation"
          >
            <div className="text-base text-warningYellow">Add KYC Info</div>
            <Icon name="forwardYellow" />
          </div>
          <div className="mt-1 text-sm text-grey">
            Add KYC Details to activate created groups
          </div>
        </div>
      )}
      {status === OrgStatus.VERIFICATION_SUBMITTED && (
        <div className="mt-6 flex flex-col gap-1 rounded-lg border-l-8 border-l-warningYellow bg-white p-4 shadow-sm">
          <div className="flex items-center gap-2">
            <Icon
              name="alert-circle"
              stroke={theme.yellow40}
              data-tooltip-id="kyc-pending-info-icon"
              className="!size-4"
            />
            <div className="text-sm font-medium text-yellow40">
              Verification Pending
            </div>
          </div>
          <div className="text-sm text-grey">
            We will notify you once the verification is completed.
          </div>
        </div>
      )}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          isCloseIconRequired
          className="min-w-[540px] p-6"
        >
          <UpgradeToCofeeModal onboardingType="Rapid Onboard" />
        </Modal>
      )}
    </>
  );
};

export default KycInfoToast;
