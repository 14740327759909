import { useDispatch, useSelector } from 'react-redux';

import { isInvalidIfscCode } from '@utils/validations';
import { getLocalStorageItem } from '@utils/storage';
import { capitalizeFirstLetter } from '@utils/stringFormat';
import { useVerifyBankDetailsMutation } from '@features/authentication/queries/AuthQuery';
import { getOrganizationId } from '@features/authentication/utils/common';
import { updateAccountInfo } from '@features/authentication/slices/AuthSlice';
import { Event, sendMixpanelEvent } from '@core/analytics';
import { FlowLabels } from '@constants/generic';
import { ApiError, FieldError } from '@types';

import { BankInfoType, VerifiedBankInfoType } from '../types';

interface VerifyBankProps {
  bankInfo: BankInfoType;
  setFieldError: (fieldError: FieldError) => void;
  setVerifiedName: (verifiedName: VerifiedBankInfoType) => void;
  setShowConfirmationModal?: (show: boolean) => void;
  setIsVerified?: (isVerified: boolean) => void;
}

const useVerify = () => {
  const dispatch = useDispatch();

  const [verifyBankDetails, { isLoading: isVerifyBankLoading }] =
    useVerifyBankDetailsMutation();

  const userFlow = getLocalStorageItem('userFlow');
  const accountInfo = useSelector((state: any) => state.auth.accountInfo);

  const handleVerifyBank = async ({
    bankInfo,
    setFieldError,
    setVerifiedName,
    setShowConfirmationModal,
    setIsVerified
  }: VerifyBankProps) => {
    if (bankInfo.accountNumber !== bankInfo.confirmAccountNumber) {
      setFieldError({
        field: 'accountNumber',
        message: 'Account number mismatch'
      });
      return;
    }
    if (isInvalidIfscCode(bankInfo.ifsc)) {
      setFieldError({
        field: 'ifsc',
        message: 'Invalid IFSC Code'
      });
      return;
    }
    if (bankInfo.accountNumber === bankInfo.confirmAccountNumber) {
      const response = await verifyBankDetails({
        userId: getLocalStorageItem('userId'),
        body: {
          organisation_id: getOrganizationId(userFlow),
          ifsc: bankInfo.ifsc.toUpperCase().trim(),
          account_number: bankInfo.accountNumber.trim(),
          branch_id: undefined
        }
      });
      if ('data' in response) {
        const {
          bank_account_name: bankAccountName,
          bank_address: bankAddress
        } = response?.data || {};
        dispatch(
          updateAccountInfo({
            ...accountInfo,
            verifiedUserName: bankAccountName,
            verifiedBankName: bankAddress,
            accountDetailId: response?.data?.account_detail_id
          })
        );
        setVerifiedName({
          bankName: bankAddress,
          userName: bankAccountName,
          bankAccountNumber: response?.data?.bank_account_number,
          bankIfsc: response?.data?.bank_ifsc,
          bankLogo: response?.data?.bank_logo,
          accountDetailId: response?.data?.account_detail_id
        });
        setIsVerified?.(true);
        setShowConfirmationModal?.(true);

        sendMixpanelEvent(Event.BANK_ACCOUNT_VERIFICATION_SUCCESS, {
          verified_name: bankAccountName,
          verified_bank_name: bankAddress,
          flow: FlowLabels[userFlow]
        });
      } else if ('error' in response) {
        const error = response.error as ApiError;
        setFieldError({
          field: 'verify',
          message: capitalizeFirstLetter(
            error.data?.message || 'Error in verifying bank details'
          )
        });
        setIsVerified?.(false);
        sendMixpanelEvent(Event.BANK_ACCOUNT_VERIFICATION_ERROR, {
          error: error.data?.errorCode,
          flow: FlowLabels[userFlow]
        });
      }
    }
  };

  return {
    handleVerifyBank,
    isVerifyBankLoading
  };
};

export default useVerify;
