import { Skeleton } from '@mui/material';

import { Icon, InfoIconTooltip } from '@components';
import formatCurrency from '@utils/numberFormater';

import theme from '../../../../../themes/colors';

interface DashboardCardProps {
  title: string;
  value: number;
  icon: string;
  growthValue?: number;
  className?: string;
  tooltipValue?: string;
  isLoading?: boolean;
  showGrowthValue: boolean;
}

const DashboardCard = ({
  title,
  value,
  icon,
  growthValue,
  className,
  tooltipValue,
  isLoading,
  showGrowthValue
}: DashboardCardProps) => {
  const iconName = growthValue && growthValue < 0 ? 'decreasing' : 'increasing';

  const changeInPercentage = () =>
    isLoading ? (
      <Skeleton
        height={32}
        width={80}
        key={Math.random()}
        animation="wave"
        variant="text"
      />
    ) : (
      <div
        className={`flex items-center rounded-xl px-3 py-1 text-sm ${
          growthValue && growthValue < 0
            ? 'bg-errorLite text-error'
            : 'bg-primaryLite9 text-primary90'
        }`}
      >
        <Icon
          name={iconName}
          size="medium"
          className="mr-1 !w-3 min-w-4"
          key={iconName}
        />
        <span>{growthValue && Math.abs(growthValue)}%</span>
      </div>
    );

  return (
    <div className={`rounded-xl bg-white p-4 ${className}`}>
      <div className="mb-4 flex items-center gap-3">
        {icon && <Icon name={icon} size="none" className="size-8" />}
        <p className="text-lg font-medium text-content">{title}</p>
        <div>
          {tooltipValue && (
            <InfoIconTooltip
              id={title.replace(/\s+/g, '-')}
              tooltipText={tooltipValue}
              classname="max-w-400"
            />
          )}
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center ">
          <Icon
            name="rupee"
            size="24"
            className="mr-1 size-3"
            fill={theme.content}
          />
          {isLoading ? (
            <Skeleton
              height={32}
              width={80}
              key={Math.random()}
              animation="wave"
              variant="text"
            />
          ) : (
            <h2 className="text-3xl font-bold leading-8 text-content ">
              {formatCurrency(+value, 'INR')}
            </h2>
          )}
        </div>
        {showGrowthValue && changeInPercentage()}
      </div>
    </div>
  );
};

export default DashboardCard;
