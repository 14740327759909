type CurrencyConfig = {
  locale: string;
  currency: string;
  minimumFractionDigits: number;
  maximumFractionDigits: number;
};

const currencyConfigs: Record<string, CurrencyConfig> = {
  INR: {
    locale: 'en-IN',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  },
  USD: {
    locale: 'en-US',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  },
  EUR: {
    locale: 'de-DE',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  },
  JPY: {
    locale: 'ja-JP',
    currency: 'JPY',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  },
  GBP: {
    locale: 'en-GB',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
};

const formatIndianCurrency = (amount: number): string => {
  if (amount == null) {
    return '0';
  }

  const [wholePart, decimalPart] = amount.toFixed(2).split('.');
  const lastThree = wholePart.slice(-3);
  const otherNumbers = wholePart.slice(0, -3);
  const formattedWholePart =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
    (otherNumbers ? ',' : '') +
    lastThree;
  return `${formattedWholePart}${
    decimalPart !== '00' ? `.${decimalPart}` : ''
  }`;
};

const formatCurrency = (amount: number, currencyCode: string): string => {
  if (currencyCode === 'INR') {
    return formatIndianCurrency(amount);
  }

  const config = currencyConfigs[currencyCode] || currencyConfigs.INR;
  const formatter = new Intl.NumberFormat(config.locale, {
    style: 'decimal',
    minimumFractionDigits: config.minimumFractionDigits,
    maximumFractionDigits: config.maximumFractionDigits
  });

  return formatter.format(amount);
};
export default formatCurrency;
