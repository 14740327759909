const SandboxBanner = () => (
  <div className="fixed left-0 top-16 z-40 flex h-14 w-screen items-center justify-center gap-3 bg-yellow10 text-grey">
    <div className="bg-warning500 rounded-md px-6 py-1.5 font-bold text-darkGrey">
      TEST MODE
    </div>
    You are currently in test mode. Transactions made in this environment are
    for testing purposes only
  </div>
);

export default SandboxBanner;
