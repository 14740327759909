import { useEffect, useState } from 'react';

import RadioGroup from '@components/radio-button/RadioGroup';
import { PaymentCollectionType } from '@features/group-management/constants';
// import { getDayType } from '@features/group-management/helper';
// import { getLastDayOfMonthUTC } from '@utils/date';
import { CardProps } from 'types/payment-options';

import { monthCardOptions, MonthDay } from '../constants';
// import { handleMonthDateSelect } from '../helper';

const MonthCard = ({
  handleActivationDate,
  selectedDate,
  type,
  handleIntervalFrequency
}: // isPaymentLinkSent
CardProps) => {
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [selectedType, setSelectedType] = useState('');

  const handleDayClick = (item: string) => {
    setSelectedType(MonthDay.CUSTOM);
    setSelectedDay(item);
    // const { nextDueDate, isFutureDate } = handleMonthDateSelect({
    //   item,
    //   isPaymentLinkSent
    // });
    // handleActivationDate(new Date(nextDueDate), isFutureDate);
    handleActivationDate(item);
    handleIntervalFrequency('1');
  };

  const handleTypeSelect = (item: string) => {
    setSelectedType(item);
    if (item === MonthDay.CUSTOM) {
      setSelectedDay('1');
      // const { nextDueDate, isFutureDate } = handleMonthDateSelect({
      //   item: '1',
      //   isPaymentLinkSent
      // });
      // handleActivationDate(new Date(nextDueDate), isFutureDate);
      handleActivationDate('1');
      handleIntervalFrequency('1');
    } else setSelectedDay('');
    if (item === MonthDay.LAST) {
      // const lastDay = getLastDayOfMonthUTC();
      // handleActivationDate(lastDay);
      handleActivationDate('last_day');
      handleIntervalFrequency('1');
    }
    if (item === MonthDay.FIRST) {
      // const { nextDueDate, isFutureDate } = handleMonthDateSelect({
      //   item: '1',
      //   isPaymentLinkSent
      // });
      // handleActivationDate(new Date(nextDueDate), isFutureDate);
      handleActivationDate('first_day');
      handleIntervalFrequency('1');
    }
  };

  useEffect(() => {
    if (!selectedDate || type !== PaymentCollectionType.MONTHLY) {
      setSelectedType(MonthDay.FIRST);
      handleActivationDate('first_day');
      handleIntervalFrequency('1');

      // const { nextDueDate, isFutureDate } = handleMonthDateSelect({
      //   item: '1',
      //   isPaymentLinkSent
      // });
      // handleActivationDate(new Date(nextDueDate), isFutureDate);
      // handleIntervalFrequency('');
    } else if (selectedDate && type === PaymentCollectionType.MONTHLY) {
      // const dayType = getDayType(selectedDate);
      // setSelectedType(dayType);
      if (selectedDate === 'first_day') {
        setSelectedType(MonthDay.FIRST);
        handleActivationDate('first_day');
        handleIntervalFrequency('1');
      } else if (selectedDate === 'last_day') {
        setSelectedType(MonthDay.LAST);
        handleActivationDate('last_day');
        handleIntervalFrequency('1');
      } else {
        setSelectedType(MonthDay.CUSTOM);
        handleActivationDate(selectedDate as string);
        handleIntervalFrequency('1');
        setSelectedDay(selectedDate as string);
      }
      // if (dayType === MonthDay.CUSTOM) {
      //   const day = new Date(selectedDate).getDate();
      //   setSelectedDay(String(day));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <>
      <RadioGroup
        options={monthCardOptions}
        handleChange={handleTypeSelect}
        orientation="vertical"
        selectedValue={selectedType}
      />
      <div className="grid grid-cols-7 gap-2 pb-6 pt-1">
        {Array.from({ length: 28 }, (_, i) => (
          <div
            key={i}
            className={`flex size-8 cursor-pointer items-center justify-center rounded-full text-content ${
              Number(selectedDay) === i + 1 ? 'bg-primary text-theme' : ''
            }`}
            role="presentation"
            onClick={() => handleDayClick(String(i + 1))}
          >
            {i + 1}
          </div>
        ))}
      </div>
    </>
  );
};

export default MonthCard;
