import { EventStatus } from '@types';

export const supportedFileFormats = ['JPEG', 'PNG', 'PDF', 'Doc'] as const;

export type FileFormat = (typeof supportedFileFormats)[number];

export enum QuestionType {
  TEXT = 'text',
  TEXT_AREA = 'text_area',
  NUMBER = 'number',
  SINGLE_CHOICE = 'single_choice',
  MULTIPLE_CHOICE = 'multiple_choice',
  DROP_DOWN = 'drop_down',
  DATE = 'date',
  FILE_UPLOAD = 'file_upload',
  MOBILE_NUMBER = 'mobile_number'
}

export type QuestionMetaData = {
  [QuestionType.TEXT]: null;
  [QuestionType.TEXT_AREA]: null;
  [QuestionType.NUMBER]: null;
  [QuestionType.DATE]: null;
  [QuestionType.DROP_DOWN]: string[];
  [QuestionType.MULTIPLE_CHOICE]: string[];
  [QuestionType.SINGLE_CHOICE]: string[];
  [QuestionType.FILE_UPLOAD]: {
    fileTypes: FileFormat[];
    size: number;
  };
  [QuestionType.MOBILE_NUMBER]: null;
};

export type AnswerType = {
  [QuestionType.TEXT]: string;
  [QuestionType.TEXT_AREA]: string;
  [QuestionType.NUMBER]: number;
  [QuestionType.DATE]: Date;
  [QuestionType.DROP_DOWN]: string;
  [QuestionType.MULTIPLE_CHOICE]: string[];
  [QuestionType.SINGLE_CHOICE]: string;
  [QuestionType.FILE_UPLOAD]: File;
  [QuestionType.MOBILE_NUMBER]: string;
};

export type Question = {
  id: string;
  question: string;
  type: QuestionType;
  required: boolean;
  questionMetaData?: QuestionMetaData[QuestionType];
  answer?: AnswerType[QuestionType];
  isDefault?: boolean;
  isDeleted?: boolean;
  displayOrder: number;
};

export interface BasicDetailsFormData {
  title: string;
  imageUrl?: string;
  description: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

export interface OtherDetailsFormData {
  venueName: string;
  addressLine1: string;
  addressLine2?: string;
  state: string;
  city: string;
  pincode: string;
  locationLink?: string;
  additionalDocUrl?: string;
  name: string;
  email?: string;
  primaryNumber: string;
  alternateNumber?: string;
}

export interface Ticket {
  id?: string;
  isActive: boolean;
  name: string;
  price: string;
  description?: string;
}

export interface EventData {
  basicDetails: BasicDetailsFormData;
  otherDetails: OtherDetailsFormData;
  tickets: Ticket[];
  questions: Question[];
  registrationCloseDate: string;
  registrationCloseTime: string;
}

export interface EventTicket extends Ticket {
  ticketId: string;
  isActive: boolean;
  seatsBooked: number;
}

export interface EventDetails {
  eventId: string;
  name: string;
  imageUrl: string;
  venueName?: string;
  venueCity: string;
  registrationEndDateTime: string;
  startDateTime: string;
  endDateTime: string;
  formLink: string;
  totalRegistrations: number;
  totalTicketSold: number;
  totalRevenue: string;
  tickets: EventTicket[];
  status: EventStatus;
}
export interface AttendeeDetails {
  questions: Question[];
}

export interface EventFormSubmission {
  fieldName: string;
  fieldValue: string;
  fieldType: QuestionType;
  isRequired: boolean;
}
