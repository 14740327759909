/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Avatar, Icon, Modal, ProfileSettings } from '@components';
import { ErrorCode } from '@constants/errorCodes';
import {
  AccountType,
  currentOrgAvatarColor,
  OrgStatus,
  SettlementMode,
  SubscriptionPlanExpiredMessage,
  SubscriptionStatus
} from '@constants/generic';
import UserPermissionTypes from '@constants/permissions';
import {
  AUTH,
  BRANCHES,
  EVENTS,
  GROUPS,
  MEMBERS,
  SUBSCRIPTIONS
} from '@constants/routes';
import { PROFILE_ITEM_PATHS } from '@constants/sideMenu';
import testId from '@constants/testId';
import { useLazyAppConfigQuery } from '@features/authentication/queries/AuthQuery';
import {
  useCreateBranchMutation,
  useLazyGetOrgAccountDetailsQuery,
  useOnboardBranchMutation
} from '@features/branch-management/queries';
import {
  useLazyGetOrganizationConfigQuery,
  useLazyGetOrgFeatureFlagsConfigQuery
} from '@features/group-management/queries/GroupQuery';
import useNotify from '@hooks/notify';
import AccountModals from '@layouts/side-nav-bar/AccountModals';
import { ApiError, BranchForm, ImageForm, ValidationError } from '@types';
import { getNameErrorMessage, isMinLengthSatisfied } from '@utils/generic';
import useOnClickOutside from '@utils/hooks';
import { buildQueryString } from '@utils/queryStringBuilder';
import { getLocalStorageItem, setLocalStorageItem } from '@utils/storage';
import { capitalizeFirstLetter } from '@utils/stringFormat';
import { Org } from 'types/organization';

import { updateBranchFilter } from './branchSlice';
import BranchSelection from './components/BranchSelection';
import CreateBranchModal from './components/CreateBranchModal';
import EditOrganization from './components/EditOrganization';
import Profile from './components/Profile';
import {
  setAccountType,
  setAppConfig,
  setCurrentOrg,
  setOrgConfig,
  setOrgFeatureFlags
} from './userSlice';

interface MainHeaderProps {
  orgList: Org[];
  subscriptionData: any;
}

const MainHeader = (props: MainHeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createErrorAlert, createSuccessAlert } = useNotify();
  const location = useLocation();
  const { pathname: currentPath } = useLocation();
  const ref = useRef(null);
  const { rolesAndPermissions } = useSelector((state: any) => state.user);
  const { currentSubscription } = useSelector((state: any) => state.user);

  const { orgList, subscriptionData } = props;

  const organizationId = getLocalStorageItem('organizationId');

  const [error, setError] = useState<ValidationError>({});

  const [showUserSettings, setShowUserSettings] = useState<boolean>(false);
  const [showOrganizationDetailsModal, setShowOrganizationDetailsModal] =
    useState<boolean>(false);
  const [accountMethods, setAccountMethods] = useState<any>(null);
  const [createBranchModalOpen, setCreateBranchModalOpen] =
    useState<boolean>(false);

  const [formData, setFormData] = useState<BranchForm>({
    branchName: '',
    settlementMode: SettlementMode.BANK
  });

  const [createBranch, { data: branchCreationData }] =
    useCreateBranchMutation();

  const [getAccountDetails, { data: accountDetails }] =
    useLazyGetOrgAccountDetailsQuery();

  const [onboardBranch] = useOnboardBranchMutation();

  const { currentUser } = useSelector((state: any) => state.user);

  const token = getLocalStorageItem('token');
  const orgId = getLocalStorageItem('organizationId');
  const currentOrg = orgList?.find((org: Org) => org.id === orgId);

  const [getOrgConfig] = useLazyGetOrganizationConfigQuery();
  const [getAppConfig] = useLazyAppConfigQuery();
  const [getOrgFeatureFlags] = useLazyGetOrgFeatureFlagsConfigQuery();

  const { currentBranchFilter } = useSelector((state: any) => state.branch);

  const branchName =
    currentBranchFilter?.branchName || getLocalStorageItem('branchName');

  const isProfileList = PROFILE_ITEM_PATHS.some((path) =>
    currentPath.startsWith(path)
  );

  const showBranchView =
    !isProfileList &&
    (currentOrg?.status === OrgStatus.APPROVED ||
      (currentOrg?.status === OrgStatus.KYC_PENDING &&
        !currentOrg?.is_kyc_deferred)) &&
    currentOrg?.has_plan;

  const [selectedBranch, setSelectedBranch] = useState<string>(branchName);

  const handleBranchChange = (
    selectedBranchId: string,
    selectedBranchName: string
  ) => {
    if (selectedBranchId) {
      setLocalStorageItem('branchId', selectedBranchId);
      setLocalStorageItem('branchName', selectedBranchName);
      dispatch(
        updateBranchFilter({
          branchId: selectedBranchId,
          branchName: selectedBranchName
        })
      );

      if (/^\/groups\/[^/]+/.test(location.pathname)) {
        navigate(GROUPS.HOME);
      } else if (/^\/events\/[^/]+/.test(location.pathname)) {
        navigate(EVENTS.HOME);
      } else if (/^\/members\/[^/]+/.test(location.pathname)) {
        navigate(MEMBERS.MEMBERS);
      }
    }
  };

  const showModal =
    showOrganizationDetailsModal &&
    currentOrg?.type === AccountType.ORGANIZATION;

  const onCancelModal = () => setShowOrganizationDetailsModal(false);

  const getOrgConfigData = async () => {
    const payload = {
      organizationId: currentOrg?.id || getLocalStorageItem('organizationId')
    };
    const resp = await getOrgConfig(payload);
    if ('data' in resp) {
      dispatch(setOrgConfig(resp.data));
    }
  };

  const getOrgFeatureFlagsData = async () => {
    const payload = {
      organizationId: currentOrg?.id || getLocalStorageItem('organizationId')
    };
    const resp = await getOrgFeatureFlags(payload);
    if ('data' in resp) {
      dispatch(setOrgFeatureFlags(resp.data));
    }
  };

  const getAppConfigData = async () => {
    const resp = await getAppConfig(null);
    if ('data' in resp) {
      dispatch(setAppConfig(resp.data));
    }
  };

  const { currentOrg: currentStoredOrg } = useSelector(
    (state: any) => state.user
  );

  useOnClickOutside(ref, (e) => {
    e.stopPropagation();
    setShowUserSettings(false);
  });

  const handleDropDownClick = () => {
    setShowUserSettings((prev) => !prev);
  };

  const isIndividualLite = (org: Org) =>
    org?.status === OrgStatus.KYC_PENDING &&
    !org?.has_plan &&
    !org?.is_kyc_deferred &&
    org?.type === AccountType.INDIVIDUAL;

  const onChangeForm = (field: string, value: string | ImageForm) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const onChangeBranchName = (value: string) => {
    setError({ name: '' });
    onChangeForm('branchName', value);
  };

  const onBlurBranchName = (value: string) => {
    if (!isMinLengthSatisfied(value)) setError({ name: getNameErrorMessage() });
  };

  const handleGetAccountDetails = async (id: string) => {
    const res = await getAccountDetails({
      organizationId,
      queryString: buildQueryString({
        branch_id: id || branchCreationData?.id
      })
    });
    if ('error' in res) {
      const apiError = res.error as ApiError;
      createErrorAlert(
        apiError.data?.message || 'Error in fetching account details'
      );
    }
  };

  const handleSaveNewBranch = async () => {
    const onboardBranchPayload = {
      params: {
        organizationId: getLocalStorageItem('organizationId'),
        branchId: branchCreationData?.id
      },
      body: {
        name: formData.branchName.trim(),
        payout_account_type: formData.settlementMode,
        primary_account: accountDetails?.organisation.id,
        is_lite: !!(
          currentOrg?.status === OrgStatus.KYC_PENDING &&
          !currentOrg?.is_kyc_deferred
        )
      }
    };

    const res = await onboardBranch(onboardBranchPayload);

    // const analyticData = {
    //   org_id: organizationId,
    //   branch_name: onboardBranchPayload.body.name.trim(),
    //   settlement_mode: onboardBranchPayload.body.payout_account_type
    // };

    if ('data' in res) {
      setLocalStorageItem('branchId', res.data?.id);
      setLocalStorageItem('branchName', res.data?.name);
      dispatch(
        updateBranchFilter({
          branchId: res.data?.id,
          branchName: res.data?.name
        })
      );
      setSelectedBranch(res.data?.name);
      createSuccessAlert('Branch created successfully');
      setFormData({ branchName: '', settlementMode: SettlementMode.BANK });
      setCreateBranchModalOpen(false);
      navigate(BRANCHES.EDIT_BRANCH);
    } else if ('error' in res) {
      const apiError = res.error as ApiError;
      createErrorAlert(
        capitalizeFirstLetter(
          apiError?.data?.message || 'Error in branch creation'
        )
      );
      if (apiError?.data?.errorCode === ErrorCode.ERR_SUBSCRIPTION_EXPIRED) {
        if (
          rolesAndPermissions.permissions.includes(
            UserPermissionTypes.SUBSCRIPTION_VIEW
          )
        ) {
          navigate(SUBSCRIPTIONS.PLAN_DETAILS);
        } else navigate(BRANCHES.EDIT_BRANCH);
      }
    }
  };

  const onClickSave = async () => {
    if (subscriptionData?.status === SubscriptionStatus.EXPIRED) {
      createErrorAlert(SubscriptionPlanExpiredMessage);
      if (
        rolesAndPermissions.permissions.includes(
          UserPermissionTypes.SUBSCRIPTION_VIEW
        )
      ) {
        navigate(SUBSCRIPTIONS.PLAN_DETAILS);
      }
    } else {
      handleSaveNewBranch();
    }
  };

  const isOrgLite =
    currentOrg?.status === OrgStatus.KYC_PENDING &&
    currentOrg?.type === AccountType.ORGANIZATION &&
    !currentOrg?.is_kyc_deferred;

  const handleCreateBranch = async () => {
    if (currentSubscription?.status === SubscriptionStatus.EXPIRED) {
      createErrorAlert(SubscriptionPlanExpiredMessage);
      if (
        rolesAndPermissions.permissions.includes(
          UserPermissionTypes.SUBSCRIPTION_VIEW
        )
      ) {
        navigate(SUBSCRIPTIONS.PLAN_DETAILS);
      }
    } else {
      const res = await createBranch({ organizationId });
      if ('data' in res) {
        if (!isOrgLite) handleGetAccountDetails(res.data.id);
      } else if ('error' in res) {
        const apiError = res.error as ApiError;
        createErrorAlert(apiError.data?.message);
        if (apiError?.data?.errorCode === ErrorCode.ERR_SUBSCRIPTION_EXPIRED) {
          if (
            rolesAndPermissions.permissions.includes(
              UserPermissionTypes.SUBSCRIPTION_VIEW
            )
          ) {
            navigate(SUBSCRIPTIONS.PLAN_DETAILS);
          } else {
            createErrorAlert(SubscriptionPlanExpiredMessage);
          }
        }
      }
    }
  };

  const handleAddNewBranch = async () => {
    await handleCreateBranch();
    setCreateBranchModalOpen(true);
  };

  const latestOrg = currentStoredOrg || currentOrg;

  useEffect(() => {
    if (currentOrg?.id === currentStoredOrg?.id) return;
    if (!orgId) {
      navigate(AUTH.ORG_SELECTION);
      return;
    }
    dispatch(setCurrentOrg(currentOrg));
    dispatch(setAccountType({ type: currentOrg?.type }));
  }, [currentOrg]);

  useEffect(() => {
    if (
      (latestOrg?.status === OrgStatus.APPROVED && !latestOrg.has_plan) ||
      isIndividualLite(latestOrg)
    ) {
      navigate(SUBSCRIPTIONS.NO_PLAN);
    }
  }, [currentPath, currentStoredOrg, currentOrg, navigate]);

  useEffect(() => {
    if (!token) return;
    getAppConfigData();
    getOrgConfigData();
    getOrgFeatureFlagsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStoredOrg]);

  useEffect(() => {
    if (branchCreationData && !isOrgLite) {
      handleGetAccountDetails(branchCreationData.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchCreationData]);

  useEffect(() => {
    if (branchName) {
      setSelectedBranch(branchName);
    }
  }, [branchName]);

  return (
    <div className="fixed left-0 top-0 z-50 h-auto w-full bg-content text-content shadow-sm">
      <div className="flex h-16 w-full items-center border-b shadow-sm">
        <div className="flex h-full items-center">
          <Profile
            currentOrg={currentOrg}
            onClickOrganizationEdit={() =>
              setShowOrganizationDetailsModal(true)
            }
          />
        </div>
        <div className="flex size-full justify-between">
          <div className="flex h-full items-center border-l border-l-borderGrey pl-2">
            {showBranchView && (
              <BranchSelection
                initialBranchName={selectedBranch}
                onBranchChange={handleBranchChange}
                handleAddNewBranch={handleAddNewBranch}
              />
            )}
          </div>
          <div className="flex  h-full items-center  justify-center gap-2 border-l border-l-borderGrey pl-3 pr-4">
            {/* <div className="flex items-center justify-center text-base font-normal text-content">
            {currentUser?.name}
          </div> */}

            <div
              data-testid={testId.header.profileButton}
              className="relative flex h-fit cursor-pointer items-center justify-center gap-x-2 p-1.5  pr-2.5 hover:rounded-full hover:bg-theme20"
              role="presentation"
              onClick={handleDropDownClick}
              ref={ref}
            >
              <Avatar
                imageId={testId.header.profileButtonIcon}
                name={currentUser?.name || ''}
                className="size-10 rounded-full text-sm text-white"
                bgColor={currentOrgAvatarColor}
              />
              <Icon
                name="down-icon-white"
                size="16"
                className={showUserSettings ? 'rotate-180' : 'rotate-0'}
              />
              {showUserSettings && (
                <ProfileSettings
                  orgList={orgList || []}
                  subscriptionData={subscriptionData}
                  accountMethods={accountMethods}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <AccountModals orgList={orgList} setAccountMethods={setAccountMethods} />
      {showModal && (
        <Modal isOpen={showModal} onCancel={onCancelModal} isCloseIconRequired>
          <EditOrganization
            imageUrl={currentOrg?.image_url || ''}
            currentOrgName={currentOrg?.name || ''}
            currentOrgId={currentOrg?.id || ''}
            handleSave={onCancelModal}
          />
        </Modal>
      )}

      {createBranchModalOpen && (
        <CreateBranchModal
          createBranchModalOpen={createBranchModalOpen}
          setCreateBranchModalOpen={setCreateBranchModalOpen}
          formData={formData}
          setFormData={setFormData}
          onChangeBranchName={onChangeBranchName}
          onBlurBranchName={onBlurBranchName}
          onClickSave={onClickSave}
          error={error}
        />
      )}
    </div>
  );
};

export default MainHeader;
