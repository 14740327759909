import { useEffect, useState } from 'react';

import { PaymentCollectionType } from '@features/group-management/constants';
import { CardProps, DayOptionsType } from 'types/payment-options';

import { dayOptions } from '../constants';
// import { handleWeekDateSelect } from '../helper';

const WeekCard = ({
  handleActivationDate,
  type,
  selectedDate,
  handleIntervalFrequency
}: // isPaymentLinkSent
CardProps) => {
  const [selectedDay, setSelectedDay] = useState<DayOptionsType>();

  const handleDayClick = (item: DayOptionsType) => {
    setSelectedDay(item);
    // const { nextDueDate, isFutureDate } = handleWeekDateSelect(
    //   item.id,
    //   undefined,
    //   isPaymentLinkSent
    // );
    // handleActivationDate(nextDueDate, isFutureDate);
    handleActivationDate(item.value);
    handleIntervalFrequency('1');
  };

  // useEffect(() => {
  //   if (!selectedDate) {
  //     const { nextDueDate, isFutureDate } = handleWeekDateSelect(
  //       dayOptions[0].id
  //     );
  //     handleActivationDate(nextDueDate, isFutureDate);
  //     handleIntervalFrequency('');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (!selectedDate || type !== PaymentCollectionType.WEEKLY) {
      setSelectedDay(dayOptions[0]);
      handleActivationDate(dayOptions[0].value);
      handleIntervalFrequency('1');
    } else if (selectedDate && type === PaymentCollectionType.WEEKLY) {
      // setSelectedDay(dayOptions[new Date(selectedDate).getDay()]);
      // handleActivationDate(dayOptions[new Date(selectedDate).getDay()].value);
      setSelectedDay(
        dayOptions.find((option) => option.value === selectedDate)
      );
      handleActivationDate(selectedDate as string);
      handleIntervalFrequency('1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <div className="grid grid-cols-3 gap-4 pb-6">
      {dayOptions.map((item) => (
        <div
          key={item.id}
          className={`flex h-10 w-20 cursor-pointer items-center justify-center rounded-lg bg-grey10 text-grey ${
            selectedDay?.id === item.id
              ? 'border border-primary text-primary'
              : ''
          }`}
          role="presentation"
          onClick={() => handleDayClick(item)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default WeekCard;
