import {
  ADMIN,
  BRANCHES,
  EVENTS,
  GROUPS,
  MEMBERS,
  TEAM_MANAGEMENT,
  TRANSACTIONS
} from '@constants/routes';

export type Breadcrumb = { path: string; name: string };

export const GROUPS_HOME_CRUMB = { name: 'Groups', path: GROUPS.HOME };

export const ADD_NEW_GROUP_CRUMB = [
  GROUPS_HOME_CRUMB,
  { name: 'New Group', path: GROUPS.ADD_NEW_GROUP }
];

const ADD_NEW_BRANCH_CRUMB = [
  { name: 'New Branch', path: BRANCHES.ADD_NEW_BRANCH }
];

export const ADMIN_ORG_HOME_CRUMB = {
  name: 'Organizations',
  path: ADMIN.KYC_DOCUMENTS
};

export const ADMIN_INDIVIDUAL_HOME_CRUMB = {
  name: 'Individual',
  path: ADMIN.KYC_DOCUMENTS
};

export const ADMIN_VIEW_DOCUMENTS_CRUMB = {
  name: 'View Documents',
  path: ADMIN.VIEW_DOCUMENTS
};

export const ADMIN_CUSTOMER_ORG_HOME_CRUMB = {
  name: 'Organization Customers',
  path: ADMIN.CUSTOMERS_LIST
};

export const ADMIN_CUSTOMER_INDIVIDUAL_HOME_CRUMB = {
  name: 'Individual Customers',
  path: ADMIN.CUSTOMERS_LIST
};

export const breadcrumbsMap: Map<string, Breadcrumb[]> = new Map([
  [GROUPS.HOME, [GROUPS_HOME_CRUMB]],
  [GROUPS.ADD_NEW_GROUP, ADD_NEW_GROUP_CRUMB],
  [
    GROUPS.GROUP_INFO,
    [GROUPS_HOME_CRUMB, { name: 'Group Info', path: GROUPS.GROUP_INFO }]
  ],
  [BRANCHES.ADD_NEW_BRANCH, ADD_NEW_BRANCH_CRUMB]
]);

export const TM_HOME_CRUMB = {
  name: 'Team Management',
  path: TEAM_MANAGEMENT.TEAM_MANAGEMENT
};

export const TRANSACTIONS_HOME_CRUMB = {
  name: 'Transactions',
  path: TRANSACTIONS.TRANSACTIONS
};

export const MEMBERS_HOME_CRUMB = {
  name: 'Members',
  path: MEMBERS.MEMBERS
};

export const EVENTS_HOME_CRUMB = {
  name: 'Events',
  path: EVENTS.HOME
};
