import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '@components';
import UserPermissionTypes from '@constants/permissions';
import { GROUPS } from '@constants/routes';
import { Event, sendMixpanelEvent, trackPageView } from '@core/analytics';
import { useLazyGetRolesAndPermissionsQuery } from '@features/authentication/queries/AuthQuery';
import KycInfoToast from '@features/group-management/components/kyc-info-toast/KycInfoToast';
import useCheckPermission from '@hooks/checkPermission';
import Head from '@layouts/head/Head';
import { getLocalStorageItem } from '@utils/storage';

import BranchRevenueChart from '../components/BranchRevenueChart';
import DashboardCardsOverview from '../components/dashboard-cards-overview/DashboardCardsOverview';
import DashboardDateRangePicker from '../components/dashboard-date-range-picker/DashboardDateRangePicker';
import GroupPerfomanceChart from '../components/GroupPerfomanceChart';
import PaymentLinksAndModes from '../components/PaymentLinksAndModes';
import {
  allBranches,
  DashboardDateRangePickerTypesEnum,
  defaultDateRange
} from '../constants';
import { DateRangeType } from '../types';

const OrgDashboard = () => {
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

  const [selectedDateRange, setSelectedDateRange] = useState<{
    dateRange: DateRangeType;
    dateRangeType: DashboardDateRangePickerTypesEnum;
  }>({
    dateRange: defaultDateRange(),
    dateRangeType: DashboardDateRangePickerTypesEnum.thisMonth
  });

  const selectedBranch = allBranches.value;
  const selectedBranchId = `${allBranches.id}`;

  const { currentOrg } = useSelector((state: any) => state.user);

  const [
    getRolesAndPermissions,
    {
      data: rolesAndPermissions,
      isFetching: isPermissionsFetching,
      isSuccess: isPermissionsSuccess
    }
  ] = useLazyGetRolesAndPermissionsQuery();
  const orgId = getLocalStorageItem('organizationId');

  const organizationId = getLocalStorageItem('organizationId');
  const navigate = useNavigate();

  useEffect(() => {
    trackPageView('Dashboard');
  }, []);

  useEffect(() => {
    sendMixpanelEvent(Event.DASHBOARD_SELECTED_DATERANGE, {
      selectedDateRange
    });
  }, [selectedDateRange]);

  useEffect(() => {
    sendMixpanelEvent(Event.DASHBOARD_SELECTED_BRANCH, {
      branchId: selectedBranchId
    });
  }, [selectedBranchId]);
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (isPermissionsFetching) setIsPageLoading(true);

    if (!isPermissionsFetching && isPermissionsSuccess) {
      const hasDashboardViewAccess = checkPermission(
        [UserPermissionTypes.DASHBOARD_VIEW],
        rolesAndPermissions
      );
      if (hasDashboardViewAccess) {
        setIsPageLoading(false);
      } else {
        navigate(GROUPS.HOME);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPermissionsFetching]);

  useEffect(() => {
    setIsPageLoading(true);
    getRolesAndPermissions(orgId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return (
    <div className="page bg-bgBase">
      {isPageLoading ? (
        <div className="flex h-[calc(100vh-64px)] w-full items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <Head title="Dashboard" />
          <div className="flex flex-wrap items-center justify-between gap-4 lg:flex-nowrap lg:gap-0">
            <KycInfoToast status={currentOrg?.status} />

            <div className="text-2xl font-bold text-content">Overview</div>

            <DashboardDateRangePicker
              handleDateRangeSelect={(dateRange, dateRangeType) => {
                setSelectedDateRange({ dateRange, dateRangeType });
              }}
              //   selectedBranch={selectedBranch}
              //   setSelectedBranch={setSelectedBranch}
              //   setSelectedBranchId={setSelectedBranchId}
            />
          </div>

          <DashboardCardsOverview
            organizationId={organizationId}
            dateRange={selectedDateRange.dateRange}
            showGrowth={
              selectedDateRange.dateRangeType !==
              DashboardDateRangePickerTypesEnum.custom
            }
            selectedBranchId={
              selectedBranchId === allBranches.id ? '' : selectedBranchId
            }
          />

          <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-wrap gap-4 py-4 lg:flex-nowrap">
              <BranchRevenueChart
                selectedBranch={selectedBranch}
                selectedDateRange={selectedDateRange.dateRange}
                selectedBranchId={selectedBranchId}
              />
              <GroupPerfomanceChart
                selectedDateRange={selectedDateRange.dateRange}
                selectedBranchId={
                  selectedBranchId === allBranches.id ? '' : selectedBranchId
                }
              />
            </div>
            <PaymentLinksAndModes
              selectedDateRange={selectedDateRange.dateRange}
              selectedBranchId={
                selectedBranchId === allBranches.id ? '' : selectedBranchId
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OrgDashboard;
