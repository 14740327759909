import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';

import { Icon } from '@components/index';
import {
  getDashboardCardData,
  getFormattedDateForChartAPI
} from '@features/dashboard/helper';

import DashboardCard from '../dashboardCard/DashboardCard';

import { useLazyGetPaymentOverviewQuery } from '../../queries/index';
import { DateRangeType } from '../../types';

interface DashboardCardProps {
  organizationId: string;
  dateRange: DateRangeType;
  selectedBranchId: string;
  showGrowth: boolean;
}

const DashboardCardsOverview = ({
  organizationId,
  dateRange,
  selectedBranchId,
  showGrowth
}: DashboardCardProps) => {
  const [dashBoardCardData, setDashBoardCardData] = useState<any>(
    getDashboardCardData({}, showGrowth)
  );

  const [getPaymentOverview, { isLoading, isFetching, isUninitialized }] =
    useLazyGetPaymentOverviewQuery();

  const handleGetAllPaymentOverview = async () => {
    const { startDate, endDate } = getFormattedDateForChartAPI(dateRange);

    const res = await getPaymentOverview({
      orgId: organizationId,
      queryString: `?from_timestamp=${startDate}&to_timestamp=${endDate}&branch_id=${selectedBranchId}&include_growth_percentage=${showGrowth}`
    });
    if ('data' in res) {
      setDashBoardCardData(getDashboardCardData(res.data, showGrowth));
    }
  };

  const showSkeleton = isLoading || isFetching || isUninitialized;

  useEffect(() => {
    handleGetAllPaymentOverview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, selectedBranchId]);

  const renderGroupInfo = () => (
    <div className="w-[48%] rounded-xl bg-white px-5 py-2 shadow-dashboardCardShadow lg:w-2/12">
      {dashBoardCardData?.groupInfo?.map((item: any) => (
        <div
          key={item.title}
          className="py-1 first-of-type:border-b first-of-type:border-grey10"
        >
          <div className="flex items-center gap-2 py-1">
            <Icon name={item.icon} className="!size-6" />
            <p className="text-sm font-medium text-grey">{item.title}</p>
          </div>
          <div className="flex items-center ">
            {showSkeleton ? (
              <Skeleton
                height={32}
                width={80}
                key={Math.random()}
                animation="wave"
                variant="text"
              />
            ) : (
              <h2 className="text-xl font-bold leading-8 text-content ">
                {item.value}
              </h2>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex w-full flex-wrap gap-5 pb-2 pt-6 lg:flex-nowrap">
      {dashBoardCardData?.cardValues?.map((item: any) => (
        <DashboardCard
          key={item.title}
          title={item.title}
          value={item.value}
          icon={item.icon}
          growthValue={item.growthValue}
          className="flex w-[48%] flex-col justify-center shadow-dashboardCardShadow lg:w-[28%]"
          isLoading={showSkeleton}
          tooltipValue={item.tooltipValue}
          showGrowthValue={Object.hasOwn(item, 'growthValue')}
        />
      ))}
      {renderGroupInfo()}
    </div>
  );
};

export default DashboardCardsOverview;
