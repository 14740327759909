import { DropdownOption } from 'types/dropdown';
import {
  formatISODate,
  getLastNDays,
  getStartAndPresentDateOfCurrentMonth
} from '@utils/date';

import { DateRangeType } from './types';

export enum PaymentModesChartColors {
  UPI = '#FFCE9C',
  Cards = '#74ABFF',
  NetBanking = '#FFB1B1',
  Others = '#59C3CF',
  Offline = '#E1E1E1'
}

export const GroupPerfomancePageLimit = 5;

export enum DashboardDateRangePickerTypesEnum {
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
  custom = 'custom'
}

export const DashboardDateRangePickerTypes = [
  { key: DashboardDateRangePickerTypesEnum.thisMonth, label: 'This Month' },
  { key: DashboardDateRangePickerTypesEnum.lastMonth, label: 'Last Month' },
  { key: DashboardDateRangePickerTypesEnum.custom, label: 'Custom' }
];

export type DashboardDateRange = {
  startDate: string;
  endDate: string;
};

export const allBranches: DropdownOption = {
  id: 'all',
  value: 'All Branches',
  label: 'All Branches'
};

export const customDateRangeMinDaysDiff = 7;

export const customDefaultDateRange = {
  startDate: new Date(getLastNDays(customDateRangeMinDaysDiff).startDate),
  endDate: new Date(getLastNDays(customDateRangeMinDaysDiff).endDate)
};

export const customBranchOption = {
  id: 'top_5',
  label: 'Top 5',
  value: 'Top 5'
};

export const colorListForGroupPerformance = [
  { backgroundColor: '#FFF3E8', color: '#E88323' },
  { backgroundColor: '#FFF9E5', color: '#DDBB3F' },
  { backgroundColor: '#ECF4FF', color: '#538DE5' },
  { backgroundColor: '#FBF2FF', color: '#C88BE4' },
  { backgroundColor: '#59C3CF20', color: '#59C3CF' }
];

export const defaultDateRange = (): DateRangeType => {
  const { startDate, endDate } = getStartAndPresentDateOfCurrentMonth();
  return {
    startDate: formatISODate({
      date: new Date(startDate),
      format: 'dd-mm-yyyy',
      separator: '-'
    }),
    endDate: formatISODate({
      date: new Date(endDate),
      format: 'dd-mm-yyyy',
      separator: '-'
    })
  };
};
