import {
  apiMethodColorMapper,
  CoFeeApiDetails
} from '@features/docs/constants';

type ApiMethodChipProps = {
  method: string;
};

const ApiMethodChip = ({ method }: ApiMethodChipProps) => (
  <div
    className={`flex w-8 items-center justify-center rounded-[4px] p-0.5 text-center text-[8px] font-semibold uppercase ${
      apiMethodColorMapper[method as CoFeeApiDetails] || 'bg-grey'
    } text-white`}
  >
    <span className="px-2">{method}</span>
  </div>
);

export default ApiMethodChip;
