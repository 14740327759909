import { Avatar, Icon } from '@components';
import { AccountType } from '@constants/generic';

import theme from '../../../../themes/colors';

interface ProfileProps {
  currentOrg: any;
  onClickOrganizationEdit?: () => void;
}

const Profile = ({ currentOrg, onClickOrganizationEdit }: ProfileProps) => (
  <div
    className={`group relative  bg-content ${
      currentOrg?.type === AccountType.ORGANIZATION ? 'cursor-pointer' : ''
    }`}
    role="presentation"
    onClick={() => onClickOrganizationEdit?.()}
  >
    <div className="flex w-56 items-center justify-center gap-3 px-2 transition-all">
      <div className="flex items-center gap-2 overflow-hidden">
        <Avatar
          name={currentOrg?.name}
          className="size-10 min-w-10 !border-0 text-theme"
          imageUrl={currentOrg?.image_url}
          bgColor={theme.blueGrey}
        />
        <div className="flex w-45 flex-col justify-center truncate  break-words pt-1">
          <div className="overflow-hidden text-ellipsis text-sm font-medium text-white">
            {currentOrg?.name}
          </div>
          <div className="overflow-hidden text-ellipsis text-xs font-medium text-subText">
            {currentOrg?.role_name}
          </div>
        </div>
      </div>
      {currentOrg?.type === AccountType.ORGANIZATION && (
        <div className="flex size-6 min-w-6 items-center justify-center rounded-full bg-content opacity-0 transition-opacity group-hover:opacity-100">
          <Icon
            name="edit-with-line"
            className="p-0.5"
            stroke={theme.primary}
          />
        </div>
      )}
    </div>
  </div>
);

export default Profile;
