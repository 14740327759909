import { useEffect, useRef, useState } from 'react';
import { Skeleton } from '@mui/material';

import useOnClickOutside from '@utils/hooks';
import { DropdownOption, DropdownWithActionProps } from 'types/dropdown';

import Button from '../button/Button';
import Icon from '../icon/Icon';

import theme from '../../../themes/colors';

const DropdownWithAction = (props: DropdownWithActionProps) => {
  const {
    dropdownId,
    currentFilter,
    filterOptions,
    onSelectFilter,
    className,
    optionsStyle,
    labelStyle,
    isLoading,
    placeholder,
    loadingStyles,
    optionsCount,
    placeholderStyle,
    dropdownHeaderStyle,
    listId,
    disabled,
    placeholderNode,
    actionLabel,
    actionOnClick
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const ref = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((isOpen) => !isOpen);
  };

  const onClickFilter = (option: DropdownOption) => {
    if (option.value !== currentFilter && !option.isDisabled) {
      onSelectFilter(option.value);
      toggleDropdown();
    }
  };

  const selectedFilter: DropdownOption | undefined = filterOptions?.find(
    (option) => option.value === currentFilter
  );

  useOnClickOutside(ref, (e) => {
    e.stopPropagation();
    setIsDropdownOpen(false);
  });

  const onClickAction = () => {
    setIsDropdownOpen(false);
    actionOnClick?.();
  };

  useEffect(() => {
    if (isDropdownOpen) {
      const element = document.getElementById(`${listId}-dropdown-list`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        });
      }
    }
  }, [isDropdownOpen, listId]);

  if (isLoading)
    return (
      <div className={loadingStyles || 'w-full'}>
        <Skeleton
          height={53}
          key={Math.random()}
          animation="wave"
          variant="rounded"
        />
      </div>
    );

  return (
    <div className={`relative ${className || ''}`} ref={ref}>
      <div className={`flex items-center gap-2 ${dropdownHeaderStyle || ''}`}>
        <Button
          data-testid={dropdownId}
          variant="tertiary"
          handleClick={toggleDropdown}
          size="medium"
          label={
            (selectedFilter?.element ?? selectedFilter?.label) ||
            (placeholderNode ?? placeholder)
          }
          labelCount={optionsCount}
          rightIconName="down-icon-white"
          className={`min-w-48 max-w-48 justify-between border ${className}`}
          labelStyle={
            placeholder && !selectedFilter?.label
              ? `!text-base text-grey ${placeholderStyle || ''}`
              : `truncate ${labelStyle || ''}`
          }
          rightIconStyle={isDropdownOpen ? 'rotate-180' : ''}
          disabled={disabled}
        />
      </div>
      {isDropdownOpen && (
        <div
          className={`absolute z-20 mt-3 w-55 origin-top-right rounded-md border border-bgGrey bg-bgGrey shadow-sm ${
            optionsStyle || 'right-0'
          }`}
          id={`${listId}-dropdown-list`}
        >
          {actionLabel && (
            <div className="border-b border-darkGrey px-2">
              <div
                className="my-2 flex cursor-pointer items-center justify-between rounded-md p-2 hover:bg-darkGrey"
                role="presentation"
                onClick={onClickAction}
              >
                <span className="font-base text-sm text-primary">
                  {actionLabel}
                </span>
                <div className="flex size-6">
                  <Icon
                    name="plus"
                    size="24"
                    className="p-0.5"
                    stroke={theme.primary}
                  />
                </div>
              </div>
            </div>
          )}
          <div
            className="mr-1 max-h-60 overflow-y-scroll px-2"
            id={`${listId}-dropdown-items`}
          >
            {filterOptions.length > 0 ? (
              filterOptions?.map((option) => (
                <div
                  id={`dropdown_${option.value}`}
                  key={option?.id}
                  className={`my-2 flex w-full items-center justify-between truncate rounded-md p-2  text-sm text-white hover:bg-darkGrey
                    ${
                      option.isDisabled
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    } ${
                    option.value === selectedFilter?.value
                      ? 'bg-darkGrey font-semibold'
                      : 'font-light'
                  }`}
                  role="presentation"
                  onClick={() => onClickFilter(option)}
                >
                  {option.element ?? option.label}
                  {option.value === selectedFilter?.value && (
                    <Icon
                      name="tick"
                      size="24"
                      className="p-0.5"
                      stroke={theme.primary}
                    />
                  )}
                </div>
              ))
            ) : (
              <div className="mt-3 flex h-40 items-center justify-center px-6 text-base text-grey">
                No results found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownWithAction;
