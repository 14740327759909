import { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import { useSelector } from 'react-redux';

import Icon from '@components/icon/Icon';
import RadioGroup from '@components/radio-button/RadioGroup';
import { PaymentCollectionType } from '@features/group-management/constants';
import { CardProps } from '@types';

import { onceCardOptions, OnceKeys } from '../constants';

import colors from '../../../../themes/colors';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles.css';

const OnceCard = ({
  handleActivationDate,
  handleIntervalFrequency,
  selectedDate,
  type,
  minDate,
  isImmediatePayment,
  isEdit
}: CardProps) => {
  const { currentOrg } = useSelector((state: any) => state.user);
  const [date, setDate] = useState<Date>();
  const [selectedType, setSelectedType] = useState<string>(
    isImmediatePayment ? OnceKeys?.IMMEDIATELY : OnceKeys.DUE_DATE
  );

  const handleTypeSelect = (item: string) => {
    setSelectedType(item);
    if (item === OnceKeys.IMMEDIATELY) handleActivationDate(item);
  };

  useEffect(() => {
    if (!selectedDate || type !== PaymentCollectionType.ONCE) {
      handleIntervalFrequency('');
    }
    if (
      selectedDate &&
      type === PaymentCollectionType.ONCE &&
      selectedDate !== OnceKeys.IMMEDIATELY &&
      !isImmediatePayment
    )
      setDate(new Date(selectedDate));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    handleActivationDate(date || new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <>
      {currentOrg?.status === 'approved' && (
        <RadioGroup
          options={onceCardOptions}
          handleChange={handleTypeSelect}
          orientation="horizontal"
          selectedValue={selectedType}
          disabled={isEdit}
        />
      )}
      {selectedType === OnceKeys.DUE_DATE ? (
        <Calendar
          className="mt-4 !text-sm !text-content"
          date={date}
          onChange={(modifiedDate) => setDate(modifiedDate)}
          color={`${colors.primary}`}
          {...(minDate && { minDate })}
        />
      ) : (
        <p className="mt-4 text-base text-grey">
          Payment links will be sent to all members immediately upon group
          creation and whenever a new member is added.
        </p>
      )}
      <div className="bg-warningBg mt-4 flex items-center gap-2 rounded-lg p-4 text-sm text-warningYellow">
        <Icon name="alert-circle" stroke={colors.warningYellow} />
        When &apos;Once&apos; is selected, all members share the same due date
        as the group.
      </div>
    </>
  );
};

export default OnceCard;
