import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { SUBSCRIPTIONS } from '@constants/routes';

const SubscriptionPlan = lazy(
  () => import('@features/subscription/pages/SubscriptionPlan')
);

const NoPlan = lazy(() => import('@features/subscription/pages/NoPlan'));

export const SubscriptionRoutes: RouteObject[] = [
  {
    path: SUBSCRIPTIONS.PLAN_DETAILS,
    element: <SubscriptionPlan />
  },
  {
    path: SUBSCRIPTIONS.NO_PLAN,
    element: <NoPlan />
  }
];
export default SubscriptionRoutes;
