import { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { Spinner } from '@components';
import { UserTypes } from '@constants/role';
import { AUTH } from '@constants/routes';
import { useLazyGetOrganizationListQuery } from '@features/authentication/queries/AuthQuery';
import { useLazyGetCurrentSubscriptionQuery } from '@features/subscription/queries';
import useNotify from '@hooks/notify';
import ApprovalGuard from '@layouts/approval-guard/ApprovalGuard';
import SandboxBanner from '@layouts/main-header/components/SandboxBanner';
import MainHeader from '@layouts/main-header/MainHeader';
import { setCurrentSubscription } from '@layouts/main-header/userSlice';
import MobileLayout from '@layouts/mobile-layout/MobileLayout';
import SideNavBar from '@layouts/side-nav-bar/SideNavBar';
import { getLocalStorageItem } from '@utils/storage';
import { Org } from 'types/organization';

import AccessGuard from '../access-guard/AccessGuard';

const MainLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createErrorAlert } = useNotify();

  const token = getLocalStorageItem('token');
  const orgId = getLocalStorageItem('organizationId');
  const userType = getLocalStorageItem('userType');

  const { currentOrg: currentStoredOrg } = useSelector(
    (state: any) => state.user
  );

  const [orgList, setOrgList] = useState<Org[]>();

  const [getCurrentSubscription, { data: subscriptionData }] =
    useLazyGetCurrentSubscriptionQuery();
  const [getOrgList] = useLazyGetOrganizationListQuery();

  const handleGetCurrentSubscription = async () => {
    const res = await getCurrentSubscription({ organizationId: orgId });
    if ('data' in res) {
      dispatch(setCurrentSubscription(res.data));
    }
  };

  const getOrgListData = async () => {
    const resp = await getOrgList(null, false);
    if ('data' in resp) {
      setOrgList(resp.data);
    } else if ('error' in resp) {
      createErrorAlert('Error in fetching organization list');
    }
  };

  const isSandBoxEnv = process.env.REACT_APP_ENVIRONMENT === 'sandbox';

  useEffect(() => {
    if (orgId) handleGetCurrentSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  useEffect(() => {
    if (!token) return;
    getOrgListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStoredOrg]);

  useEffect(() => {
    if (orgList && orgList.length <= 0 && userType !== UserTypes.ADMIN)
      navigate(AUTH.SELECT_ACCOUNT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgList]);

  return (
    <AccessGuard>
      <div className="z-100 hidden h-screen w-screen overflow-hidden mobile:flex">
        <MobileLayout />
      </div>
      <div className="flex h-screen w-screen overflow-hidden mobile:hidden">
        <div className="size-full pl-56">
          <MainHeader
            orgList={orgList || []}
            subscriptionData={subscriptionData}
          />
          <SideNavBar
            orgList={orgList || []}
            subscriptionData={subscriptionData}
          />
          {isSandBoxEnv && <SandboxBanner />}
          <div
            className={`h-screen overflow-y-auto bg-bgBase  ${
              isSandBoxEnv ? 'mt-30 pb-35' : 'mt-16 pb-21'
            }`}
          >
            <div className="container">
              <Suspense
                fallback={
                  <div className="absolute left-1/2 top-1/2 flex size-full -translate-x-1/2 -translate-y-1/2 items-center justify-center">
                    <Spinner />
                  </div>
                }
              >
                <ApprovalGuard>
                  <Outlet />
                </ApprovalGuard>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </AccessGuard>
  );
};
export default MainLayout;
