import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import AdminSideNavBar from '@layouts/admin-side-nav-bar/AdminSideNavBar';
import MobileLayout from '@layouts/mobile-layout/MobileLayout';
import { Spinner } from '@components';

import AccessGuard from '../access-guard/AccessGuard';

const AdminLayout = () => (
  <AccessGuard>
    <div className="z-100 hidden h-screen w-screen overflow-hidden mobile:flex">
      <MobileLayout />
    </div>
    <div className="flex h-screen w-screen overflow-hidden mobile:hidden">
      <div className="size-full pl-56">
        <AdminSideNavBar />
        <div className="h-screen overflow-y-auto bg-bgBase pb-83">
          <div className="container">
            <Suspense
              fallback={
                <div className="absolute left-1/2 top-1/2 flex size-full -translate-x-1/2 -translate-y-1/2 items-center justify-center">
                  <Spinner />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  </AccessGuard>
);
export default AdminLayout;
