import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: {},
  rolesAndPermissions: {},
  accountType: '',
  currentOrg: null,
  isOrgListLoading: false,
  orgConfig: null,
  appConfig: null,
  currentSubscription: {},
  orgFeatureFlags: []
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (
      state,
      { payload: { user } }: PayloadAction<{ user: any }>
    ) => ({
      ...state,
      currentUser: user
    }),
    setRolesAndPermissions: (
      state,
      { payload: { permissions } }: PayloadAction<{ permissions: any }>
    ) => ({
      ...state,
      rolesAndPermissions: permissions
    }),
    setAccountType: (state, action: PayloadAction<any>) => {
      state.accountType = action.payload;
    },
    setCurrentOrg: (state, action: PayloadAction<any>) => {
      state.currentOrg = action.payload;
    },
    setOrgListLoading: (state, action: PayloadAction<any>) => {
      state.isOrgListLoading = action.payload.isOrgListLoading;
    },
    setOrgConfig: (state, action: PayloadAction<any>) => {
      state.orgConfig = action.payload;
    },
    setAppConfig: (state, action: PayloadAction<any>) => {
      state.appConfig = action.payload;
    },
    setCurrentSubscription: (state, action: PayloadAction<any>) => {
      state.currentSubscription = action.payload;
    },
    setOrgFeatureFlags: (state, action: PayloadAction<any>) => {
      state.orgFeatureFlags = action.payload;
    },
    resetUserState: () => initialState
  }
});

export const {
  setCurrentUser,
  setRolesAndPermissions,
  setAccountType,
  setCurrentOrg,
  setOrgListLoading,
  setOrgConfig,
  setAppConfig,
  setCurrentSubscription,
  setOrgFeatureFlags,
  resetUserState
} = userSlice.actions;
export default userSlice.reducer;
