import { Button, Input, Modal } from '@components';
import {
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
  SettlementMode
} from '@constants/generic';
import { nameRegex } from '@constants/regex';
import { BranchForm, ValidationError } from '@types';

interface CreateBranchModalProps {
  createBranchModalOpen: boolean;
  setCreateBranchModalOpen: (open: boolean) => void;
  setFormData: (data: BranchForm) => void;
  formData: BranchForm;
  onChangeBranchName: (value: string) => void;
  error: ValidationError;
  onBlurBranchName: (value: string) => void;
  onClickSave: () => void;
}

const CreateBranchModal = ({
  createBranchModalOpen,
  setCreateBranchModalOpen,
  setFormData,
  formData,
  onChangeBranchName,
  error,
  onBlurBranchName,
  onClickSave
}: CreateBranchModalProps) => (
  <Modal
    isOpen={createBranchModalOpen}
    onCancel={() => {
      setCreateBranchModalOpen(false);
      setFormData({
        branchName: '',
        settlementMode: SettlementMode.BANK
      });
    }}
    isCloseIconRequired
    closeIconClassName="!top-0"
    className="w-78"
  >
    <div className="border-b border-b-grey30">
      <div className="px-4 py-2.5 text-sm font-semibold text-content">
        New Branch
      </div>
    </div>
    <div className="p-4">
      <div className="px-8 text-center text-xl">
        You are about to create a new branch
      </div>
      <Input
        value={formData.branchName}
        className="mt-5"
        handleInputChange={onChangeBranchName}
        placeholder="Branch Name"
        inputRegex={nameRegex}
        minLength={NAME_MIN_LENGTH}
        maxLength={NAME_MAX_LENGTH}
        error={!!error.name}
        errorMessage={error.name}
        handleInputBlur={onBlurBranchName}
      />
      <div className="mt-6 flex w-full items-center justify-center">
        <Button
          label="Create"
          size="medium"
          variant="primary"
          handleClick={onClickSave}
          disabled={formData.branchName.length < NAME_MIN_LENGTH}
        />
      </div>
    </div>
  </Modal>
);

export default CreateBranchModal;
