import { lazy } from 'react';

import ConfirmationModal from '../layouts/modal/ConfirmationModal';
import Modal from '../layouts/modal/Modal';
import Accordion from './accordion/Accordion';
import ActionButton from './action-button/ActionButton';
import AddMemberModal from './add-member-modal/AddMemberModal';
import AmountInput from './amount-input/AmountInput';
import AmountInputTooltip from './amount-input-tooltip/AmountInputTooltip';
import Avatar from './avatar/Avatar';
import BackWithCustomText from './back-with-custom-text/BackWithCustomText';
import Breadcrumbs from './breadcrumbs/Breadcrumbs';
import Button from './button/Button';
import Checkbox from './checkbox/Checkbox';
import Chip from './chip/Chip';
import CopyToClipboard from './copy-to-clipboard/CopyToClipboard';
import CountryCode from './country-code/CountryCode';
import DatePicker from './date-picker/DatePicker';
import DateRangePicker from './date-range-picker/DateRangePicker';
import DowntimeTimer from './down-time-timer/DownTimeTimer';
import Drawer from './drawer/Drawer';
import Dropdown from './drop-down/Dropdown';
import DropdownWithAction from './drop-down/DropdownWithAction';
import DropdownWithRadoiButton from './drop-down/DropdownWithRadioButton';
import EmptyScreen from './empty-screen/EmptyScreen';
import FeeConfigInput from './fee-config-input/FeeConfigInput';
import GroupCard from './group-card/GroupCard';
import Icon from './icon/Icon';
import ImageUpload from './image-upload/ImageUpload';
import InfiniteScroll from './infinite-scroll/InfiniteScroll';
import InfoCard from './info-card/InfoCard';
import InfoIconTooltip from './info-icon-tooltip/InfoIconTooltip';
import Input from './input/Input';
import Label from './label/Label';
import MultiFieldFilter from './multi-field-filter/MultiFieldFilter';
import MultiProgressBar from './multi-progress-bar/MultiProgressBar';
import MultiSelectFilter from './multi-select-filter/MultiSelectFilter';
import MultiSelector from './multi-selector/MultiSelector';
import MultiSelectorWithChips from './multi-selector-with-chips/MultiSelectorWithChips';
import MultiUpload from './multi-upload/MultiUpload';
import MultiSelectDropdown from './multiselect-dropdown/MultiSelectDropdown';
import MulitSelectDropdownWithApplyButton from './multiselect-dropdown/MultiSelectDropdownWithApplyButton';
import Notification from './notification/Notification';
import OrgPreApprovedStage from './org-pre-approved-stage/OrgPreApprovedStage';
import ProfileSettings from './org-switching/ProfileSettings';
import OtpInput from './otpInput/OtpInput';
import PageIndicator from './page-indicator/PageIndicator';
import PageLink from './page-link/PageLink';
import PageTitle from './page-title/PageTitle';
import Pagination from './pagination/Pagination';
import PaymentCollection from './payment-collection/PaymentCollection';
import PendingVerification from './pending-verification/PendingVerification';
import PlusNChipList from './plus-n-chip-list/PlusNChipList';
import PlusNMoreRounded from './plus-n-more-rounded/PlusNMoreRounded';
import ProgressBar from './progress-bar/ProgressBar';
import RadioButton from './radio-button/RadioButton';
import RadioGroup from './radio-button/RadioGroup';
import RadioCard from './radio-card/RadioCard';
import RequestRejected from './request-rejected/RequestRejected';
import ReuploadDocument from './reupload-document/ReUpload';
import SearchBar from './searchbar/SearchBar';
import SectionTitle from './section-title/SectionTitle';
import SidebarItem from './sidebar-item/SidebarItem';
import Spinner from './spinner/Spinner';
import Switch from './switch/Switch';
import Table from './table/Table';
import TablePageIndicator from './table/TablePageIndicator';
import Tabs from './tabs/Tabs';
import TextArea from './text-area/TextArea';
import ThreeDotActionButton from './three-dot-action-button/ThreeDotActionButton';
import Toast from './toast/Toast';
import Tooltip from './tooltip/Tooltip';
import DayPicker from './week-day-picker/DayPicker';

const MultiBarChart = lazy(
  () => import('./apex-chart/multi-bar-chart/MultiBarChart')
);
const HorizontalBarChart = lazy(
  () => import('./apex-chart/horizontal-bar-chart/HorizontalBarChart')
);
const LineChart = lazy(() => import('./apex-chart/line-chart/LineChart'));
const SemiDonutChart = lazy(
  () => import('./apex-chart/semi-donut-chart/SemiDonutChart')
);
const SemiCircleGuageChart = lazy(
  () => import('./apex-chart/semi-circle-gauge-chart/SemiCircleGuageChart')
);

const PdfViewer = lazy(() => import('./pdf-viewer/PdfViewer'));

export {
  Accordion,
  ActionButton,
  AddMemberModal,
  AmountInput,
  AmountInputTooltip,
  Avatar,
  BackWithCustomText,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  ConfirmationModal,
  CopyToClipboard,
  CountryCode,
  DatePicker,
  DateRangePicker,
  DayPicker,
  DowntimeTimer,
  Drawer,
  Dropdown,
  DropdownWithAction,
  DropdownWithRadoiButton,
  EmptyScreen,
  FeeConfigInput,
  GroupCard,
  HorizontalBarChart,
  Icon,
  ImageUpload,
  InfiniteScroll,
  InfoCard,
  InfoIconTooltip,
  Input,
  Label,
  LineChart,
  Modal,
  MulitSelectDropdownWithApplyButton,
  MultiBarChart,
  MultiFieldFilter,
  MultiProgressBar,
  MultiSelectDropdown,
  MultiSelectFilter,
  MultiSelector,
  MultiSelectorWithChips,
  MultiUpload,
  Notification,
  OrgPreApprovedStage,
  OtpInput,
  PageIndicator,
  PageLink,
  PageTitle,
  Pagination,
  PaymentCollection,
  PdfViewer,
  PendingVerification,
  PlusNChipList,
  PlusNMoreRounded,
  ProfileSettings,
  ProgressBar,
  RadioButton,
  RadioCard,
  RadioGroup,
  RequestRejected,
  ReuploadDocument,
  SearchBar,
  SectionTitle,
  SemiCircleGuageChart,
  SemiDonutChart,
  SidebarItem,
  Spinner,
  Switch,
  Table,
  TablePageIndicator,
  Tabs,
  TextArea,
  ThreeDotActionButton,
  Toast,
  Tooltip
};
