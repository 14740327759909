import { Icon } from '@components/index';

interface SandboxSwitchProps {
  isProfileList: boolean;
}

const SandboxSwitch = ({ isProfileList }: SandboxSwitchProps) => {
  const isSandBoxEnv = process.env.REACT_APP_ENVIRONMENT === 'sandbox';

  const onClickSwtichEnv = () => {
    if (isSandBoxEnv) window.open('https://web.cofee.life/', '_blank');
    else window.open('https://web.sandbox.cofee.life/', '_blank');
  };

  return (
    <div
      className={` flex cursor-pointer items-center justify-between rounded-md text-sm ${
        isProfileList
          ? 'py-2 text-content hover:opacity-25'
          : 'p-2 text-white hover:bg-darkGrey'
      }`}
      onClick={onClickSwtichEnv}
      role="presentation"
    >
      <div>{isSandBoxEnv ? 'Production' : 'Sandbox'}</div>
      <Icon name="link" size="16" />
    </div>
  );
};

export default SandboxSwitch;
