import { Input } from '@components';
import { FieldError } from '@types';
import { NAME_MAX_LENGTH, NAME_MIN_LENGTH } from '@constants/generic';
import { ifscCodeLength } from '@constants/upi';

interface BankAccountDetailsProps {
  info: any;
  onInputChange: (field: string, value: string | number) => void;
  isLoadingAccountDetails: boolean;
  fieldError: FieldError;
  setFieldError: (fieldError: FieldError) => void;
  isVerified?: boolean;
}

const BankAccountDetails = ({
  info,
  onInputChange,
  isLoadingAccountDetails,
  fieldError,
  setFieldError,
  isVerified
}: BankAccountDetailsProps) => {
  const clearError = () => {
    setFieldError({
      field: null,
      message: ''
    });
  };

  const onAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError();
    onInputChange('accountNumber', e.target.value);
  };

  const onConfirmAccountNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    clearError();
    onInputChange('confirmAccountNumber', e.target.value);
  };

  const onIfscChange = (value: string) => {
    clearError();
    onInputChange('ifsc', value.toUpperCase());
  };

  return (
    <div className="w-full">
      <div className="mt-6 flex flex-col gap-6">
        <div>
          <Input
            name="accountNumber"
            value={info?.accountNumber}
            placeholder="Account Number"
            onChange={onAccountNumberChange}
            className="rounded-xl"
            maxLength={NAME_MAX_LENGTH}
            minLength={NAME_MIN_LENGTH}
            error={fieldError.field === 'accountNumber'}
            errorMessage={fieldError.message}
            isLoading={isLoadingAccountDetails}
            disabled={isVerified}
          />
        </div>
        <div>
          <Input
            name="confirmAccountNumber"
            value={info?.confirmAccountNumber}
            placeholder="Confirm account number"
            onChange={onConfirmAccountNumberChange}
            className="rounded-xl"
            maxLength={NAME_MAX_LENGTH}
            minLength={NAME_MIN_LENGTH}
            error={fieldError.field === 'accountNumber'}
            errorMessage={fieldError.message}
            disabled={isVerified}
            isLoading={isLoadingAccountDetails}
          />
        </div>
        <div>
          <Input
            name="ifsc"
            value={info?.ifsc}
            placeholder="IFSC code"
            handleInputChange={onIfscChange}
            className="rounded-xl"
            maxLength={ifscCodeLength}
            minLength={3}
            error={fieldError.field === 'ifsc'}
            errorMessage={fieldError.message}
            disabled={isVerified}
            isLoading={isLoadingAccountDetails}
          />
        </div>
      </div>
      {fieldError.field === 'verify' && (
        <div className="absolute text-xs text-error">{fieldError.message}</div>
      )}
    </div>
  );
};

export default BankAccountDetails;
