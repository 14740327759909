import { PaginatedResponse } from './api';
import { OrgBranchEventIds } from './branches';

export interface CreateEventRequest {
  name: string;
  description: string;
  image_url?: string;
  venue_name: string;
  venue_address_line_1: string;
  venue_address_line_2?: string;
  venue_city: string;
  venue_pincode: string;
  contact_phone_country_code: string;
  contact_phone_alternate_country_code: string;
  location_link?: string;
  venue_state: string;
  contact_name: string;
  contact_email?: string;
  contact_phone: string;
  contact_alternate_phone?: string;
  start_date_time?: string;
  end_date_time?: string;
  additional_doc_url?: string;
  registration_end_date_time?: string;
  event_tickets: EventTicketRequest[];
  form_fields: EventFormField[];
}

export interface EventTicketRequest {
  id?: number;
  name: string;
  price: string;
  is_active?: boolean;
  description?: string;
}

export interface EventFormField {
  id?: number;
  name: string;
  type: string;
  is_required: boolean;
  options: string[] | null;
  to_be_deleted?: boolean;
  display_order: number;
}

export interface CreateEventResponse {
  event_id: string;
  name: string;
  description: string;
  image_url: string;
  form_link: string;
  venue_name: string;
  venue_address_line_1: string;
  venue_address_line_2: string;
  venue_city: string;
  venue_pincode: string;
  location_link: string;
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  contact_alternate_phone: string;
  start_date_time: string;
  end_date_time: string;
  additional_doc_url: string;
  min_ticket_price: string;
}

export enum EventStatus {
  ONGOING = 'ongoing',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled'
}

export enum EventRegistrationStatus {
  OPEN = 'open',
  CLOSED = 'closed'
}

export interface Event {
  id?: string;
  name?: string;
  status?: EventStatus;
  image_url?: string;
  venue_name?: string;
  venue_city?: string;
  start_date_time: string;
  end_date_time: string;
  registration_status: EventRegistrationStatus;
  seats_booked: number;
  registration_end_date: string;
  form_link?: string;
}

export interface EventListItemResponse {
  id: string;
  name: string;
  status: EventStatus;
  image_url: string;
  venue_name: string;
  start_date_time: string;
  end_date_time: string;
  registration_status: string;
  seats_booked: number;
  registration_end_date: string;
}

export type EventListResponse = PaginatedResponse<Event>;

export interface EventStatusChangeMutationProps {
  organizationId: string;
  branchId: string;
  eventId: string;
  status: 'cancelled' | 'closed';
}

export enum EventTicketStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export interface EventTicketStatusChangePayload extends OrgBranchEventIds {
  status: EventTicketStatus;
  ticketId: string;
}

export interface EventDetailsResponse {
  event_id: string;
  name: string;
  image_url: string;
  venue_name: string;
  venue_city: string;
  registration_end_date_time: string;
  start_date_time: string;
  end_date_time: string;
  form_link: string;
  total_registrations: number;
  total_ticket_sold: number;
  total_revenue: string;
  tickets: EventTicketResponse[];
  status: EventStatus;
}

export interface EventTicketResponse {
  ticket_id: string;
  name: string;
  price: string;
  is_active: boolean;
  seats_booked: number;
}

export interface AtendeeDetailsPayload extends OrgBranchEventIds {
  attendeeId: string;
}

export interface AttendeeDetailsResponse {
  name: string;
  mobile: string;
  event_form_submission: EventFormSubmissionResponse[];
}

export interface EventFormSubmissionResponse {
  id: number;
  field_name: string;
  field_value: string;
  field_type: string;
  is_required: boolean;
  display_order: number;
}
export interface FormField {
  form_id: number;
  field_name: string;
  field_type: string;
  is_required: boolean;
  is_active: boolean;
  options?: string[];
  display_order: number;
}

export interface EventFormTicket {
  ticket_id: number;
  name: string;
  price: string;
  is_active: boolean;
  description: string;
}
export interface EventFormDetails {
  event_id: string;
  name: string;
  description: string;
  image_url: string;
  venue_name: string;
  venue_address_line1: string;
  venue_address_line2: string;
  venue_city: string;
  venue_state: string;
  venue_pincode: string;
  location_link: string;
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  contact_alternate_phone: string;
  start_date_time: string;
  end_date_time: string;
  registration_end_date_time: string;
  additional_doc_url: string;
  status: string;
  registration_status: string;
  event_tickets: EventFormTicket[];
  min_ticket_price: string;
  form_fields: FormField[];
  event_registration_status: string;
  event_status: string;
}

export type EditEventProps = {
  organizationId: string;
  branchId: string;
  eventId: string;
};
