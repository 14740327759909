import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AccountType, OrgStatus, UserFlow } from '@constants/generic';
import UserPermissionTypes from '@constants/permissions';
import { AUTH } from '@constants/routes';
import { Event, sendMixpanelEvent } from '@core/analytics';
import { useCreateOrganizationMutation } from '@features/authentication/queries/AuthQuery';
import useCheckPermission from '@hooks/checkPermission';
import useNotify from '@hooks/notify';
import { OrgProfileContext } from '@layouts/constants';
import { ApiError, MapObj } from '@types';
import {
  getLocalStorageItem,
  setLocalStorageItem,
  setMultipleLocalStorageItems
} from '@utils/storage';
import { Org } from 'types/organization';

import SandboxSwitch from './SandboxSwitch';

interface ProfileListCompProps {
  orgList: Org[];
  subscriptionData: any;
  labelStyle?: string;
  accountMethods: {
    onClickLogout: () => void;
    onClickUpgradeToOrg: () => void;
    onClickSwitchAccount: () => void;
  };
  isProfileList: boolean;
}

const ProfileOptions = (props: ProfileListCompProps) => {
  const {
    orgList,
    subscriptionData,
    labelStyle,
    accountMethods,
    isProfileList
  } = props;

  const navigate = useNavigate();
  const { checkPermission } = useCheckPermission();
  const { createErrorAlert } = useNotify();
  const { currentUser, orgFeatureFlags } = useSelector(
    (state: any) => state.user
  );

  const orgId = getLocalStorageItem('organizationId');

  const currentOrg = orgList?.find((org: Org) => org.id === orgId);

  const [createOrganization] = useCreateOrganizationMutation();

  const hasIntegrationEditAccess =
    checkPermission([UserPermissionTypes.INTEGRATION_EDIT]) &&
    orgFeatureFlags &&
    orgFeatureFlags.includes('api_integrations');

  const onClickAddNewOrg = async () => {
    const response = await createOrganization(null);
    if ('data' in response) {
      setMultipleLocalStorageItems([
        { key: 'newOrgId', value: response.data?.organisation_id },
        { key: 'newBranchId', value: response.data?.branch_id },
        { key: 'userFlow', value: UserFlow.CREATE_ORG }
      ]);
      navigate(AUTH.SELECT_ACCOUNT);
    } else if ('error' in response) {
      const error = response.error as ApiError;
      createErrorAlert(error.data?.message);
    }
  };

  const onClickCreateAnAccount = () => {
    setLocalStorageItem('userFlow', UserFlow.USER_ONBOARD);
    navigate(AUTH.SELECT_ACCOUNT);
    sendMixpanelEvent(Event.CREATE_ACCOUNT_CLICKED);
  };

  const createNewButtonInfo: MapObj = {
    [OrgProfileContext.CREATE_ORG]: {
      label: 'Add Organization',
      onClick: onClickAddNewOrg
      // Icon: PlusIcon,
      // iconStroke: theme.grey,
      // labelStyle: 'text-darkGrey'
    },
    [OrgProfileContext.CREATE_ACCOUNT]: {
      label: 'Create an account',
      onClick: onClickCreateAnAccount
      // Icon: PlusIcon,
      // iconStroke: theme.grey,
      // labelStyle: 'text-darkGrey'
    },
    [OrgProfileContext.UPGRADE_TO_ORG]: {
      label: 'Upgrade to Organization',
      onClick: accountMethods?.onClickUpgradeToOrg
      // Icon: UpgradeToOrgIcon,
      // labelStyle: 'text-primary'
    },
    [OrgProfileContext.SWITCH_ACCOUNT]: {
      label: 'Switch Account',
      onClick: accountMethods?.onClickSwitchAccount
      // Icon: SwitchOrgIcon,
      // labelStyle: 'text-darkGrey'
    }
  };

  const context = useMemo(() => {
    if (
      (currentOrg?.status === OrgStatus.APPROVED && !currentOrg.has_plan) ||
      currentOrg?.status === OrgStatus.VERIFICATION_PENDING ||
      currentOrg?.status === OrgStatus.VERIFICATION_SUBMITTED
    ) {
      return '';
    }

    if (
      currentUser?.is_user_onboarded &&
      currentOrg?.type === AccountType.INDIVIDUAL &&
      !currentOrg?.is_upgrade_requested &&
      (currentOrg?.status === OrgStatus.APPROVED ||
        currentOrg?.status === OrgStatus.KYC_PENDING) &&
      subscriptionData?.status !== 'expired'
    )
      return OrgProfileContext.UPGRADE_TO_ORG;
    // Add an Org flow commented out for first phase
    // if (
    //   currentOrg?.role === OrgRoleType.OWNER &&
    //   currentOrg?.type === AccountType.ORGANIZATION
    // )
    //   return OrgProfileContext.CREATE_ORG;
    if (!currentUser?.is_user_onboarded)
      return OrgProfileContext.CREATE_ACCOUNT;
    return '';
  }, [currentUser, currentOrg, subscriptionData]);

  const renderProfileSettingsItem = (ctx: OrgProfileContext) => {
    const { label, onClick } = createNewButtonInfo[ctx];
    return (
      <div
        className={`flex cursor-pointer items-center gap-3 rounded-md ${
          isProfileList ? 'py-2 hover:opacity-25' : 'p-2 hover:bg-darkGrey'
        }`}
        role="presentation"
        onClick={onClick}
      >
        <div className={`text-sm ${labelStyle}`}>{label}</div>
      </div>
    );
  };

  return (
    <div className="my-2">
      {hasIntegrationEditAccess && (
        <SandboxSwitch isProfileList={isProfileList} />
      )}
      {/* Add an Organization / Create an Account / Switch to Organization */}
      {context && renderProfileSettingsItem(context)}
      {orgList &&
        orgList.length > 1 &&
        renderProfileSettingsItem(OrgProfileContext.SWITCH_ACCOUNT)}
      <div
        role="presentation"
        className={`flex cursor-pointer items-center gap-4 rounded-md text-sm ${
          isProfileList ? 'py-2 hover:opacity-25' : 'p-2 hover:bg-darkGrey'
        } ${labelStyle}`}
        onClick={accountMethods?.onClickLogout}
      >
        Logout
      </div>
    </div>
  );
};

export default ProfileOptions;
