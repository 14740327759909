import { MapObj } from '@types';

import theme from '../../themes/colors';

export const DEFAULT_PLACEHOLDER = '--';

export const PAGINATION_LIMIT = 10;

export const NULLDATE = '0001-01-01T00:00:00Z';

export const imageInitialState = {
  file: undefined,
  preview: '',
  image_url: ''
};

export enum SettlementMode {
  UPI = 'UPI',
  BANK = 'BANK_ACCOUNT'
}

export enum AccountType {
  INDIVIDUAL = 'individual',
  ORGANIZATION = 'organisation'
}

export enum OrgRoleType {
  ORGANISATION_ADMIN = 'organisation_admin',
  BRANCH_ADMIN = 'branch_admin',
  GROUP_ADMIN = 'group_admin',
  FINANCE = 'finance',
  OWNER = 'owner',
  ATTENDANCE_MANAGER = 'attendance_manager'
}

export enum OrgStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  KYC_PENDING = 'kyc_pending',
  KYC_SUBMITTED = 'kyc_submitted',
  VERIFICATION_PENDING = 'verification_pending',
  VERIFICATION_SUBMITTED = 'verification_submitted'
}

export const GROUPS_LIST_PAGINATION_LIMIT = 12;
export const EVENTS_LIST_PAGINATION_LIMIT = 10;

export const GROUP_MEMBER_MAX_AMOUNT = 50000;
export const GROUP_MEMBER_MIN_AMOUNT = 1;
export const SPLIT_REQUIRED_AMOUNT_MIN = 5000;
export const NAME_MAX_LENGTH = 30;
export const NAME_MIN_LENGTH = 3;
export const NOTES_MAX_LENGTH = 30;
export const NOTES_MIN_LENGTH = 3;
export const PER_SPLIT_AMOUNT_MIN = 2;
export const QUICK_COLLECT_MAX_AMOUNT = 200000;
export const QUICK_COLLECT_MIN_AMOUNT = 2;
export const EVENT_NAME_MIN_LENGTH = 3;
export const EVENT_NAME_MAX_LENGTH = 100;
export const EVENT_DESCRIPTION_MIN_LENGTH = 3;
export const EVENT_DESCRIPTION_MAX_LENGTH = 1500;

export const DOCUMENT_LIST_WITH_PAN = [
  { id: 'pan', label: 'PAN Card', file: null },
  { id: 'business_license', label: 'Business License', file: null }
];

export const DOCUMENT_LIST_WITH_GST = [
  { id: 'gst_certificate', label: 'GSTIN Certificate', file: null },
  { id: 'business_license', label: 'Business License', file: null }
];

export enum UserFlow {
  USER_ONBOARD = 'onboard',
  UPGRADE_TO_ORG = 'upgrade_to_org',
  CREATE_ORG = 'create_org'
}

export const FlowLabels: MapObj = {
  [UserFlow.USER_ONBOARD]: 'Sign Up',
  [UserFlow.UPGRADE_TO_ORG]: 'Upgrade to organization',
  [UserFlow.CREATE_ORG]: 'Create an organization'
};

export const currentOrgAvatarColor = theme.meroon;

export const phoneNumberErrorMessage = 'Mobile number should have 10 digits';

export const duplicatePhoneNumberErrorMessage =
  'Primary number and alternate number cannot be the same';

export const amountErrorMessage = 'Minimum amount should be Rs 2';

export const FEE_CATEGORY_LIMIT = 20;
export const BANK_ACCOUNT_LIMIT = 20;

export enum SubscriptionStatus {
  EXPIRED = 'expired'
}

export const SubscriptionPlanExpiredMessage = 'Subscription plan has expired';

export const S3_URL =
  'https://cofe-dev-sg-s3-core-data.s3.ap-southeast-1.amazonaws.com';
