import { Button } from '@components';

import { GroupPerfomancePageLimit } from '../constants';

interface GroupPerfomancePaginationProps {
  page: number;
  totalGroups: number;
  onClickPrev: () => void;
  onClickNext: () => void;
}

const GroupPerfomancePagination = ({
  page,
  totalGroups,
  onClickPrev,
  onClickNext
}: GroupPerfomancePaginationProps) => {
  const lowerLimit = (page - 1) * GroupPerfomancePageLimit + 1;
  const upperLimit = Math.min(page * GroupPerfomancePageLimit, totalGroups);

  return (
    <div className="flex items-center gap-2">
      {/* do not show left arrow in first page */}
      {page !== 1 && (
        <Button
          variant="transparent"
          size="small"
          handleClick={onClickPrev}
          leftIconName="left"
          className={`!h-6 !w-6 ${page === 1 ? '' : 'bg-grey50'}`}
          leftIconStyle="!m-0 !p-0.5"
          leftIconFill="text-grey80"
        />
      )}

      <div className="text-grey80">{`${lowerLimit} - ${upperLimit} / ${totalGroups} Groups`}</div>

      {/* do not show right arrow in last page */}
      {page * GroupPerfomancePageLimit < totalGroups && (
        <Button
          variant="transparent"
          size="small"
          handleClick={onClickNext}
          leftIconName="right"
          className={`!h-6 !w-6 ${
            page * GroupPerfomancePageLimit >= totalGroups ? '' : 'bg-grey50'
          }`}
          leftIconStyle="!m-0 !p-0.5"
          leftIconStroke="!text-grey80"
        />
      )}
    </div>
  );
};

export default GroupPerfomancePagination;
