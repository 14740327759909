import Dropdown from '@components/drop-down/Dropdown';
import Input from '@components/input/Input';
import Label from '@components/label/Label';
import {
  cities,
  countries
} from '@features/admin/components/location-details/cityMapping';

interface MemberAddressProps {
  addressLine1: string;
  addressLine2: string;
  country: string;
  state: string;
  city: string;
  pincode: string;
  onLine1Change: (value: string) => void;
  onLine2Change: (value: string) => void;
  onCountryChange: (value: string) => void;
  onStateChange: (value: string) => void;
  onCityChange: (value: string) => void;
  onPincodeChange: (value: string) => void;
  pincodeError: string;
  onBlurPincode: () => void;
}

const MemberAddress = ({
  addressLine1,
  addressLine2,
  country,
  state,
  city,
  pincode,
  onLine1Change,
  onLine2Change,
  onCountryChange,
  onStateChange,
  onCityChange,
  onPincodeChange,
  pincodeError,
  onBlurPincode
}: MemberAddressProps) => {
  const countryList = countries
    .map((item) => ({
      id: item,
      label: item,
      value: item
    }))
    .concat({ id: 'Others', label: 'Others', value: 'Others' });

  const stateList = Object.keys(cities).map((item) => ({
    id: item,
    label: item,
    value: item
  }));

  return (
    <div className="grid w-full grid-cols-2 gap-4 py-3">
      <div>
        <Label label="Address Line 1" />
        <Input
          value={addressLine1}
          handleInputChange={onLine1Change}
          type="text"
          placeholder="Enter Address Line 1"
          maxLength={150}
        />
      </div>
      <div>
        <Label label="Address Line 2" />
        <Input
          value={addressLine2}
          handleInputChange={onLine2Change}
          type="text"
          placeholder="Enter Address Line 2"
          maxLength={150}
        />
      </div>
      <div>
        <Label label="Country" />
        <Dropdown
          currentFilter={country || ''}
          filterOptions={countryList}
          onSelectFilter={onCountryChange}
          className="h-12.5 w-full !rounded-lg !bg-grey10"
          optionsStyle="!w-full max-h-56 overflow-y-auto"
          placeholder="Select Country"
        />
      </div>
      <div>
        <Label label="State" />
        <Dropdown
          currentFilter={state || ''}
          filterOptions={stateList}
          onSelectFilter={onStateChange}
          searchBarPlaceholder="Search state"
          className="h-12.5 w-full !rounded-lg !bg-grey10"
          optionsStyle="!w-full max-h-56 overflow-y-auto"
          placeholder="Select State"
          disabled={country !== 'India'}
        />
      </div>
      <div>
        <Label label="City" />
        <Input
          value={city}
          handleInputChange={onCityChange}
          placeholder="Enter City"
          maxLength={30}
          disabled={!state}
        />
      </div>
      <div>
        <Label label="Pincode" />
        <Input
          value={pincode}
          handleInputChange={onPincodeChange}
          placeholder="Enter pincode"
          type="number"
          maxLength={6}
          error={!!pincodeError}
          errorMessage={pincodeError}
          handleInputBlur={onBlurPincode}
          disabled={country !== 'India'}
        />
      </div>
    </div>
  );
};

export default MemberAddress;
