import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { Icon as DropdownIcon } from '@components';
import { Event, sendMixpanelEvent } from '@core/analytics';
import ApiMethodChip from '@features/docs/components/api-method-chip/ApiMethodChip';

import { Item, SidebarItemProps } from './types';

const SidebarItem = (props: SidebarItemProps) => {
  const location = useLocation();

  const { isCurrentRoute, item, path, isOrgLevelItem, branchId } = props;

  const { id, Icon, label, route, children, method } = item;

  const [isExpanded, setIsExpanded] = useState(isCurrentRoute);

  const itemBgColor = isCurrentRoute ? 'bg-primaryLite9' : 'hover:bg-grey10';
  const itemColor = isCurrentRoute ? 'text-primary' : 'text-bgGrey';

  const sideBarClick = () => {
    sendMixpanelEvent(Event.SIDEBAR_TAB_CLICKED, {
      section: label,
      branchId
    });

    if (children) {
      setIsExpanded(!isExpanded);
    }
  };

  const renderNestedList = (child: Item) => {
    const { id: childId, route: childRoute } = child;
    const isCurrentChildRoute = (location.pathname + location.hash).startsWith(
      childRoute
    );

    return (
      <SidebarItem
        key={childId}
        item={child}
        path={childRoute}
        isCurrentRoute={isCurrentChildRoute}
        isOrgLevelItem
        branchId={branchId}
      />
    );
  };

  return (
    <div>
      <Link
        key={id}
        to={path || route}
        className={
          isOrgLevelItem
            ? 'flex items-center px-1'
            : `flex items-center gap-3 rounded-lg px-4 py-3 ${itemBgColor}`
        }
        onClick={sideBarClick}
      >
        <div className="flex items-center gap-3">
          {!isOrgLevelItem && Icon && (
            <Icon className={clsx('size-6', itemColor)} />
          )}
          {method && <ApiMethodChip method={method} />}
          <span
            className={`break-words text-sm font-medium ${itemColor} ${
              isOrgLevelItem ? 'hover:opacity-25' : ''
            }`}
          >
            {label}
          </span>
        </div>
        {(children?.length ?? 0) > 0 && (
          <span className="text-bgGrey">
            <DropdownIcon
              name="down-icon-white"
              size="24"
              className={isExpanded ? 'rotate-180' : 'rotate-0'}
              fill="currentColor"
            />
          </span>
        )}
      </Link>

      {isExpanded && (children?.length ?? 0) > 0 && (
        <div className="ml-6 mt-3 flex flex-col gap-3">
          {children?.map((child) => renderNestedList(child))}
        </div>
      )}
    </div>
  );
};

export default SidebarItem;
