import { parsePhoneNumberWithError } from 'libphonenumber-js';

import {
  DEFAULT_PLACEHOLDER,
  NAME_MIN_LENGTH,
  NOTES_MIN_LENGTH
} from '@constants/generic';
import { Column } from '@types';

export const getRandomId = (): string =>
  (Math.random() + 1).toString(36).substring(7);

export const formatMobileNumber = (num: string) =>
  // Assuming a format like 'xxxxx xxxxx'
  `${num.slice(0, 5)} ${num.slice(5)}`;

export const addKeyValue = (
  array: Column[],
  accessor: string,
  key: string,
  value: string
) =>
  array.map((element) =>
    element.accessor === accessor ? { ...element, [key]: value } : element
  );

// export const getColorValue = () => Math.floor(Math.random() * 56) + 200;

// export const getRandomColor = () =>
//   `rgb(${getColorValue()}, ${getColorValue()}, ${getColorValue()}`;

export const getRandomColor = (index?: number) => {
  const colorsArray = [
    '#CBE4F9',
    '#CDF5F6',
    '#EFF9DA',
    '#D6CDEA',
    '#F9D8D6',
    '#EBFFE4'
  ];

  const colorIndex =
    (index || Math.floor(Math.random() * colorsArray.length)) %
    colorsArray.length;

  return colorsArray[colorIndex];
};

export const getFirstLetters = (str: string) =>
  str
    ?.split(' ')
    .map((word) => word[0])
    .filter((_, index) => index < 2)
    .join('')
    .toUpperCase();

export const checkCountryCodeInPhoneNumber = (phone: string) => {
  let formattedNumber: string;
  if (phone.length === 12 && phone?.slice(0, 2) === '91') {
    formattedNumber = `${phone}`;
  } else if (phone.length === 10) {
    formattedNumber = `91${phone}`;
  } else formattedNumber = `${phone}`;
  return formattedNumber;
};

export const sanitizeNumberInput = (
  input: string,
  allowDecimals = false
): string => {
  const regex = allowDecimals ? /[^0-9.]/g : /[^0-9]/g; // Allow '.' for decimals
  let sanitized = input.replace(regex, '');

  if (allowDecimals) {
    // Ensure only one decimal point is allowed
    const parts = sanitized.split('.');
    if (parts.length > 2) {
      sanitized = `${parts[0]}.${parts.slice(1).join('')}`;
    }
  }

  return sanitized;
};

// i/p: 91XXXXXXXXXX or XXXXXXXXXX
// o/p: +91-XXXXXXXXXX or XXXXXXXXXX
export const getPhoneNumberWith91 = (phoneNumber: number | string) => {
  const formattedNumber = String(phoneNumber);
  if (!phoneNumber) return DEFAULT_PLACEHOLDER;
  if (formattedNumber.slice(0, 2) === '91') {
    return `+${formattedNumber?.slice(0, 2)}-${formattedNumber?.slice(2)}`;
  }
  return `${formattedNumber}`;
};

// i/p: 91XXXXXXXXXX or XXXXXXXXXX
// o/p: XXXXXXXXXX
export const getFormattedPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';
  const formattedNumber = parsePhoneNumberWithError(phoneNumber);
  if (phoneNumber.length > 10)
    return `+${formattedNumber.countryCallingCode}-${formattedNumber.nationalNumber}`;
  return phoneNumber;
};

export const hasNonZeroValue = (arr: Number[]) =>
  // Using Array.some() to check if there is any non-zero value in the array
  arr.some((value) => value !== 0);

export const valueOf = (value: any) => {
  if (value) return value;
  if (value === 0) return value;
  return DEFAULT_PLACEHOLDER;
};

export const isMinLengthSatisfied = (
  value: string,
  minLength = NAME_MIN_LENGTH
) => {
  if (value !== '' && value.length < minLength) return false;
  return true;
};

export const getNameErrorMessage = (minLength = NAME_MIN_LENGTH) =>
  `Minimum length should be ${minLength}`;

export const getAmountErrorMessage = (minAmount: number) =>
  `Minimum amount should be Rs ${minAmount}`;

export const getNotesErrorMessage = (minLength = NOTES_MIN_LENGTH) =>
  `Please enter at least ${minLength} characters.`;

export const checkArraysAreSame = (
  array1: string[],
  array2: string[]
): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }
  const sortedA = array1.slice().sort();
  const sortedB = array2.slice().sort();
  return sortedA.every((element, index) => element === sortedB[index]);
};
