import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CheckedIcon, LogoutModalIcon, UpgradeToOrgIcon } from '@icons';

import AvatarWithName from '@components/avatar-with-name/AvatarWithName';
import { Button, ConfirmationModal, Modal } from '@components/index';
import {
  AccountType,
  currentOrgAvatarColor,
  OrgStatus,
  UserFlow
} from '@constants/generic';
import { AUTH, DASHBOARD } from '@constants/routes';
import testId from '@constants/testId';
import { Event, sendMixpanelEvent } from '@core/analytics';
import {
  useCreateOrganizationMutation,
  useLazyLogoutQuery,
  useOnboardOrganizationMutation
} from '@features/authentication/queries/AuthQuery';
import { resetAuthState } from '@features/authentication/slices/AuthSlice';
import { resetGroupState } from '@features/group-management/slices/groupSlice';
import { resetMemberState } from '@features/member-management/slices/MemberSlice';
import useNotify from '@hooks/notify';
import {
  resetBranchState,
  updateBranchFilter
} from '@layouts/main-header/branchSlice';
import { resetUserState } from '@layouts/main-header/userSlice';
import { ApiError } from '@types';
import { getRandomColor } from '@utils/generic';
import {
  clearAllLocalStorageItems,
  getLocalStorageItem,
  setMultipleLocalStorageItems
} from '@utils/storage';
import { Org } from 'types/organization';

interface AccountModalsProps {
  orgList?: Org[];
  setAccountMethods: (methods: any) => void;
}

const AccountModals = (props: AccountModalsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createErrorAlert, createSuccessAlert } = useNotify();
  const userId = getLocalStorageItem('userId');
  const orgId = getLocalStorageItem('organizationId');

  const { orgList, setAccountMethods } = props;

  const { currentUser } = useSelector((state: any) => state.user);

  const [onboardOrganization] = useOnboardOrganizationMutation();
  const [logout, { isLoading: isLogoutLoading }] = useLazyLogoutQuery();
  const [createOrganization, { isLoading: isCreateOrgLoading }] =
    useCreateOrganizationMutation();

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState<boolean>(false);
  const [isUpgradeToOrgModalOpen, setUpgradeToOrgModalOpen] =
    useState<boolean>(false);
  const [isSwitchAccountModalOpen, setSwitchAccountModalOpen] =
    useState<boolean>(false);

  const currentOrg = orgList?.find((org: Org) => org.id === orgId);
  const [selectedOrg, setSelectedOrg] = useState<Org | undefined>(currentOrg);

  const onClickLogout = () => {
    setIsLogoutModalOpen(true);
    sendMixpanelEvent(Event.LOGOUT_CLICKED);
  };

  const onCancelLogout = () => {
    setIsLogoutModalOpen(false);
    sendMixpanelEvent(Event.LOGOUT_CANCEL_BUTTON_CLICKED);
  };

  const isCurrentOrg = (org: Org | undefined) => org?.id === currentOrg?.id;

  const handleLogoutConfirm = async () => {
    sendMixpanelEvent(Event.LOGOUT_CONFIRM_BUTTON_CLICKED, {
      user_id: userId
    });
    const res = await logout(userId);
    if ('data' in res) {
      dispatch(resetGroupState());
      dispatch(resetUserState());
      dispatch(resetAuthState());
      dispatch(resetMemberState());
      dispatch(resetBranchState());
      clearAllLocalStorageItems();
      sendMixpanelEvent(Event.LOGOUT_SUCCESS, { user_id: userId });
      navigate(AUTH.LOGIN, { replace: true });
    } else if ('error' in res) {
      const error = res.error as ApiError;
      createErrorAlert(error.data?.message);
      sendMixpanelEvent(Event.LOGOUT_ERROR, {
        user_id: userId,
        error: error.data?.errorCode
      });
    }
  };

  const createNewOrg = async () => {
    const response = await createOrganization(null);
    if ('data' in response) {
      setMultipleLocalStorageItems([
        { key: 'upgradedOrgId', value: response.data?.organisation_id },
        { key: 'upgradedBranchId', value: response.data?.branch_id },
        { key: 'userFlow', value: UserFlow.UPGRADE_TO_ORG }
      ]);
      navigate(AUTH.SELECT_ACCOUNT);
    } else if ('error' in response) {
      const error = response.error as ApiError;
      createErrorAlert(error.data?.message);
    }
    setUpgradeToOrgModalOpen(false);
  };

  const onClickUpgradeToOrg = () => {
    setUpgradeToOrgModalOpen(true);
    sendMixpanelEvent(Event.UPGRADE_TO_ORGANIZATION_POPUP_VIEWED, {
      individual_organization_name: currentUser?.name || ''
    });
  };

  const upgradeIndividualLiteToOrgLite = async () => {
    const requestBody = {
      organizationId: orgId,
      body: {
        branch_name: currentOrg?.default_branch_name.trim(),
        organisation_name: currentOrg?.name.trim(),
        branch_id: currentOrg?.default_branch_id,
        is_lite: true
      }
    };
    const response = await onboardOrganization(requestBody);
    if ('data' in response) {
      createSuccessAlert('Updated to organization successfully');
      setUpgradeToOrgModalOpen(true);
      window.location.reload();
    } else {
      const error = response.error as ApiError;
      createErrorAlert(
        error?.data?.message || 'Error in upgrading to organization'
      );
    }
  };

  const onConfirmUpgradeToOrg = () => {
    sendMixpanelEvent(Event.UPGRADE_TO_ORGANIZATION_CONFIRM_BUTTON_CLICKED, {
      individual_organization_name: currentUser?.name || ''
    });
    if (
      currentOrg?.status === OrgStatus.KYC_PENDING &&
      currentOrg?.type === AccountType.INDIVIDUAL &&
      !currentOrg?.is_upgrade_requested &&
      !currentOrg?.is_kyc_deferred
    ) {
      upgradeIndividualLiteToOrgLite();
    } else createNewOrg();
  };

  const onCancelUpgradeToOrg = () => {
    setUpgradeToOrgModalOpen(false);
    sendMixpanelEvent(Event.UPGRADE_TO_ORGANIZATION_CANCEL_BUTTON_CLICKED, {
      individual_organization_name: currentUser?.name || ''
    });
  };

  const onClickSwitchAccount = () => {
    setSwitchAccountModalOpen(true);
    sendMixpanelEvent(Event.SWITCH_ACCOUNT_CLICKED);
  };

  const onCloseSwitchAccountModal = () => {
    setSelectedOrg(currentOrg);
    setSwitchAccountModalOpen(false);
    sendMixpanelEvent(Event.SWITCH_ACCOUNT_CANCEL_BUTTON_CLICKED);
  };

  const onSelectOrg = (organization: Org) => {
    setSelectedOrg(organization);
  };

  const onSwitchOrg = () => {
    if (selectedOrg) {
      if (isCurrentOrg(selectedOrg)) return;
      const {
        id,
        default_branch_id: defaultBranchId,
        default_branch_name: defaultBranchName
      } = selectedOrg;
      setMultipleLocalStorageItems([
        { key: 'organizationId', value: id },
        { key: 'branchId', value: defaultBranchId },
        { key: 'branchName', value: defaultBranchName }
      ]);
      dispatch(
        updateBranchFilter({
          branchId: defaultBranchId,
          branchName: defaultBranchName
        })
      );
      navigate(DASHBOARD);
      setSwitchAccountModalOpen(false);
      sendMixpanelEvent(Event.SWITCH_ACCOUNT_CONTINUE_BUTTON_CLICKED, {
        switched_to: selectedOrg.name
      });
    }
  };

  useEffect(() => {
    setAccountMethods({
      onClickLogout,
      onClickUpgradeToOrg,
      onClickSwitchAccount
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAccountMethods]);

  return (
    <>
      {isUpgradeToOrgModalOpen && (
        <ConfirmationModal
          buttonIds={{
            confirmButton: testId.header.upgradeToOrgPopup.confirm,
            cancelButton: testId.header.upgradeToOrgPopup.cancel
          }}
          isOpen
          title="Upgrade to Organization ?"
          message="Once your request to upgrade to organization is accepted, you will no longer have access to your individual account."
          ModalIcon={UpgradeToOrgIcon}
          handleConfirm={onConfirmUpgradeToOrg}
          handleCancel={onCancelUpgradeToOrg}
          isConfirmButtonLoading={isCreateOrgLoading}
          className="max-w-95"
        />
      )}
      {isLogoutModalOpen && (
        <ConfirmationModal
          buttonIds={{
            confirmButton: testId.header.logoutPopup.confirm,
            cancelButton: testId.header.logoutPopup.cancel
          }}
          isOpen={isLogoutModalOpen}
          ModalIcon={LogoutModalIcon}
          title="Logging Out?"
          message="Are you sure you want to log out?"
          confirmButtonStyle="text-primary"
          handleConfirm={handleLogoutConfirm}
          handleCancel={onCancelLogout}
          className="w-78"
          isConfirmButtonLoading={isLogoutLoading}
        />
      )}
      {isSwitchAccountModalOpen && (
        <Modal
          isOpen={isSwitchAccountModalOpen}
          onCancel={onCloseSwitchAccountModal}
          isCloseIconRequired
          className="h-fit w-125 p-6"
        >
          <div className="mb-4 text-xl font-bold text-content">
            Switch Account
          </div>
          <div className="max-h-48 overflow-auto">
            {orgList?.map((org, index) => {
              const isSelectedOrg = org.id === selectedOrg?.id;
              return (
                <div
                  key={org.id}
                  className="flex cursor-pointer items-center justify-between p-3 hover:bg-grey20"
                  role="presentation"
                  onClick={() => onSelectOrg(org)}
                >
                  <AvatarWithName
                    name={org.name}
                    subText={org.role_name || ''}
                    imageUrl={org.image_url}
                    bgColor={
                      isCurrentOrg(org)
                        ? currentOrgAvatarColor
                        : getRandomColor(index)
                    }
                    avatarClass={isCurrentOrg(org) ? 'text-white' : ''}
                    textWrapperClass="!items-start"
                  />
                  {isSelectedOrg && <CheckedIcon className="h-3 w-4" />}
                </div>
              );
            })}
          </div>
          <div className="mt-4 flex w-full justify-end">
            <Button
              id={testId.header.switchAccountModal.cancel}
              label="Cancel"
              variant="secondary"
              handleClick={onCloseSwitchAccountModal}
            />
            <Button
              id={testId.header.switchAccountModal.continue}
              label="Continue"
              handleClick={onSwitchOrg}
              className="ml-4"
              disabled={isCurrentOrg(selectedOrg)}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default AccountModals;
