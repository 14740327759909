import testId from '@constants/testId';

export enum CertificateLabel {
  pan = 'PAN',
  gstin = 'GSTIN'
}

export const certificateOptions = [
  {
    id: testId.groupsPage.upgradeToCofeeModal.panRadioButton,
    key: 'pan',
    value: CertificateLabel.pan
  },
  {
    id: testId.groupsPage.upgradeToCofeeModal.gstinRadioButton,
    key: 'gstin',
    value: CertificateLabel.gstin
  }
];

export enum CertificateType {
  PAN = 'pan',
  GSTIN = 'gstin'
}

export const tootlipText = {
  organizationName: 'Name of your business',
  individualDisplayName: 'This name will be displayed on the receipt',
  panGstinInfo:
    'By skipping PAN/GSTIN, you will enter Lite mode where payment links will not be sent out.'
};
