import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Icon, Spinner } from '@components/index';
import UserPermissionTypes from '@constants/permissions';
import { PAGE_NOT_FOUND } from '@constants/routes';
import { trackPageView } from '@core/analytics';
import { useLazyGetAttendeeDetailsQuery } from '@features/event-registration/queries/EventQuery';
import {
  AnswerType,
  Question,
  QuestionType
} from '@features/event-registration/types';
import useCheckPermission from '@hooks/checkPermission';
import { formatISODate } from '@utils/date';
import { getLocalStorageItem } from '@utils/storage';

import theme from '../../../../../themes/colors';

const Response = ({ question }: { question: Question }) => {
  switch (question.type) {
    case QuestionType.TEXT:
    case QuestionType.NUMBER:
    case QuestionType.TEXT_AREA: {
      const textAnswer = question.answer as AnswerType[QuestionType.TEXT];
      return (
        <div className="mt-3 border-b border-grey30 pb-2 text-base text-darkGrey">
          {textAnswer}
        </div>
      );
    }
    case QuestionType.DATE: {
      const dateAnswer = question.answer as AnswerType[QuestionType.DATE];
      return (
        <div className="mt-5 flex border-b border-grey30 pb-3 ">
          <Icon name="calendar" />
          <div className="pl-3 text-base text-darkGrey">
            {formatISODate({
              date: dateAnswer
            })}
          </div>
        </div>
      );
    }
    case QuestionType.MULTIPLE_CHOICE: {
      const choices =
        question.answer as AnswerType[QuestionType.MULTIPLE_CHOICE];
      return (
        <>
          {choices.map((choice, index) => (
            <div
              key={choice}
              className={`flex items-center ${
                index === choices.length - 1 ? '' : 'pb-3'
              }`}
            >
              <Icon
                name="green-check"
                className="size-3 min-h-5 min-w-4"
                size="none"
                stroke={theme.primary}
              />
              <div className="pl-2 text-base text-darkGrey">{choice}</div>
            </div>
          ))}
        </>
      );
    }

    case QuestionType.DROP_DOWN:
    case QuestionType.SINGLE_CHOICE: {
      const choice = question.answer as AnswerType[QuestionType.DROP_DOWN];
      return (
        <div key={choice} className="flex items-center">
          <Icon
            name="green-check"
            className="size-3 min-h-5 min-w-4"
            size="none"
            stroke={theme.primary}
          />
          <div className="pl-2 text-base text-darkGrey">{choice}</div>
        </div>
      );
    }

    default:
      return null;
  }
};

const AttendeeDetails: React.FC = () => {
  const navigate = useNavigate();
  const { eventId, attendeeId } = useParams();
  const [searchParams] = useSearchParams();
  const { checkPermission } = useCheckPermission();

  const branchId: string = searchParams.get('branchId') || '';

  const organizationId = getLocalStorageItem('organizationId');

  const [getAttendeeDetails, { data, isLoading }] =
    useLazyGetAttendeeDetailsQuery();

  const hasEventAttendeeViewAccess = checkPermission([
    UserPermissionTypes.EVENT_ATTENDEE_VIEW
  ]);

  useEffect(() => {
    if (!hasEventAttendeeViewAccess) {
      navigate(PAGE_NOT_FOUND);
    }
    if (eventId && attendeeId) {
      trackPageView('Attendee Details', { branchId, eventId, attendeeId });
      getAttendeeDetails({
        organizationId,
        branchId,
        eventId,
        attendeeId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="flex h-150 items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <div className="mt-[30px] rounded-xl bg-white pb-2">
      <div className="flex items-center p-4">
        <button onClick={() => navigate(-1)} type="button">
          <Icon
            name="back"
            size="extralarge"
            stroke={theme.grey20}
            className="size-3.5"
          />
        </button>
        <h1 className="ml-2 text-lg font-semibold text-content">
          Attendee Details
        </h1>
      </div>
      <hr className="border-t border-grey30" />
      {data.questions.map((question) => (
        <div key={question.id} className="my-6 max-w-[787px] px-6">
          {((Array.isArray(question.answer) && question.answer[0].length > 0) ||
            (!Array.isArray(question.answer) && question.answer)) && (
            <>
              <div className="mb-3 font-medium text-grey">
                {question.question}{' '}
                {question.required && (
                  <span className="text-errorLite5">*</span>
                )}
              </div>
              <Response question={question} />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default AttendeeDetails;
