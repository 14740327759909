import {
  BaseQueryApi,
  BaseQueryFn
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query/react';
import { Mutex } from 'async-mutex';

import { API_URL, END_POINTS } from '@config';
import ApiTags from '@constants/api-tags';
import {
  ErrorCode,
  OrgLevel403ErrorCodes,
  OrgLevel500ErrorCodes,
  RoleLevel403ErrorCodes
} from '@constants/errorCodes';
import { AUTH, PAGE_NOT_FOUND } from '@constants/routes';
import { ApiError, GetOrganizationListResponse, TokenResponse } from '@types';
import {
  clearAllLocalStorageItems,
  getLocalStorageItem,
  setLocalStorageItem,
  setTokensInStorage,
  StorageKeys
} from '@utils/storage';
import { Org } from 'types/organization';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}`,
  prepareHeaders: (headers) => {
    const token = getLocalStorageItem(StorageKeys.ACCESS_TOKEN);
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    const organizationId = getLocalStorageItem('organizationId');
    if (organizationId) {
      headers.set('x-org-id', organizationId);
    }

    return headers;
  }
});

const handle403Error = async (
  errorCode: string,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const code = errorCode as ErrorCode;
  if (RoleLevel403ErrorCodes.includes(code)) {
    const orgListResult = await baseQuery(
      {
        url: END_POINTS.ORGANIZATION.GET_ORGANIZATION_LIST,
        method: 'GET'
      },
      api,
      extraOptions
    );
    if (orgListResult?.data) {
      const orgList = orgListResult.data as GetOrganizationListResponse;
      const organizationId = getLocalStorageItem('organizationId');
      const organizationData = orgList.find(
        (org: Org) => org.id === organizationId
      );

      if (organizationData) {
        const branchId = organizationData.default_branch_id;
        const branchName = organizationData.default_branch_name;
        setLocalStorageItem('branchId', branchId);
        setLocalStorageItem('branchName', branchName);
      }
    }
    window.location.replace(PAGE_NOT_FOUND);
  } else if (OrgLevel403ErrorCodes.includes(code)) {
    clearAllLocalStorageItems();
    window.location.replace(AUTH.LOGIN);
  }
};

const handle500Error = (errorCode: string, userType: string) => {
  if (
    userType !== 'admin' &&
    OrgLevel500ErrorCodes.includes(errorCode as ErrorCode)
  ) {
    clearAllLocalStorageItems();
    window.location.replace(AUTH.LOGIN);
  }
};

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = getLocalStorageItem(StorageKeys.REFRESH_TOKEN);
        const mobile = getLocalStorageItem('mobile');
        const refreshResult = await baseQuery(
          {
            url: END_POINTS.AUTH.REFRESH,
            method: 'POST',
            body: { refresh_token: refreshToken, mobile: String(mobile) }
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          setTokensInStorage(refreshResult.data as TokenResponse);
          // Retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          clearAllLocalStorageItems();
          window.location.replace(AUTH.LOGIN);
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  } else if (result.error) {
    const error = result.error as ApiError;
    const errorCode = error.data?.errorCode;
    if (error.status === 403 && errorCode) {
      await handle403Error(errorCode, api, extraOptions);
    } else if (error.status === 500 && errorCode) {
      const userType = getLocalStorageItem('userType');
      handle500Error(errorCode, userType);
    }
  }
  return result;
};

// eslint-disable-next-line import/prefer-default-export
export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: false,
  refetchOnReconnect: false,
  tagTypes: Object.values(ApiTags)
});
