import { defaultCountryCode } from '@components/country-code/constants';
import { dayOptions, MonthDay } from '@components/payment-collection/constants';
import {
  handleMonthDateSelect,
  handleTermDateSelect,
  handleWeekDateSelect
} from '@components/payment-collection/helper';
import { MonthArray } from '@constants/groups';
import {
  cities,
  countries
} from '@features/admin/components/location-details/cityMapping';
import {
  isValidBloodGroup,
  isValidInsureValue
} from '@features/member-management/helper';
import { UserProfileCustomField } from '@features/member-management/types';
import { isValidPhoneNumber } from '@helpers/generic';
import {
  AddMemberInput,
  DateRange,
  EditGroupType,
  FeeCategoryDataType,
  FeeConfigurationType,
  FormDataType,
  GroupDetailsType,
  Member,
  MemberListType,
  PaymentCollectionComponentType
} from '@types';
import {
  formatISODate,
  getOrdinalDateString,
  isFirstDayOfMonth,
  isLastDayOfMonth
} from '@utils/date';
import { checkArraysAreSame } from '@utils/generic';
import { getLocalStorageItem } from '@utils/storage';
import { capitalizeFirstLetter } from '@utils/stringFormat';
import {
  isInvalidAmount,
  isInvalidEmail,
  isInvalidGroupName,
  isInvalidMemberName
} from '@utils/validations';

import {
  defaultTemplateHeaders,
  PaymentCollectionOptions,
  PaymentCollectionType
} from './constants';
import { AddMemberModeType } from './types';

export const getPaymentCollectionComponentType = (
  frequency: string,
  interval_frequency: number
) => {
  let paymentType: PaymentCollectionComponentType =
    PaymentCollectionComponentType.DEFAULT;
  if (frequency === 'monthly' && interval_frequency === 1) {
    paymentType = PaymentCollectionComponentType.MONTHLY;
  } else if (frequency === 'monthly' && interval_frequency > 1) {
    paymentType = PaymentCollectionComponentType.BY_TERM;
  } else if (frequency === 'weekly') {
    paymentType = PaymentCollectionComponentType.WEEKLY;
  } else if (frequency === 'once')
    paymentType = PaymentCollectionComponentType.ONCE;
  return paymentType;
};

export const getDayType = (date: Date) => {
  const day = new Date(date);
  if (isFirstDayOfMonth(day)) return MonthDay.FIRST;
  if (isLastDayOfMonth(day)) return MonthDay.LAST;
  return MonthDay.CUSTOM;
};

const getCustomDate = (date: Date) =>
  getOrdinalDateString(String(new Date(date).getDate()));

export const formatDueDatePlaceholder = (
  type: PaymentCollectionType,
  frequency: number,
  date: string | Date,
  isImmediatePayment?: boolean
) => {
  const getFormattedMonthValue = () => {
    if (date === 'first_day' || date === '1') {
      return 'First day of month';
    }
    if (date === 'last_day') {
      return 'Last day of month';
    }
    return `${getOrdinalDateString(date as string)} day of month`;
  };
  const getFormattedTermValue = () => {
    if (date === 'first_day' || date === '1') {
      return 'first day';
    }
    if (date === 'last_day') {
      return 'last day';
    }
    return `${getOrdinalDateString(date as string)} day`;
  };

  // Weekly
  if (type === PaymentCollectionType.WEEKLY)
    return `Weekly: ${capitalizeFirstLetter(date as string)}`;
  // Once
  if (type === PaymentCollectionType.ONCE) {
    if (isImmediatePayment) {
      return `Once: Immediately`;
    }
    return `Once: ${formatISODate({
      date: new Date(date),
      format: 'dd-mm-yyyy'
    })}`;
  }
  // Monthly
  if (type === PaymentCollectionType.MONTHLY) {
    return `Monthly: ${getFormattedMonthValue()}`;
  }
  // By Term
  if (type === PaymentCollectionType.BY_TERM) {
    const everyXMonthsOn = `Every ${frequency} months on`;
    return `${everyXMonthsOn} ${getFormattedTermValue()}`;
  }
  return date;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dueDateDisplayText = {
  [PaymentCollectionType.BY_TERM]: {
    first_day: 'First day of every term',
    last_day: 'Last day of every term',
    custom: 'of every term'
  },
  [PaymentCollectionType.MONTHLY]: {
    first_day: 'First day of month',
    last_day: 'Last day of month',
    custom: 'day of month'
  }
};

export const formatDueDateDisplayText = (
  type: PaymentCollectionType,
  date: Date,
  isImmediatePayment?: boolean
) => {
  const dayType = getDayType(date);
  if (type === PaymentCollectionType.WEEKLY)
    return `${capitalizeFirstLetter(
      dayOptions[new Date(date).getDay()].value
    )}`;
  if (type === PaymentCollectionType.ONCE) {
    if (isImmediatePayment) {
      return 'Immediately';
    }
    return `${formatISODate({
      date: new Date(date),
      format: 'dd-mm-yyyy'
    })}`;
  }
  const suffix = dueDateDisplayText[type][dayType];
  const dateValue =
    dayType === 'custom' ? `${getCustomDate(date)} ${suffix}` : suffix;

  return `${dateValue}`;
};

export const getPaymentCollectionType = (
  frequency: string,
  interval_frequency: number
) => {
  let paymentType: PaymentCollectionType = PaymentCollectionType.MONTHLY;
  if (frequency === 'monthly') {
    if (interval_frequency === 1) {
      paymentType = PaymentCollectionType.MONTHLY;
    } else if (interval_frequency > 1) {
      paymentType = PaymentCollectionType.BY_TERM;
    }
  } else if (frequency === 'weekly') {
    paymentType = PaymentCollectionType.WEEKLY;
  } else if (frequency === 'once') {
    paymentType = PaymentCollectionType.ONCE;
  }
  return paymentType;
};

const dueDateTooltipText = {
  [PaymentCollectionType.BY_TERM]: {
    first: 'the first day of every term',
    last: 'the last day of every term',
    custom: 'of every term'
  },
  [PaymentCollectionType.MONTHLY]: {
    first: 'the first day of the month',
    last: 'the last day of the month',
    custom: 'of every month'
  }
};

export const formatDueDateTooltip = (
  type: PaymentCollectionType,
  date: Date
) => {
  const dayType = getDayType(date);
  if (type === PaymentCollectionType.WEEKLY)
    return `every ${capitalizeFirstLetter(
      dayOptions[new Date(date).getDay()].value
    )}`;
  if (type === PaymentCollectionType.ONCE) {
    return 'the due date';
  }
  if (dayType === 'custom') {
    return `the ${getCustomDate(date)} ${dueDateTooltipText[type][dayType]}`;
  }
  return '';
  // return dueDateTooltipText[type][dayType];
};

export const getMonthsTillCurrentMonth = () => {
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const monthsUntilCurrent = MonthArray.slice(0, currentMonthIndex + 1);
  return monthsUntilCurrent;
};

export const isInvalidMemberList = (
  memberList: MemberListType[],
  maxAmountLimit: string,
  minAmountLimit: string,
  nameMaxLength: string,
  nameMinLength: string,
  countryCodeMapping: Record<string, string>
) =>
  memberList.some(
    (member) =>
      (member.amount &&
        member.amount !== '0' &&
        isInvalidAmount(member.amount, maxAmountLimit, minAmountLimit)) ||
      isInvalidMemberName(member.name, nameMaxLength, nameMinLength) ||
      !member.primaryNumber ||
      (member.primaryNumber &&
        !isValidPhoneNumber(
          member.primaryNumber,
          countryCodeMapping[member.primaryNumberCountryCode || '']
        )) ||
      (member.altNumber &&
        member.altNumber !== 'null' &&
        !isValidPhoneNumber(
          member.altNumber,
          countryCodeMapping[member.altNumberCountryCode || '']
        ))
  );

export const invalidCSVErrorMessage =
  'Invalid data found. Update the uploaded file & try again.';

export const getFeeCategoryData = (
  feeCategoryData: {
    id: number;
    label: string;
    value: string;
  }[]
) =>
  feeCategoryData.map((item: any) => ({
    branch_fee_category_id: item.id,
    amount: item.value
  }));

export const findDueDateFromStartDate = (
  paymentCollectionType: string,
  dueDate: string,
  startDate: string
) => {
  if (paymentCollectionType === PaymentCollectionType.MONTHLY) {
    const { nextDueDate } = handleMonthDateSelect({
      item: new Date(dueDate).getDate().toString(),
      date: new Date(startDate || new Date())
    });
    return nextDueDate;
  }
  if (paymentCollectionType === PaymentCollectionType.WEEKLY) {
    const { nextDueDate } = handleWeekDateSelect(
      new Date(dueDate).getDay(),
      new Date(startDate || new Date())
    );
    return nextDueDate;
  }
  if (paymentCollectionType === PaymentCollectionType.BY_TERM) {
    const nextDueDate = handleTermDateSelect({
      item: new Date(dueDate).getDate().toString(),
      date: new Date(startDate || new Date())
    });
    return nextDueDate;
  }
  return '';
};

// const getMemberDueDate = (
//   dueDate: string,
//   paymentCollectionType: string,
//   startDate?: string,
//   isFileUpload?: boolean
// ) => {
//   if (dueDate) {
//     if (
//       isFileUpload &&
//       startDate &&
//       new Date(startDate).setHours(0, 0, 0, 0) <=
//         new Date(dueDate).setHours(0, 0, 0, 0)
//     ) {
//       return formatISODate({
//         date: dueDate,
//         separator: '-',
//         format: 'yyyy-mm-dd'
//       });
//     }

//     return formatISODate({
//       date:
//         startDate && isTodayOrFutureDate(startDate)
// eslint-disable-next-line max-len
//           ? findDueDateFromStartDate(paymentCollectionType, dueDate, startDate)
//           : dueDate,
//       separator: '-',
//       format: 'yyyy-mm-dd'
//     });
//   }

//   return '';
// };

export const getPaymentSchedule = (memberItem: MemberListType) => {
  const getIntervalValue = (item: MemberListType) => {
    if (item?.paymentCollectionType === PaymentCollectionType.WEEKLY)
      return PaymentCollectionType.WEEKLY;
    if (item?.paymentCollectionType === PaymentCollectionType.ONCE)
      return PaymentCollectionType.ONCE;
    return PaymentCollectionType.MONTHLY;
  };

  const paymentSchedule = {
    start_date: memberItem?.startDate
      ? formatISODate({
          date: memberItem?.startDate,
          separator: '-',
          format: 'yyyy-mm-dd'
        })
      : null,
    interval: getIntervalValue(memberItem), // monthly, weekly
    interval_frequency: Number(memberItem.interval),
    ...(getIntervalValue(memberItem) === PaymentCollectionType.MONTHLY && {
      monthly_config: {
        day_option:
          // eslint-disable-next-line no-nested-ternary
          memberItem.dueDate === '1'
            ? MonthDay.FIRST
            : memberItem.dueDate === MonthDay.FIRST ||
              memberItem.dueDate === MonthDay.LAST
            ? memberItem.dueDate
            : 'custom', // first_day, last_day, custom
        ...(memberItem.dueDate !== MonthDay.FIRST &&
          memberItem.dueDate !== MonthDay.LAST &&
          memberItem.dueDate !== '1' && {
            custom_day: Number(memberItem.dueDate)
          }) // 2 to 28
      }
    }),
    ...(getIntervalValue(memberItem) === PaymentCollectionType.WEEKLY && {
      weekly_config: {
        day_of_week: memberItem.dueDate // monday, tuesday, etc.
      }
    })
  };
  return paymentSchedule;
};

// input : date
// output: dayName eg: monday,tuesday...
export const getDayFromDate = (date: Date) => {
  const dayNumber = new Date(date).getDay();
  const day = dayOptions.find((item) => item.id === dayNumber)?.value;
  return day;
};

export const getMonthConfigFromDate = (date: string) => {
  const dateNumber = new Date(date).getDate().toString();
  let dayOption = 'custom';
  if (dateNumber === '1') {
    dayOption = 'first_day';
  } else if (['29', '30', '31'].includes(dateNumber)) {
    dayOption = 'last_day';
  }
  return {
    day_option: dayOption,
    ...(dayOption === 'custom' && { custom_day: Number(dateNumber) })
  };
};

export const getFileImportPaymentSchedule = (memberItem: MemberListType) => {
  const getIntervalValue = (frequency: string) => {
    const freqValue = PaymentCollectionOptions.find(
      (option) => option.label === frequency
    )?.value;

    if (freqValue === PaymentCollectionType.WEEKLY)
      return PaymentCollectionType.WEEKLY;
    return PaymentCollectionType.MONTHLY;
  };

  const paymentSchedule = memberItem.startDate
    ? {
        start_date: memberItem?.startDate
          ? formatISODate({
              date: memberItem?.startDate,
              separator: '-',
              format: 'yyyy-mm-dd'
            })
          : null,
        interval: memberItem.frequency
          ? getIntervalValue(memberItem.frequency)
          : '', // monthly, weekly
        interval_frequency: Number(memberItem.monthsPerTerm) || 1,
        ...(memberItem.frequency &&
          getIntervalValue(memberItem.frequency) ===
            PaymentCollectionType.MONTHLY && {
            monthly_config: getMonthConfigFromDate(memberItem.startDate)
          }),
        ...(memberItem.frequency &&
          getIntervalValue(memberItem.frequency) ===
            PaymentCollectionType.WEEKLY && {
            weekly_config: {
              day_of_week: getDayFromDate(new Date(memberItem.startDate)) // monday, tuesday, etc.
            }
          })
      }
    : null;
  return paymentSchedule;
};

export const getParsedMemberData = ({
  memberList,
  groupAmount,
  groupPaymentCollectionType,
  isFileUpload,
  isFeeCategoryEnabled,
  isAmountPaidTillDateEnabled
}: // startDate
{
  memberList: MemberListType[];
  groupAmount: string;
  groupPaymentCollectionType: string;
  isFileUpload?: boolean;
  isFeeCategoryEnabled?: boolean;
  isAmountPaidTillDateEnabled?: boolean;
  startDate?: string;
}) => {
  const isAmountPaidEmptyOrZero = memberList.every(
    (item) => item.amountPaid === '' || Number(item.amountPaid) === 0
  );

  const getAmountPaidTillDate = (member: MemberListType) => {
    if (!isFileUpload || isAmountPaidEmptyOrZero) {
      return null;
    }
    return member.amountPaid ? Number(member.amountPaid) : 0;
  };

  const hasValidAmount = (data: FeeCategoryDataType[]) =>
    data.every((item) => item.value !== undefined);

  return memberList.map((member) => ({
    name: member.name.trim(),
    mobile: member.primaryNumber.toString().replace(/\s+/g, ''),
    country_code: member.primaryNumberCountryCode,
    amount: Number(member.amount) || Number(groupAmount),
    ...(groupPaymentCollectionType !== PaymentCollectionType.ONCE && {
      payment_schedule: isFileUpload
        ? getFileImportPaymentSchedule(member)
        : getPaymentSchedule(member)
    }),
    alternate_mobile: member.altNumber?.toString().replace(/\s+/g, ''),
    secondary_country_code: member.altNumberCountryCode,
    email: member.email,
    ...(isFeeCategoryEnabled &&
      member.feeCategoryData &&
      hasValidAmount(member.feeCategoryData) && {
        member_fee_configuration: getFeeCategoryData(
          member.feeCategoryData || []
        )
      }),
    ...(isAmountPaidTillDateEnabled && {
      amount_paid_till_date: getAmountPaidTillDate(member)
    }),
    admission_number: member?.admissionNumber || null,
    admission_date: member?.admissionDate || null,
    date_of_birth: member?.dateOfBirth || null,
    guardian_name: member?.guardianName || null,
    age: member?.age || null,
    address_line_1: member?.addressLine1 || null,
    address_line_2: member?.addressLine2 || null,
    city: member?.city || null,
    state: member?.state || null,
    country: member?.country || null,
    pincode: member?.pincode?.toString() || null,
    custom_fields: [
      ...('bloodGroup' in member
        ? [{ key: 'blood_group', value: member.bloodGroup }]
        : []),
      ...('isInsured' in member
        ? [
            {
              key: 'is_insured',
              value: member.isInsured === 'Yes' ? 'true' : 'false'
            }
          ]
        : [])
    ]
  }));
};

export const getCreateGroupPayload = (
  formData: FormDataType,
  imageFilePath: string,
  memberList: MemberListType[],
  feeCategories: {
    id: number;
    label: string;
    value: string;
  }[],
  isFeeCategoryEnabled: boolean,
  isFileUpload: boolean,
  isAmountPaidTillDateEnabled: boolean
) => {
  const getIntervalValue = () => {
    if (formData.paymentCollectionType === PaymentCollectionType.WEEKLY)
      return PaymentCollectionType.WEEKLY;
    if (formData.paymentCollectionType === PaymentCollectionType.ONCE)
      return PaymentCollectionType.ONCE;
    return PaymentCollectionType.MONTHLY;
  };
  const isAmountPaidEmptyOrZero = memberList.every(
    (item) => item.amountPaid === '' || Number(item.amountPaid) === 0
  );

  const getSplitPaymentValue = () => {
    if (isAmountPaidTillDateEnabled && isFileUpload) {
      if (isAmountPaidEmptyOrZero) return formData?.paymentInstallment;
      return !!isAmountPaidTillDateEnabled;
    }
    return formData?.paymentInstallment;
  };

  const paymentSchedule = {
    start_date: formData?.startDate
      ? formatISODate({
          date: formData.startDate,
          separator: '-',
          format: 'yyyy-mm-dd'
        })
      : null,
    end_date: formData?.endDate
      ? formatISODate({
          date: formData.endDate,
          separator: '-',
          format: 'yyyy-mm-dd'
        })
      : null,
    interval_execution_type:
      formData.paymentCollectionType === 'once' && formData.isImmediatePayment
        ? 'immediate'
        : 'due_date',
    interval: getIntervalValue(), // monthly, weekly
    interval_frequency:
      formData.paymentCollectionType === PaymentCollectionType.BY_TERM
        ? Number(formData.intervalFrequency)
        : 1,
    ...(getIntervalValue() === PaymentCollectionType.MONTHLY && {
      monthly_config: {
        day_option:
          // eslint-disable-next-line no-nested-ternary
          formData.activationDate === '1'
            ? MonthDay.FIRST
            : formData.activationDate === MonthDay.FIRST ||
              formData.activationDate === MonthDay.LAST
            ? formData.activationDate
            : 'custom', // first_day, last_day, custom
        ...(formData.activationDate !== MonthDay.FIRST &&
          formData.activationDate !== MonthDay.LAST &&
          formData.activationDate !== '1' && {
            custom_day: Number(formData.activationDate)
          }) // 2 to 28
      }
    }),
    ...(getIntervalValue() === PaymentCollectionType.WEEKLY && {
      weekly_config: {
        day_of_week: formData.activationDate // monday, tuesday, etc.
      }
    }),
    ...(formData.paymentCollectionType === 'once' &&
      !formData.isImmediatePayment &&
      formData.activationDate && {
        custom_date: formData.activationDate
      })
  };

  const payload = {
    params: {
      organizationId: getLocalStorageItem('organizationId'),
      branchId: getLocalStorageItem('branchId')
    },
    body: {
      name: formData.groupName.trim(),
      image_url: imageFilePath,
      amount: Number(formData.amount),
      payment_schedule: paymentSchedule,
      members: getParsedMemberData({
        memberList,
        groupAmount: formData.amount,
        groupPaymentCollectionType: formData.paymentCollectionType,
        isFileUpload,
        isFeeCategoryEnabled,
        isAmountPaidTillDateEnabled,
        ...(formData.startDate && { startDate: formData.startDate })
      }),
      is_split_payment: getSplitPaymentValue(),
      attendance_config: {
        is_attendance_tracking_enabled: formData?.isAttandanceTrackingEnabled,
        is_send_attendance_alert_enabled:
          formData?.isSendAttendanceAlertEnabled,
        working_days: formData?.workingDays
      },
      is_fee_configured: isFeeCategoryEnabled,
      ...(isFeeCategoryEnabled && {
        group_fee_configuration: getFeeCategoryData(feeCategories)
      })
    }
  };

  return payload;
};

export const formatDate = (date: string) =>
  formatISODate({ date, separator: '-', format: 'yyyy-mm-dd' });

export const checkHasChangesInEditGroup = (
  groupInfo: EditGroupType,
  groupDetails: GroupDetailsType
) =>
  groupInfo.name !== groupDetails.name ||
  groupInfo.amount !== groupDetails.amount ||
  (groupInfo.groupPhoto.image_url || groupInfo.groupPhoto.preview || '') !==
    (groupDetails.image_url || '') ||
  groupInfo.next_due_at !== formatDate(groupDetails.next_due_at) ||
  groupInfo.frequency !== groupDetails.frequency ||
  groupInfo.interval_frequency !== groupDetails.interval_frequency ||
  groupInfo.paymentInstallment !== groupDetails.is_split_payment ||
  groupInfo.end_date !== groupDetails.end_date ||
  groupInfo.isAttandanceTrackingEnabled !==
    groupDetails.is_attendance_tracking_enabled ||
  groupInfo.isSendAttendanceAlertEnabled !==
    groupDetails.is_send_attendance_alert_enabled ||
  !checkArraysAreSame(
    groupInfo?.workingDays || [],
    groupDetails?.work_days || []
  );

export const checkHasChangesInEditMember = (
  memberDetails: MemberListType,
  member: Member,
  feeCategories: FeeConfigurationType[]
) => {
  const exisitngFeeConfig = member.fee_amount_configuration;

  const isFeeCategoryAmountDifferent =
    feeCategories &&
    feeCategories.some((feeCategory) => {
      const memberFee = exisitngFeeConfig?.find(
        (fee) => fee.category === feeCategory.category
      );
      return memberFee && memberFee.amount !== feeCategory.amount;
    });

  return (
    memberDetails.name !== member.name ||
    memberDetails.amount !== member.amount ||
    memberDetails.altNumber !== member.secondary_mobile ||
    memberDetails.dueDate !== formatDate(member.next_due_at) ||
    memberDetails.email !== member.email ||
    (feeCategories.length > 0 && isFeeCategoryAmountDifferent) ||
    memberDetails.paymentSchedule?.startDate !==
      member.payment_schedule?.start_date ||
    memberDetails.paymentSchedule?.intervalFrequency !==
      member.payment_schedule?.interval_frequency ||
    memberDetails.paymentSchedule?.interval !==
      member.payment_schedule?.interval ||
    memberDetails.paymentSchedule?.monthlyConfig?.dayOption !==
      member.payment_schedule?.monthly_config?.day_option ||
    memberDetails.paymentSchedule?.monthlyConfig?.customDay !==
      member.payment_schedule?.monthly_config?.custom_day ||
    memberDetails.paymentSchedule?.weeklyConfig?.dayOfWeek !==
      member.payment_schedule?.weekly_config?.day_of_week
  );
};

export const getGroupInfo = (groupDetails: GroupDetailsType) => ({
  groupPhoto: {
    file: '',
    preview: '',
    image_url: groupDetails.image_url || ''
  },
  name: groupDetails.name,
  amount: groupDetails.amount,
  next_due_at: formatDate(groupDetails.next_due_at),
  frequency: groupDetails.frequency,
  interval_frequency: groupDetails.interval_frequency,
  paymentInstallment: groupDetails.is_split_payment,
  end_date: groupDetails.end_date,
  isAttandanceTrackingEnabled: !!groupDetails.is_attendance_tracking_enabled,
  isSendAttendanceAlertEnabled: !!groupDetails.is_send_attendance_alert_enabled,
  workingDays: groupDetails.work_days || []
});

export const getFormattedMemberData = (member: Member) => ({
  id:
    member.id ??
    member.name + (member.phone || member.primary_mobile_number || ''),
  name: member.name || '',
  primaryNumber: member.phone || member.primary_mobile_number || '',
  altNumber: member.secondary_mobile_number || '',
  amount: member.amount || '',
  dueDate: member.next_due_at ? formatDate(member.next_due_at) : '',
  is_payment_link_sent: member.is_payment_link_sent || false,
  primaryNumberCountryCode: member.primary_country_code || defaultCountryCode,
  altNumberCountryCode: member.secondary_country_code || defaultCountryCode,
  email: member.email || '',
  paymentSchedule: {
    startDate: member.payment_schedule?.start_date,
    intervalFrequency: member.payment_schedule?.interval_frequency,
    interval: member.payment_schedule?.interval,
    ...(member.payment_schedule?.monthly_config && {
      monthlyConfig: {
        dayOption: member.payment_schedule?.monthly_config?.day_option,
        customDay: member.payment_schedule?.monthly_config?.custom_day
      }
    }),
    ...(member.payment_schedule?.weekly_config && {
      weeklyConfig: {
        dayOfWeek: member.payment_schedule?.weekly_config?.day_of_week
      }
    })
  },
  is_collection_started: member.is_collection_started
});

export const getAnalyticData = (
  groupData: any,
  formData: FormDataType,
  memberList: MemberListType[],
  memberMode: AddMemberModeType,
  feeCategories: {
    id: number;
    label: string;
    value: string;
  }[],
  isFeeCategoryEnabled: boolean
) => ({
  group_name: groupData.name,
  type: formData.paymentCollectionType,
  interval: groupData.interval_frequency,
  payment_collection_date: groupData.activation_date,
  ...(formData.paymentCollectionType === PaymentCollectionType.WEEKLY && {
    payment_collection_day: getDayFromDate(new Date(groupData.activation_date))
  }),
  amount: groupData.amount,
  is_split_payment: groupData.is_split_payment,
  // ...(getParsedMemberData({
  //   memberList,
  //   paymentCollectionType: formData.paymentCollectionType
  // }).length > 0 && {
  //   add_member_mode: memberMode
  // }),
  ...(groupData?.end_date && {
    end_date: groupData.end_date
  }),
  // members: getParsedMemberData({
  //   memberList,
  //   paymentCollectionType: formData.paymentCollectionType
  // }),
  ...(isFeeCategoryEnabled && {
    feeCategories: getFeeCategoryData(feeCategories)
  })
});

export const formatMonthForAttendance = (date: string) =>
  formatISODate({ date, separator: ' ', format: 'MMMM YYYY' });

export const formatDateForAttendance = (date: string) =>
  formatISODate({ date, separator: ' ', format: 'DD MMMM YYYY' });

export const formatDateRange = (dateRange: DateRange) => {
  // adjust the time zone offset between local time zone and UTC
  const startOfMonthWithOffset = new Date(
    dateRange.startDate.getTime() -
      dateRange.startDate.getTimezoneOffset() * 60000
  ).toISOString();
  const endOfMonthWithOffset = new Date(
    dateRange.endDate.getTime() - dateRange.endDate.getTimezoneOffset() * 60000
  ).toISOString();
  return { startDate: startOfMonthWithOffset, endDate: endOfMonthWithOffset };
};

export const isAddNewGroupSaveDisabled = (
  formData: FormDataType,
  groupMemberMaxAmount: any,
  groupMemberMinAmount: any,
  feeCategories: { id: number; label: string; value: string }[],
  isFeeCategoryEnabled: boolean
) => {
  const areAllFeeCategoriesFilled =
    feeCategories.length > 0 &&
    feeCategories.every((category: any) => category.value.trim() !== '');

  return (
    isInvalidGroupName(formData.groupName) ||
    (!formData.activationDate && !formData.isImmediatePayment) ||
    isInvalidAmount(
      formData.amount,
      groupMemberMaxAmount,
      groupMemberMinAmount
    ) ||
    (formData?.isAttandanceTrackingEnabled &&
      formData?.workingDays?.length === 0) ||
    (isFeeCategoryEnabled && !areAllFeeCategoriesFilled) ||
    (formData?.startDate &&
      formData?.endDate &&
      new Date(formData.startDate).setHours(0, 0, 0, 0) >
        new Date(formData.endDate).setHours(0, 0, 0, 0))
  );
};

const isValidDate = (year: number, month: number, day: number) => {
  const date = new Date(year, month, day);
  return (
    date.getFullYear() === year &&
    date.getMonth() === month &&
    date.getDate() === day
  );
};

export const parseDateString = (dateString: string) => {
  // Check if date is in the format dd-mm-yyyy
  if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
    const [day, month, year] = dateString.split('-').map(Number);
    if (isValidDate(year, month - 1, day)) {
      return {
        date: new Date(Number(year), Number(month) - 1, Number(day)),
        error: ''
      };
    }
    return { date: null, error: 'Invalid Date' };
  }
  // Check if date is in the format yyyy-mm-dd
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    const [year, month, day] = dateString.split('-').map(Number);
    if (isValidDate(year, month - 1, day)) {
      return {
        date: new Date(Number(year), Number(month) - 1, Number(day)),
        error: ''
      };
    }
    return { date: null, error: 'Invalid Date' };
  }

  return {
    date: null,
    error: 'Date should be in the format dd-mm-yyyy or yyyy-mm-dd'
  };
};

export const getValidationErrorsOnMemberDetails = (
  dataHeaders: string[],
  memberDetails: AddMemberInput[],
  maxAmount: number,
  minAmount: number,
  feeCategories: string[],
  isEmailEnabled: boolean,
  isAmountPaidTillDateEnabled: boolean,
  isOnce: boolean,
  customFields: UserProfileCustomField[],
  countryCodeList: string[],
  countryCodeMapping: Record<string, string>
): string[] => {
  // Validate Headers

  const templateHeaders = [
    ...defaultTemplateHeaders(
      feeCategories,
      isEmailEnabled,
      isAmountPaidTillDateEnabled,
      isOnce,
      customFields
    )
  ];

  const templateHeadersWithUserProfile = [
    ...defaultTemplateHeaders(
      feeCategories,
      isEmailEnabled,
      isAmountPaidTillDateEnabled,
      isOnce,
      customFields,
      true
    )
  ];

  const countryList = countries.map((country) => country).concat('Others');

  const stateList = Object.keys(cities).map((state) => state);

  if (
    (checkArraysAreSame(
      dataHeaders.filter((header) => header !== '' && !header.startsWith('_')),
      templateHeaders.filter(
        (header) => header !== '' && !header.startsWith('_')
      )
    ) ||
      checkArraysAreSame(
        dataHeaders.filter(
          (header) => header !== '' && !header.startsWith('_')
        ),
        templateHeadersWithUserProfile.filter(
          (header) => header !== '' && !header.startsWith('_')
        )
      )) === false
  ) {
    return [
      `Column names don't match. Please ensure they follow the latest template.`
    ];
  }

  const errors: string[] = [];
  const primaryNumbers: string[] = [];
  const altNumbers: string[] = [];
  const admissionNumbers: string[] = [];

  memberDetails.forEach((member: AddMemberInput, index: number) => {
    const {
      name,
      primaryNumber,
      altNumber,
      amount,
      frequency,
      monthsPerTerm,
      startDate,
      primaryNumberCode,
      altNumberCode,
      email,
      amountPaid,
      dateOfBirth,
      admissionDate,
      admissionNumber,
      guardianName,
      country,
      state,
      city,
      pincode,
      bloodGroup,
      isInsured
    } = member;

    // Validate name
    if (!name || name.length < 3) errors.push(`Row ${index + 1}: Invalid name`);

    // Validate primary number code
    if (
      !primaryNumberCode ||
      (primaryNumberCode && !countryCodeList.includes(`${primaryNumberCode}`))
    ) {
      errors.push(`Row ${index + 1}: Invalid Primary Number Country Code`);
    }

    const primaryNumberWithCode = `+${primaryNumberCode}${primaryNumber}`;

    //  validate primary number
    if (
      !primaryNumber ||
      (primaryNumber &&
        primaryNumberCode &&
        !isValidPhoneNumber(
          primaryNumber,
          countryCodeMapping[primaryNumberCode]
        ))
    )
      errors.push(`Row ${index + 1}: Invalid Primary number format`);

    // check if primary number and name are duplicate
    if (primaryNumbers.includes(`${name}_${primaryNumberWithCode}`))
      errors.push(`Row ${index + 1}: Duplicate Name and Primary Number`);

    // Validate alt number code
    if (altNumberCode && !countryCodeList.includes(`${altNumberCode}`)) {
      errors.push(`Row ${index + 1}: Invalid Alternative Number Country Code`);
    }

    const altNumberWithCode = `+${altNumberCode}${altNumber}`;
    // validate alternate number
    if (
      altNumber &&
      (!altNumberCode ||
        !isValidPhoneNumber(altNumber, countryCodeMapping[altNumberCode]))
    )
      errors.push(`Row ${index + 1}: Invalid Alternative number format`);

    // check if alternate number is duplicate
    if (altNumbers.includes(`${name}_${altNumberWithCode}`))
      errors.push(`Row ${index + 1}: Duplicate Name Alternative Number`);

    // check primary and secondary numbers are same
    if (altNumber && primaryNumberWithCode === altNumberWithCode)
      errors.push(
        `Row ${index + 1}: Primary and Alternative Number should not be same`
      );

    // Validate email
    if (email && isInvalidEmail(email))
      errors.push(`Row ${index + 1}: Invalid Email`);

    if (feeCategories.length > 0) {
      // Check feeCatgories amount
      let totalAmount = 0;
      let hasEmptyCategoryValue = false;
      let hasNegativeValue = false;
      feeCategories.forEach((category) => {
        const categoryValue = member[category as keyof AddMemberInput];
        const isCategoryValueEmpty =
          categoryValue === undefined || categoryValue === null;
        if (!hasEmptyCategoryValue && isCategoryValueEmpty)
          hasEmptyCategoryValue = true;
        if (
          typeof categoryValue === 'number' &&
          categoryValue < 0 &&
          !hasNegativeValue
        )
          hasNegativeValue = true;
        if (categoryValue && Number(categoryValue).toLocaleString() === 'NaN') {
          errors.push(`Row ${index + 1}: Invalid amount for ${category}`);
        } else {
          totalAmount += Number(categoryValue);
        }
      });
      if (
        (!hasEmptyCategoryValue && totalAmount < minAmount) ||
        totalAmount > maxAmount ||
        hasNegativeValue
      )
        errors.push(
          `Row ${
            index + 1
          }: Amount should be between ${minAmount} and ${maxAmount}`
        );
    } else {
      if (amount && Number(amount).toLocaleString() === 'NaN')
        errors.push(`Row ${index + 1}: Invalid amount`);

      if (amount && (Number(amount) < minAmount || Number(amount) > maxAmount))
        errors.push(
          `Row ${
            index + 1
          }: Amount should be between ${minAmount} and ${maxAmount}`
        );
    }

    if (amountPaid && Number(amountPaid).toLocaleString() === 'NaN')
      errors.push(`Row ${index + 1}: Invalid amount paid`);
    else if (
      amountPaid &&
      (Number(amountPaid) < minAmount || Number(amountPaid) > maxAmount)
    )
      errors.push(
        `Row ${
          index + 1
        }: Amount paid should be between ${minAmount} and ${maxAmount}`
      );
    else if (
      amountPaid &&
      amount &&
      Number(amount).toLocaleString() !== 'NaN' &&
      Number(amountPaid) > Number(amount)
    )
      errors.push(
        `Row ${index + 1}: Amount paid should be less than or equal to Amount`
      );

    if (frequency) {
      const filteredOptions = PaymentCollectionOptions.filter(
        (option: { label: string }) => option.label !== 'Once'
      );
      const options = filteredOptions.map(
        (option: { label: string }) => option.label
      );
      if (!options.includes(frequency))
        errors.push(
          `Row ${
            index + 1
          }: Frequency should be one of the provided list values`
        );
    }

    if (monthsPerTerm) {
      if (frequency !== 'By term') {
        errors.push(
          `Row ${
            index + 1
          }: Months per term should be left empty for frequencies other than ‘By term‘`
        );
      } else if (Number(monthsPerTerm) < 1 || Number(monthsPerTerm) > 12)
        errors.push(
          `Row ${
            index + 1
          }: Months per term should be within the range of 1 to 12`
        );
    } else if (frequency === 'By term')
      errors.push(
        `Row ${
          index + 1
        }: Months per term cannot be empty for frequency ‘By term’`
      );

    if (startDate) {
      // Validate start date
      const { date, error } = parseDateString(startDate);
      if (error) errors.push(`Row ${index + 1}: ${error}`);
      if (date && date.getTime() < new Date().setHours(0, 0, 0, 0))
        errors.push(
          `Row ${
            index + 1
          }: Start date should be greater than or equal to today`
        );
    } else if (frequency)
      errors.push(
        `Row ${index + 1}: Start date is required when frequency is specified`
      );

    // Validate guardian name
    if (guardianName && guardianName.length < 3)
      errors.push(`Row ${index + 1}: Invalid Guardian Name`);

    if (country) {
      if (!countryList.includes(country))
        errors.push(
          `Row ${index + 1}: Country should be one of the provided list values`
        );
      if (country === 'Others' && (state || city || pincode))
        errors.push(
          `Row ${
            index + 1
          }: For Non-Indian countries, State, City, and Pincode fields should remain empty`
        );
    }

    if (state) {
      if (!stateList.includes(state))
        errors.push(
          `Row ${index + 1}: State should be one of the provided list values`
        );
      if (!country)
        errors.push(
          `Row ${index + 1}: Please select a Country before entering State`
        );
    }

    if (city && !state)
      errors.push(
        `Row ${index + 1}: Please select a State before entering City`
      );

    if (
      pincode &&
      !(/^\d+$/.test(pincode.toString()) && pincode.toString().length === 6)
    ) {
      errors.push(`Row ${index + 1}: Invalid Pincode`);
    }

    if (bloodGroup && !isValidBloodGroup(bloodGroup))
      errors.push(`Row ${index + 1}: Invalid blood group format`);

    if (isInsured && !isValidInsureValue(isInsured))
      errors.push(`Row ${index + 1}: Invalid is insured value`);

    if (dateOfBirth) {
      const { date, error } = parseDateString(dateOfBirth);
      if (error) errors.push(`Row ${index + 1}: ${error}`);
      if (date && date.getTime() > new Date().setHours(0, 0, 0, 0))
        errors.push(
          `Row ${index + 1}: Date of birth should not be a future date`
        );
    }

    if (admissionDate) {
      const { error } = parseDateString(admissionDate);
      if (error) errors.push(`Row ${index + 1}: ${error}`);
    }

    if (admissionNumber && admissionNumbers.includes(admissionNumber)) {
      errors.push(`Row ${index + 1}: Duplicate Admission Number`);
    }

    // Add Primary Number and AltNumber to array
    if (primaryNumber) primaryNumbers.push(`${name}_${primaryNumberWithCode}`);
    if (altNumber) altNumbers.push(`${name}_${altNumberWithCode}`);
    if (admissionNumber) admissionNumbers.push(admissionNumber);
  });

  return errors;
};

export const formatGroupData = (data: any) => {
  const formattedNextDueAt = formatISODate({
    date: data.next_due_at,
    separator: '-',
    format: 'yyyy-mm-dd'
  });

  const getActivationDate = () => {
    const paymentSchedule = data.payment_schedule || {};
    if (paymentSchedule.interval === 'once') {
      return formattedNextDueAt;
    }
    if (paymentSchedule.interval === PaymentCollectionType.MONTHLY) {
      if (
        paymentSchedule.monthly_config?.day_option === MonthDay.FIRST ||
        paymentSchedule.monthly_config?.day_option === MonthDay.LAST
      )
        return paymentSchedule.monthly_config?.day_option;
      return paymentSchedule.monthly_config?.custom_day;
    }
    if (paymentSchedule.interval === PaymentCollectionType.WEEKLY) {
      return paymentSchedule.weekly_config?.day_of_week;
    }
    return '';
  };

  const formattedEndDate = formatISODate({
    date: data.end_date,
    separator: '-',
    format: 'yyyy-mm-dd'
  });
  const groupFormData = {
    groupPhoto: {
      imageUrl: data.image_url || ''
    },
    groupName: data.name || '',
    nextDueAt: formattedNextDueAt || '',
    intervalFrequency: data.interval_frequency || 0,
    amount: data.amount || 0,
    paymentInstallment: !!data.is_split_payment,
    paymentCollectionType: data.frequency || PaymentCollectionType.MONTHLY,
    activationDate: getActivationDate() || '',
    endDate: formattedEndDate || '',
    isAttandanceTrackingEnabled: !!data.is_attendance_tracking_enabled,
    workingDays: data.work_days || [],
    isPaymentLinkSent: data.is_payment_link_sent,
    startDate: data.start_date || '',
    collectionStartedAt: data.collection_started_at,
    isSendAttendanceAlertEnabled: !!data.is_send_attendance_alert_enabled,
    isCollectionStarted: data.is_collection_started,
    isImmediatePayment: data.interval_execution_type === 'immediate'
  };
  return groupFormData;
};

export const formatFeeConfiguration = (
  groupFeeAmountConfiguration: Array<object>
) => {
  const feeConfiguration = groupFeeAmountConfiguration.map((item: any) => ({
    id: item.branch_fee_category_id,
    value: item.amount,
    label: item.category
  }));
  return feeConfiguration;
};
