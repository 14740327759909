import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@core/store';
import { CreateEventResponse } from '@types';

interface EventState {
  eventBranchFilter: {
    branchId: string;
    branchName: string;
  };
  event: CreateEventResponse;
}

const initialState: EventState = {
  eventBranchFilter: {
    branchId: '',
    branchName: ''
  },
  event: {
    event_id: '',
    name: '',
    form_link: '',
    description: '',
    image_url: '',
    venue_name: '',
    venue_address_line_1: '',
    venue_address_line_2: '',
    venue_city: '',
    venue_pincode: '',
    location_link: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    contact_alternate_phone: '',
    start_date_time: '',
    end_date_time: '',
    additional_doc_url: '',
    min_ticket_price: ''
  }
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    updateEventBranchFilter: (
      state,
      action: PayloadAction<{ branchId: string; branchName: string }>
    ) => {
      state.eventBranchFilter = action.payload;
    },
    saveEventResponse: (
      state,
      action: PayloadAction<{ event: CreateEventResponse }>
    ) => {
      state.event = action.payload.event;
    },
    clearEvent: (state) => {
      state.event = initialState.event;
    }
  }
});

export const { updateEventBranchFilter, clearEvent, saveEventResponse } =
  eventsSlice.actions;

export const eventsState = (state: RootState) => state.events;

export default eventsSlice.reducer;
