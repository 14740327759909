import { defaultCountryCode } from '@components/country-code/constants';
import { appendTimeToISODate, formatISODate, isSameDay } from '@utils/date';
import {
  AttendeeDetailsResponse,
  CreateEventRequest,
  EventDetailsResponse,
  EventFormDetails,
  FormField
} from 'types/event';

import { defaultQuestionNames } from './constants';
import {
  AttendeeDetails,
  EventData,
  EventDetails,
  Question,
  QuestionMetaData,
  QuestionType
} from './types';

export function getInitialQuestionData(
  questionType: QuestionType,
  nextDisplayOrder: number
): Question {
  const getMetaData = () => {
    switch (questionType) {
      case QuestionType.NUMBER:
      case QuestionType.DATE:
      case QuestionType.TEXT:
      case QuestionType.TEXT_AREA:
      case QuestionType.MOBILE_NUMBER:
        return null;
      case QuestionType.MULTIPLE_CHOICE:
      case QuestionType.SINGLE_CHOICE:
      case QuestionType.DROP_DOWN:
        return ['', ''];
      case QuestionType.FILE_UPLOAD:
        return {
          fileTypes: [],
          size: 1
        };
      default:
        return null;
    }
  };
  return {
    id: new Date().getTime().toString(),
    question: '',
    required: false,
    type: questionType,
    questionMetaData: getMetaData(),
    displayOrder: nextDisplayOrder
  };
}

const hasDuplicates = (arr: string[]): boolean =>
  arr.some((str, index) => arr.indexOf(str) !== index);

// TODO: Modify below function proper validation
export const isQuestionValid = (
  allQuestionNames: string[],
  question: Question
) => {
  let metadata;

  if (question.question.length === 0) {
    return false;
  }

  if (allQuestionNames.includes(question.question.toLowerCase())) return false;

  switch (question.type) {
    case QuestionType.NUMBER:
    case QuestionType.DATE:
    case QuestionType.TEXT:
    case QuestionType.TEXT_AREA:
    case QuestionType.MOBILE_NUMBER:
      return true;
    case QuestionType.MULTIPLE_CHOICE:
    case QuestionType.SINGLE_CHOICE:
    case QuestionType.DROP_DOWN:
      metadata =
        question.questionMetaData as QuestionMetaData[QuestionType.MULTIPLE_CHOICE];
      return (
        metadata.length > 1 &&
        metadata.every((option) => option.length > 0) &&
        !hasDuplicates(metadata)
      );
    case QuestionType.FILE_UPLOAD:
      metadata =
        question.questionMetaData as QuestionMetaData[QuestionType.FILE_UPLOAD];
      return metadata.fileTypes.length > 0 && metadata.size > 0;
    default:
      return false;
  }
};

export const convertToCreateEventRequest = ({
  eventData,
  isEdit
}: {
  eventData: EventData;
  isEdit: boolean;
}): CreateEventRequest => {
  const {
    basicDetails,
    otherDetails,
    questions,
    tickets,
    registrationCloseDate,
    registrationCloseTime
  } = eventData;
  const startDate = appendTimeToISODate(
    basicDetails.startDate,
    basicDetails.startTime
  );

  const endDate = appendTimeToISODate(
    basicDetails.endDate,
    basicDetails.endTime
  );

  const registrationCloseDateTime = appendTimeToISODate(
    registrationCloseDate,
    registrationCloseTime
  );

  return {
    name: basicDetails.title,
    description: basicDetails.description,
    image_url: basicDetails.imageUrl,
    venue_name: otherDetails.venueName,
    venue_address_line_1: otherDetails.addressLine1,
    venue_address_line_2: otherDetails.addressLine2,
    venue_city: otherDetails.city,
    venue_state: otherDetails.state,
    venue_pincode: otherDetails.pincode,
    location_link: otherDetails.locationLink,
    contact_name: otherDetails.name,
    contact_email: otherDetails.email,
    contact_phone_country_code: defaultCountryCode,
    contact_phone: otherDetails.primaryNumber,
    contact_alternate_phone: otherDetails.alternateNumber,
    contact_phone_alternate_country_code: otherDetails.alternateNumber
      ? defaultCountryCode
      : '',
    start_date_time: startDate,
    end_date_time: endDate,
    additional_doc_url: otherDetails.additionalDocUrl,
    registration_end_date_time: registrationCloseDateTime,
    event_tickets: tickets.map((ticket) => ({
      id: ticket.id ? Number(ticket.id) : undefined,
      name: ticket.name,
      is_active: ticket.isActive ?? true,
      price: ticket.price,
      description: ticket.description
    })),
    form_fields: questions.map((question) => ({
      id: question.id && isEdit ? Number(question.id) : undefined,
      name: question.question,
      type: question.type,
      is_required: question.required,
      options: (question.questionMetaData ?? []) as string[],
      to_be_deleted: question.isDeleted,
      display_order: question.displayOrder
    }))
  };
};

const orderFormFields = (formFields: FormField[]) =>
  [...formFields].sort((a, b) => a.display_order - b.display_order);

export const convertEventFormDetailsToEventData = (
  eventDetails: EventFormDetails
): EventData => ({
  basicDetails: {
    title: eventDetails.name,
    imageUrl: eventDetails.image_url,
    description: eventDetails.description,
    startDate: eventDetails.start_date_time,
    startTime: formatISODate({
      date: eventDetails.start_date_time,
      format: 'hh:mm am/pm'
    }).toUpperCase(),
    endDate: eventDetails.end_date_time,
    endTime: formatISODate({
      date: eventDetails.end_date_time,
      format: 'hh:mm am/pm'
    }).toUpperCase()
  },
  otherDetails: {
    venueName: eventDetails.venue_name,
    addressLine1: eventDetails.venue_address_line1,
    addressLine2: eventDetails.venue_address_line2,
    state: eventDetails.venue_state,
    additionalDocUrl: eventDetails.additional_doc_url,
    city: eventDetails.venue_city,
    pincode: eventDetails.venue_pincode,
    locationLink: eventDetails.location_link,
    name: eventDetails.contact_name,
    email: eventDetails.contact_email,
    primaryNumber: eventDetails.contact_phone,
    alternateNumber: eventDetails.contact_alternate_phone
  },
  questions: orderFormFields(eventDetails.form_fields).map((field) => ({
    id: field.form_id.toString(),
    question: field.field_name,
    type: field.field_type as QuestionType,
    required: field.is_required,
    isDefault: defaultQuestionNames.includes(field.field_name),
    questionMetaData: field.options as QuestionMetaData[QuestionType],
    displayOrder: field.display_order
  })),
  registrationCloseDate: eventDetails.registration_end_date_time,
  registrationCloseTime: formatISODate({
    date: eventDetails.registration_end_date_time,
    format: 'hh:mm am/pm'
  }).toUpperCase(),
  tickets: eventDetails.event_tickets?.map((ticket) => ({
    id: ticket.ticket_id.toString(),
    name: ticket.name,
    isActive: ticket.is_active,
    price: ticket.price,
    description: ticket.description
  }))
});

export const convertFromEventDetailsResponse = (
  response: EventDetailsResponse
): EventDetails => ({
  eventId: response.event_id,
  name: response.name,
  imageUrl: response.image_url,
  venueName: response.venue_name,
  venueCity: response.venue_city,
  registrationEndDateTime: response.registration_end_date_time,
  startDateTime: response.start_date_time,
  endDateTime: response.end_date_time,
  formLink: response.form_link,
  totalRegistrations: response.total_registrations,
  totalTicketSold: response.total_ticket_sold,
  totalRevenue: response.total_revenue,
  tickets: response.tickets.map((ticket) => ({
    name: ticket.name,
    price: ticket.price,
    ticketId: ticket.ticket_id,
    isActive: ticket.is_active,
    seatsBooked: ticket.seats_booked
  })),
  status: response.status
});

export const convertFromAttendeeDetailsResponse = (
  response: AttendeeDetailsResponse
): AttendeeDetails => ({
  questions: [
    ...response.event_form_submission.map((submission) => ({
      id: submission.id?.toString(),
      question: submission.field_name,
      answer:
        submission.field_type === QuestionType.MULTIPLE_CHOICE
          ? submission.field_value
          : submission.field_value[0],
      type: submission.field_type as QuestionType,
      required: submission.is_required,
      displayOrder: submission.display_order
    }))
  ]
});
const isQuestionMetadataChanged = (
  question: Question,
  prevQuestion: Question
): boolean => {
  switch (question.type) {
    case QuestionType.TEXT:
    case QuestionType.TEXT_AREA:
    case QuestionType.NUMBER:
    case QuestionType.DATE:
    case QuestionType.MOBILE_NUMBER:
      return false;
    case QuestionType.DROP_DOWN:
    case QuestionType.MULTIPLE_CHOICE:
    case QuestionType.SINGLE_CHOICE: {
      const options = question.questionMetaData as string[];
      const prevOptions = prevQuestion.questionMetaData as string[];
      if (options.length !== prevOptions.length) {
        return true;
      }
      return !options.every((option) =>
        prevOptions.some((prevOption) => option === prevOption)
      );
    }
    default:
      return false;
  }
};

export const getUpdatedQuestions = (
  prevQuestions: Question[],
  currentQuestions: Question[]
) => {
  const deletedQuestions = prevQuestions
    .filter((question) => !currentQuestions.some((q) => q.id === question.id))
    .map((question) => ({ ...question, isDeleted: true }));

  const updatedQuestions: Question[] = [];
  currentQuestions.forEach((question) => {
    const previouslySavedQuestion = prevQuestions.find(
      (q) => q.id === question.id
    );
    if (previouslySavedQuestion) {
      const isRequiredChanged =
        question.required !== previouslySavedQuestion.required;
      const isMetadataChanged = isQuestionMetadataChanged(
        question,
        previouslySavedQuestion
      );
      const isOrderChanged =
        question.displayOrder !== previouslySavedQuestion.displayOrder;
      if (
        previouslySavedQuestion.question !== question.question ||
        isRequiredChanged ||
        isMetadataChanged ||
        isOrderChanged
      ) {
        updatedQuestions.push({
          ...question,
          id: '',
          isDeleted: false
        });
        updatedQuestions.push({
          ...previouslySavedQuestion,
          isDeleted: true
        });
      }
    } else {
      updatedQuestions.push({
        ...question,
        id: ''
      });
    }
  });
  return [...updatedQuestions, ...deletedQuestions];
};

export const getDateRangeStrings = (startDate: string, endDate: string) => {
  const isSame = isSameDay(startDate, endDate);
  const getFormattedDate = (date: string) =>
    formatISODate({
      date,
      format: 'WK MMM DD-YYYY'
    });
  const getFormattedTime = (date: string) =>
    formatISODate({ date, format: 'hh:mm am/pm' })
      .toUpperCase()
      .replace(' ', '');

  if (isSame) {
    return {
      startDate: `${getFormattedDate(startDate)}, ${getFormattedTime(
        startDate
      )} - ${getFormattedTime(endDate)}`,
      endDate: null
    };
  }
  return {
    startDate: `${getFormattedDate(startDate)}, ${getFormattedTime(startDate)}`,
    endDate: `${getFormattedDate(endDate)}, ${getFormattedTime(endDate)}`
  };
};

export const getRegistrationCloseTime = (
  registrationCloseTime: string,
  registrationCloseDate: string,
  endDate: string,
  endTime: string
) => {
  if (registrationCloseTime) {
    return registrationCloseTime;
  }

  const formattedEventEndDate = formatISODate({
    date: endDate,
    format: 'dd-mm-yyyy'
  });
  const formattedRegistrationEndDate = formatISODate({
    date: registrationCloseDate,
    format: 'dd-mm-yyyy'
  });

  if (formattedEventEndDate === formattedRegistrationEndDate) {
    return endTime;
  }
  const endOfDay = new Date(registrationCloseDate);
  endOfDay.setHours(23, 59, 59, 999);
  endOfDay.toISOString();
  return formatISODate({
    date: endOfDay,
    format: 'hh:mm am/pm'
  }).toUpperCase();
};
