import { DateRange } from '@types';
import { formatISODate, getFormattedDateTimeWithOffset } from '@utils/date';
import { TimeZones } from '@constants/date';

import { DashboardDateRange, PaymentModesChartColors } from './constants';
import {
  BranchRevenueResponse,
  DateRangeType,
  GroupPerfomanceType,
  ProgressBarDataType
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const getSelectedDateRange = (
  dateRange: DateRange
): DashboardDateRange => ({
  startDate: formatISODate({
    date: dateRange.startDate,
    format: 'dd-mm-yyyy',
    separator: '-'
  }),
  endDate: formatISODate({
    date: dateRange.endDate,
    format: 'dd-mm-yyyy',
    separator: '-'
  })
});

const formatDate = (dateString: string) => {
  const [day, month, year] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
};

export const formatBranchRevenueChartData = (
  startDateString: string,
  endDateString: string,
  dataValues: BranchRevenueResponse[],
  isAllBranch = true
) => {
  const startDate = formatDate(startDateString);
  const endDate = formatDate(endDateString);

  const dateArray: number[] = [];

  while (startDate <= endDate) {
    dateArray.push(new Date(startDate).getTime());
    startDate.setDate(startDate.getDate() + 1);
  }

  const series: any[] = [];

  const filteredValues = isAllBranch ? dataValues : dataValues.slice(0, 1);

  filteredValues.forEach((item) => {
    const branchName = item.branch_name;
    const data: number[][] = [];
    const collectedData: number[][] = [];
    const dueData: number[][] = [];

    dateArray.forEach((date) => {
      const dateString = formatISODate({
        date: new Date(date),
        format: 'yyyy-mm-dd',
        separator: '-'
      });
      const value = item.branch_revenue.find(
        (revenueData) => revenueData.date === dateString
      );
      if (isAllBranch)
        data.push([date, value?.collected || 0, value?.due || 0]);
      else {
        collectedData.push([date, value?.collected || 0]);
        dueData.push([date, value?.due || 0]);
      }
    });

    if (isAllBranch)
      series.push({
        name: branchName,
        data
      });
    else {
      series.push({ name: 'Collected', data: collectedData });
      series.push({ name: 'Due', data: dueData });
    }
  });

  return { dateArray, series };
};

export const formatPaymentLinksData = (paymentLinksAndModesData: any) => ({
  primary: {
    label: 'Paid',
    value: paymentLinksAndModesData?.payment_links?.converted || 0
  },
  secondary: {
    label: 'Generated',
    value: paymentLinksAndModesData?.payment_links?.generated || 0
  }
});

export const getPaymentModeData = (
  paymentLinksAndModesData: any
): ProgressBarDataType[] => [
  {
    value: paymentLinksAndModesData?.payment_modes?.upi || 0,
    color: PaymentModesChartColors.UPI,
    label: 'UPI',
    id: 'upi'
  },
  {
    value: paymentLinksAndModesData?.payment_modes?.cards || 0,
    color: PaymentModesChartColors.Cards,
    label: 'Cards',
    id: 'cards'
  },
  {
    value: paymentLinksAndModesData?.payment_modes?.net_banking || 0,
    color: PaymentModesChartColors.NetBanking,
    label: 'Net Banking',
    id: 'net-banking'
  },
  {
    value: paymentLinksAndModesData?.payment_modes?.others || 0,
    color: PaymentModesChartColors.Others,
    label: 'Others',
    id: 'others'
  },
  {
    value: paymentLinksAndModesData?.payment_modes?.offline || 0,
    color: PaymentModesChartColors.Offline,
    label: 'Offline',
    id: 'offline'
  }
];

export const getFormattedDateForChartAPI = (
  selectedDateRange: DateRangeType
) => {
  const startDateString = selectedDateRange.startDate;
  const endDateString = selectedDateRange.endDate;

  const startDate = getFormattedDateTimeWithOffset(
    formatDate(startDateString),
    TimeZones['Asia/Kolkata']
  );
  const endDate = getFormattedDateTimeWithOffset(
    formatDate(endDateString),
    TimeZones['Asia/Kolkata'],
    true
  );

  return { startDate, endDate };
};

export const getDashboardCardData = (
  paymentOverview: any,
  isGrowthRequired: boolean
) => {
  const cardValues = [
    {
      title: 'Collected',
      value: paymentOverview?.collection_amount,
      icon: 'collected-icon',
      tooltipValue:
        'Collected includes both the amount settled to your account and the amount to be settled.',
      ...(isGrowthRequired && {
        growthValue: Math.round(paymentOverview?.collection_growth)
      })
    },
    {
      title: 'Due',
      value: paymentOverview?.due_amount,
      icon: 'due-icon',
      tooltipValue: '',
      ...(isGrowthRequired && {
        growthValue: Math.round(paymentOverview?.due_growth)
      })
    },
    {
      title: 'To be settled',
      value: paymentOverview?.to_be_settled,
      icon: 'toBeSettled-icon',
      tooltipValue: ''
    }
  ];

  const groupInfo = [
    {
      title: 'Groups',
      value: paymentOverview?.groups,
      icon: 'group-icon'
    },
    {
      title: 'Members',
      value: paymentOverview?.members,
      icon: 'member-icon'
    }
  ];
  return { cardValues, groupInfo };
};

const roundUpToNearest = (num: number) => {
  if (num <= 10) return 10;
  const digits = Math.floor(Math.log10(num)) + 1; // Number of digits in the number
  const scale = 10 ** (digits - 1); // Scale to round up to the nearest 10, 100, 1000, etc.
  return Math.ceil(num / scale) * scale;
};

const splitIntoParts = (num: number, parts = 5) => {
  const step = num / parts;
  const result = [];
  for (let i = 0; i <= parts; i += 1) {
    result.push(Math.round(step * i));
  }
  return result;
};

export const getSplitValues = (groupPerfomanceData?: GroupPerfomanceType[]) => {
  let maxValue = 0;
  if (groupPerfomanceData) {
    groupPerfomanceData.forEach((group) => {
      const totalAmount =
        parseFloat(group.amount_due) + parseFloat(group.amount_collected);
      if (totalAmount > maxValue) {
        maxValue = totalAmount;
      }
    });
  }
  maxValue = roundUpToNearest(maxValue);
  return { maxValue, splitValues: splitIntoParts(maxValue) };
};

export const formattedValue = (value: number) => {
  if (value >= 1000000) {
    if (value % 1000000 === 0) return `${(value / 1000000).toFixed(0)}M`;
    return `${(value / 1000000).toFixed(1)}M`;
  }
  if (value >= 1000) {
    if (value % 1000 === 0) return `${(value / 1000).toFixed(0)}K`;
    return `${(value / 1000).toFixed(1)}K`;
  }
  return `${value}`;
};
