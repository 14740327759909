import { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';

import { Button } from '@components';
import { DatePickerProps } from '@types';
import { formatISODate } from '@utils/date';

import colors from '../../../themes/colors';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles.css';

const DatePicker = ({
  className,
  title = 'Select End Date',
  titleStyle = '',
  handleCancel,
  cancelButtonStyle,
  cancelButtonLabel,
  handleConfirm,
  confirmButtonStyle,
  confirmButtonLabel,
  selectedDate,
  calendarStyle,
  isMinDateRequired = true,
  isMaxDateRequired = false,
  maxDate,
  minDate,
  onShownDateChange
}: DatePickerProps) => {
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    if (
      selectedDate &&
      formatISODate({ date: selectedDate, format: 'dd-mm-yyyy' }) !==
        formatISODate({ date: date || '', format: 'dd-mm-yyyy' })
    ) {
      setDate(selectedDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const handleOnChange = (modifiedDate: Date) => {
    setDate(modifiedDate);
  };

  return (
    <div
      className={`date-picker flex min-w-83.5 flex-col items-center justify-center p-6 text-sm font-normal ${
        className || ''
      }`}
    >
      <div className={`mb-2 w-full text-center text-base ${titleStyle}`}>
        {title}
      </div>

      <Calendar
        className={`${calendarStyle} !text-sm !text-content`}
        date={date}
        {...(isMinDateRequired ? { minDate: minDate || new Date() } : {})}
        {...(isMaxDateRequired ? { maxDate: maxDate || new Date() } : {})}
        onChange={handleOnChange}
        color={`${colors.primary}`}
        onShownDateChange={onShownDateChange}
      />
      <div className="mt-2 flex w-full justify-between">
        <Button
          handleClick={handleCancel}
          variant="transparent"
          size="none"
          className={`ml-4 text-base font-medium ${
            cancelButtonStyle && cancelButtonStyle
          }`}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          disabled={!date}
          handleClick={() => handleConfirm(date)}
          variant="transparent"
          size="none"
          className={`mr-4 text-base font-medium ${
            confirmButtonStyle && confirmButtonStyle
          }`}
        >
          {confirmButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default DatePicker;
