import {
  amountRegex,
  nameRegex,
  gstinRegex,
  ifscRegex,
  invalidCharInNameRegex,
  panRegex,
  phoneNumberRegex,
  upiIdRegex,
  groupNameRegex,
  emailRegex
} from '@constants/regex';

export const validateWithRegexp = (regex: any, value?: string) =>
  regex.test(value);

export const isInvalidAmount = (
  amount: string,
  maxLimit: string,
  minLimit: string
) =>
  !validateWithRegexp(amountRegex, amount) ||
  Number(amount) > Number(maxLimit) ||
  Number(amount) < Number(minLimit);

export const isInvalidNotes = (
  notes: string,
  maxLength: string,
  minLength: string
) => notes.length > Number(maxLength) || notes.length < Number(minLength);

export const isInvalidMemberName = (
  name: string,
  maxLength: string,
  minLength: string
) => {
  if (typeof name !== 'string') return true;
  const trimmedName = `${name}`.trim();
  return (
    trimmedName.length < Number(minLength) ||
    trimmedName.length > Number(maxLength) ||
    !validateWithRegexp(invalidCharInNameRegex, name)
  );
};

export const isInvalidGroupName = (name: string) => {
  const trimmedName = name?.trim();
  return (
    trimmedName.length < 3 ||
    trimmedName.length > 30 ||
    !validateWithRegexp(groupNameRegex, name)
  );
};

// check for  branch name and org name
export const isInvalidEntityName = (name: string) => {
  const trimmedName = name?.trim();
  return (
    trimmedName.length < 3 ||
    trimmedName.length > 30 ||
    !validateWithRegexp(nameRegex, name)
  );
};

export const isInvalidPhoneNumber = (phoneNumber: string) =>
  !validateWithRegexp(phoneNumberRegex, phoneNumber);

export const isInvalidIfscCode = (code: string) =>
  !validateWithRegexp(ifscRegex, code);

export const isInvalidUpiId = (upiId: string) =>
  !validateWithRegexp(upiIdRegex, upiId);

export const isInvalidEmail = (email: string) =>
  !validateWithRegexp(emailRegex, email);

export const isInvalidPincode = (pincode: string) =>
  pincode && pincode.length !== 6;

export const isInvalidName = (name: string) => {
  const trimmedName = name.trim();
  return (
    trimmedName.length < 3 ||
    trimmedName.length > 30 ||
    !validateWithRegexp(nameRegex, name)
  );
};

export const isInvalidGstin = (gstin: string) =>
  !validateWithRegexp(gstinRegex, gstin);

export const isInvalidPan = (pan: string) => !validateWithRegexp(panRegex, pan);

export const areObjectsEqual = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  let isEqual = true;
  keys1.forEach((key) => {
    if (obj1[key] !== obj2[key]) isEqual = false;
  });

  return isEqual;
};
