export enum RadioCardType {
  INDIVIDUAL = 'individual',
  ORGANIZATION = 'organization',
  UPI = 'upi',
  BANK = 'bank_account',
  ORGANISATION_ADMIN = 'organisation_admin',
  BRANCH_ADMIN = 'branch_admin',
  GROUP_ADMIN = 'group_admin',
  FINANCE_ADMIN = 'finance',
  ATTENDANCE_MANAGER = 'attendance_manager',
  EVENT_MANAGER = 'event_manager',
  PAYMENT_MANAGER = 'payment_manager'
}

/* eslint-disable arrow-body-style */
export interface RadioCardProps {
  isSelected: boolean;
  type: RadioCardType;
  description?: string;
  accountLabelStyle?: string;
  radioCardStyle?: string;
  handleClick?: () => void;
  showIcon?: boolean;
}
