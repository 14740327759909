import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useNotify from '@hooks/notify';
import {
  certificateOptions,
  CertificateType
} from '@features/authentication/constants/common';
import { Button, ConfirmationModal, Input, RadioGroup } from '@components';
import {
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
  SubscriptionPlanExpiredMessage,
  SubscriptionStatus
} from '@constants/generic';
import { ErrorCode } from '@constants/errorCodes';
import UserPermissionTypes from '@constants/permissions';
import { SUBSCRIPTIONS } from '@constants/routes';
import { isInvalidGstin, isInvalidPan } from '@utils/validations';
import { getLocalStorageItem } from '@utils/storage';
import {
  useUpgradeLiteMutation,
  useVerifyGstinMutation,
  useVerifyPanMutation
} from '@features/authentication/queries/AuthQuery';
import { sendMixpanelEvent, Event } from '@core/analytics';
import { ApiError, FieldError } from '@types';
import { capitalizeFirstLetter } from '@utils/stringFormat';
import testId from '@constants/testId';

import BankAccountDetails from './BankAccountDetails';
import { BankInfoType, VerifiedBankInfoType } from '../types';
import {
  BankInfoDefaultValue,
  VerifiedBankInfoDefaultValue
} from '../constants';
import useVerify from '../hooks/verify';

interface UpgradeToCofeeModalProps {
  onboardingType?: string;
}

const UpgradeToCofeeModal = ({ onboardingType }: UpgradeToCofeeModalProps) => {
  const accountInfo = useSelector((state: any) => state.auth.accountInfo);
  const { createErrorAlert } = useNotify();
  const navigate = useNavigate();
  const { rolesAndPermissions } = useSelector((state: any) => state.user);
  const { currentSubscription } = useSelector((state: any) => state.user);

  const [fieldError, setFieldError] = useState<FieldError>({
    field: null,
    message: ''
  });
  const [verifiedName, setVerifiedName] = useState<VerifiedBankInfoType>(
    VerifiedBankInfoDefaultValue
  );

  const [cardError, setCardError] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<{
    cardNumber: string;
    certificateType: string;
  }>({
    cardNumber: accountInfo?.cardNumber || '',
    certificateType: accountInfo?.certificateType || certificateOptions[0].key
  });
  const [bankInfo, setBankInfo] = useState<BankInfoType>(BankInfoDefaultValue);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const [verifyPan, { isLoading: isVerifyPanLoading }] = useVerifyPanMutation();
  const [verifyGstin, { isLoading: isGstinVerifyLoading }] =
    useVerifyGstinMutation();
  const [onboardOrganization, { isLoading: isUpgradeLiteLoading }] =
    useUpgradeLiteMutation();

  const { handleVerifyBank, isVerifyBankLoading } = useVerify();

  const handleCertificateSelect = (certificate: string) => {
    setCardError('');
    setInfo((prev) => ({
      ...prev,
      cardNumber: '',
      certificateType: certificate
    }));
  };

  const onCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCardError('');
    setInfo((prev) => ({
      ...prev,
      cardNumber: e.target.value
    }));
  };

  const onInputChange = (field: string, value: string | number) => {
    setBankInfo((prev: any) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleCertificateError = (
    cardNumber: string,
    cardType: CertificateType
  ) => {
    if (cardType === CertificateType.PAN)
      if (isInvalidPan(cardNumber)) {
        setCardError('Invalid PAN');
        setLoading(false);
        return true;
      }
    if (cardType === CertificateType.GSTIN)
      if (isInvalidGstin(cardNumber)) {
        setCardError('Invalid GSTIN');
        setLoading(false);
        return true;
      }
    return false;
  };

  const handleUpgradeLite = async () => {
    sendMixpanelEvent(Event.CONTINUE_IN_COMPLETE_KYC_POPUP_CLICKED, {
      organizationId: getLocalStorageItem('organizationId')
    });
    if (currentSubscription?.status === SubscriptionStatus.EXPIRED) {
      createErrorAlert(SubscriptionPlanExpiredMessage);
      if (
        rolesAndPermissions.permissions.includes(
          UserPermissionTypes.SUBSCRIPTION_VIEW
        )
      ) {
        navigate(SUBSCRIPTIONS.PLAN_DETAILS);
      }
    } else {
      const response = await onboardOrganization({
        orgId: getLocalStorageItem('organizationId'),
        body: { primary_account: verifiedName.accountDetailId }
      });
      if ('data' in response) {
        sendMixpanelEvent(
          onboardingType === 'Rapid Onboard'
            ? Event.KYC_DETAILS_VERIFIED
            : Event.UPGRADE_LITE_SUCCESS,
          {
            organizationId: getLocalStorageItem('organizationId')
          }
        );
        window.location.reload();
      } else if ('error' in response) {
        const error = response.error as ApiError;
        createErrorAlert(error.data?.message);
        if (error?.data?.errorCode === ErrorCode.ERR_SUBSCRIPTION_EXPIRED) {
          if (
            rolesAndPermissions.permissions.includes(
              UserPermissionTypes.SUBSCRIPTION_VIEW
            )
          ) {
            navigate(SUBSCRIPTIONS.PLAN_DETAILS);
          }
        }
        sendMixpanelEvent(
          onboardingType === 'Rapid Onboard'
            ? Event.KYC_VERIFICATION_ERROR
            : Event.UPGRADE_LITE_ERROR,
          {
            organizationId: getLocalStorageItem('organizationId'),
            error: error.data?.errorCode
          }
        );
      }
    }
  };

  const handleErrorInVerification = (error: ApiError) => {
    const errorMsg = error.data?.message
      ? capitalizeFirstLetter(error.data?.message)
      : 'Error in verification';

    createErrorAlert(errorMsg);
    setCardError(errorMsg);
  };

  const verifyBankDetails = () => {
    handleVerifyBank({
      bankInfo,
      setFieldError,
      setVerifiedName,
      setShowConfirmationModal
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (info.certificateType === CertificateType.PAN && info.cardNumber) {
      if (handleCertificateError(info.cardNumber, CertificateType.PAN)) {
        return;
      }
      const response = await verifyPan({
        orgId: getLocalStorageItem('organizationId') || '',
        body: {
          pan: info.cardNumber
        }
      });
      if ('data' in response) {
        verifyBankDetails();
      } else if ('error' in response) {
        const error = response.error as ApiError;
        handleErrorInVerification(error);
      }
    } else if (
      info.certificateType === CertificateType.GSTIN &&
      info.cardNumber
    ) {
      if (handleCertificateError(info.cardNumber, CertificateType.GSTIN)) {
        return;
      }
      const response = await verifyGstin({
        orgId: getLocalStorageItem('organizationId') || '' || '',
        body: {
          gstin: info.cardNumber
        }
      });
      if ('data' in response) {
        verifyBankDetails();
      } else if ('error' in response) {
        const error = response.error as ApiError;
        handleErrorInVerification(error);
      }
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
    sendMixpanelEvent(Event.CANCEL_IN_COMPLETE_KYC_POPUP_CLICKED, {
      organizationId: getLocalStorageItem('organizationId')
    });
  };

  const disableButton =
    !info.cardNumber ||
    !bankInfo.accountNumber ||
    !bankInfo.ifsc ||
    !bankInfo.confirmAccountNumber;

  const isButtonLoading =
    isVerifyPanLoading ||
    isLoading ||
    isUpgradeLiteLoading ||
    isVerifyBankLoading ||
    isGstinVerifyLoading;

  return (
    <div>
      <div className="flex gap-2 text-xl font-medium text-content">
        <div>Submit KYC</div>
      </div>
      <div className="mt-6 text-sm font-medium text-grey">
        Add your PAN or GSTIN details to send payment links
      </div>
      <RadioGroup
        options={certificateOptions}
        handleChange={handleCertificateSelect}
        selectedValue={info.certificateType}
        className="mt-6"
      />
      <Input
        name="cardNumber"
        value={info?.cardNumber}
        placeholder={
          info.certificateType === CertificateType.PAN
            ? 'Permanent Account Number'
            : 'GST Identification Number'
        }
        onChange={onCardNumberChange}
        className="mt-6 rounded-xl"
        maxLength={NAME_MAX_LENGTH}
        minLength={NAME_MIN_LENGTH}
        error={!!cardError}
        errorMessage={cardError}
      />
      <div className="mt-6 text-sm font-medium text-grey">
        Bank Account Details
      </div>
      <BankAccountDetails
        info={bankInfo}
        onInputChange={onInputChange}
        isLoadingAccountDetails={false}
        fieldError={fieldError}
        setFieldError={setFieldError}
      />
      <Button
        label="Verify & Continue"
        className="mt-6 w-full"
        size="large"
        handleClick={handleSubmit}
        loading={isButtonLoading}
        disabled={disableButton}
      />
      {showConfirmationModal && (
        <ConfirmationModal
          title="Do you wish to continue?"
          isOpen={showConfirmationModal}
          handleConfirm={handleUpgradeLite}
          handleCancel={handleCancel}
          buttonIds={{
            cancelButton: testId.groupsPage.kycConfirmPopup.cancelButton,
            confirmButton: testId.groupsPage.kycConfirmPopup.confirmButton
          }}
        >
          <div className="mx-2 mt-4 text-sm text-grey">
            <div>The details of provided bank account is</div>
            <div>
              Name :{' '}
              <span className="font-semibold">{verifiedName.userName}</span>
            </div>
            <div>
              Branch :{' '}
              <span className="font-semibold">{verifiedName.bankName}</span>
            </div>
          </div>
        </ConfirmationModal>
      )}
    </div>
  );
};

export default UpgradeToCofeeModal;
