import { useSelector } from 'react-redux';
import clsx from 'clsx';

import Dropdown from '@components/drop-down/Dropdown';
import { S3_URL } from '@constants/generic';

import { defaultCountryCode } from './constants';
import { CountryCodeOptions, CountryCodeProps, CountryData } from './types';

const CountryCode = (props: CountryCodeProps) => {
  const {
    className,
    hasSelection,
    countryCode = defaultCountryCode,
    setCountryCode,
    listId,
    titleWrapperStyle
  } = props;

  const renderOption = (label: string, code: string) => (
    <div
      className={`flex w-full items-center gap-3 ${titleWrapperStyle || ''}`}
    >
      <img
        src={`${S3_URL}/country_flag/${code}.png`}
        alt="flag"
        className="h-4 w-5"
      />
      <div className="text-base font-medium text-content">{label}</div>
    </div>
  );

  const { appConfig } = useSelector((state: any) => state.user);

  const countryCodeOptions: CountryCodeOptions[] = (
    appConfig?.supported_phone_number_countries || []
  ).map((country: CountryData) => ({
    id: `${country.dial_code}`,
    label: `${country.dial_code}`,
    value: `${country.dial_code}`,
    element: renderOption(`${country.dial_code}`, country.code)
  }));

  const selectedCountry = countryCodeOptions.find(
    (option) => option.id === countryCode
  );

  return (
    <div className={className}>
      {hasSelection && setCountryCode ? (
        <Dropdown
          currentFilter={countryCode}
          filterOptions={countryCodeOptions}
          onSelectFilter={(option) => setCountryCode?.(option)}
          className="!h-12.5 !min-w-30 !rounded-lg !bg-grey10 "
          optionsStyle="left-0 !min-w-40 !max-w-40 !max-h-60 !overflow-y-auto"
          listId={listId}
        />
      ) : (
        <div
          className={clsx(
            'flex h-12.5 w-full items-center justify-start gap-2 rounded-xl border bg-grey10 p-4',
            className
          )}
        >
          {selectedCountry?.element}
        </div>
      )}
    </div>
  );
};

export default CountryCode;
