import { useEffect, useId, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  defaultCountryCode,
  germanyCountryCode
} from '@components/country-code/constants';
import { CountryData } from '@components/country-code/types';
import FeeConfigInput from '@components/fee-config-input/FeeConfigInput';
import {
  duplicatePhoneNumberErrorMessage,
  GROUP_MEMBER_MAX_AMOUNT,
  GROUP_MEMBER_MIN_AMOUNT,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH
} from '@constants/generic';
import { invalidCharInNameRegex } from '@constants/regex';
import { PaymentCollectionType } from '@features/group-management/constants';
import { isValidPhoneNumber } from '@helpers/generic';
import useNotify from '@hooks/notify';
import {
  AddMemberModalType,
  AddMemberProps,
  FeeCategoryDataType,
  PaymentCollectionArg,
  SectionHeaders,
  SectionHeadersType,
  ValidationError
} from '@types';
import {
  getAmountErrorMessage,
  getNameErrorMessage,
  isMinLengthSatisfied,
  sanitizeNumberInput
} from '@utils/generic';
import {
  isInvalidAmount,
  isInvalidEmail,
  isInvalidMemberName,
  isInvalidPincode
} from '@utils/validations';

import AmountInput from '../amount-input/AmountInput';
import AmountInputTooltip from '../amount-input-tooltip/AmountInputTooltip';
import Button from '../button/Button';
import CountryCode from '../country-code/CountryCode';
import Icon from '../icon/Icon';
import Input from '../input/Input';
import Label from '../label/Label';
import MemberAddress from './MemberAddress';
import MemberDueDate from './MemberDueDate';
import MemberStartDate from './MemberStartDate';

import Modal from '../../layouts/modal/Modal';

const AddMemberModal = ({
  type = AddMemberModalType.ADD,
  isOpen,
  handleCancel,
  addMember,
  paymentCollectionType,
  selectedmember,
  groupAmount,
  groupDueDate,
  groupFrequency,
  isFeeCategoryEnabled = false,
  feeCategoryData,
  feeCategoriesValues,
  memberList,
  isImmediatePayment = false
}: AddMemberProps) => {
  const id = useId();

  const { orgConfig } = useSelector((state: any) => state.user);
  const { appConfig } = useSelector((state: any) => state.user);

  const [feeCategories, setFeeCategories] = useState<FeeCategoryDataType[]>([]);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [primaryCountryCode, setPrimaryCountryCode] =
    useState<string>(defaultCountryCode);
  const [primaryNumber, setPrimaryNumber] = useState<string>('');
  const [altCountryCode, setAltCountryCode] =
    useState<string>(defaultCountryCode);
  const [altNumber, setAltNumber] = useState<string>('');
  const [amount, setAmount] = useState<string>(groupAmount || '');
  const [dueDate, setDueDate] = useState<{
    day: string;
    paymentCollectionType: PaymentCollectionType | '';
    interval: string;
  }>({
    day: groupDueDate || '',
    paymentCollectionType,
    interval: String(groupFrequency) || '1'
  });
  const [startDate, setStartDate] = useState<string>('');
  const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);
  const [memberId, setMemberId] = useState<string>('');
  const [error, setError] = useState<ValidationError>({});
  const [openSection, setOpenSection] = useState<SectionHeadersType>(
    SectionHeaders.BASICINFO
  );
  const [addressLine1, setAddressLine1] = useState<string>('');
  const [addressLine2, setAddressLine2] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [pincode, setPincode] = useState<string>('');

  const { createErrorAlert } = useNotify();

  const groupMemberMaxAmount =
    orgConfig?.group_member_amount_max || GROUP_MEMBER_MAX_AMOUNT;
  const groupMemberMinAmount =
    orgConfig?.group_member_amount_min || GROUP_MEMBER_MIN_AMOUNT;
  const memberNameMaxLength =
    orgConfig?.member_name_length_max || NAME_MAX_LENGTH;
  const memberNameMinLength =
    orgConfig?.member_name_length_min || NAME_MIN_LENGTH;
  const isEmailEnabled = !!orgConfig?.is_email_feature_enabled;

  const onLine1Change = (value: string) => {
    setAddressLine1(value);
  };

  const onLine2Change = (value: string) => {
    setAddressLine2(value);
  };

  const onCountryChange = (value: string) => {
    if (value === 'Others') {
      setState('');
      setCity('');
      setPincode('');
    }
    setCountry(value);
  };

  const onStateChange = (value: string) => {
    setState(value);
  };

  const onCityChange = (value: string) => {
    setCity(value);
  };

  const onPincodeChange = (value: string) => {
    setError({ ...error, pincode: '' });
    setPincode(value);
  };

  const onBlurPincode = (value: string) => {
    if (value && value.length !== 6)
      setError({ ...error, pincode: 'Invalid pincode' });
  };

  const onMemberNameChange = (value: string) => {
    setError({ ...error, name: '' });
    setName(value);
  };

  const onMemberEmailChange = (value: string) => {
    setError({ ...error, email: '' });
    setEmail(value);
  };

  const onEmailBlur = (value: string) => {
    if (value && isInvalidEmail(value)) {
      setError({ ...error, email: 'Invalid email' });
    }
  };

  const onMemberNameBlur = (value: string) => {
    if (!isMinLengthSatisfied(value))
      setError({
        ...error,
        name: getNameErrorMessage(memberNameMinLength)
      });
  };

  const onPrimaryNumberChange = (value: string) => {
    if (primaryNumber === altNumber) {
      setError({ ...error, altNum: '', primaryNum: '' });
    } else {
      setError({ ...error, primaryNum: '' });
    }
    const maxLength = primaryCountryCode === germanyCountryCode ? 11 : 10;
    if (value.length <= maxLength) {
      setPrimaryNumber(value);
    }
  };

  const onStartDateChange = (date: string) => {
    setStartDate(date);
  };

  const onClearStartDate = () => {
    setStartDate('');
  };

  const getCountryId = (countryCode: string) =>
    (appConfig.supported_phone_number_countries || []).find(
      (countryData: CountryData) => `${countryData.dial_code}` === countryCode
    )?.code;

  const onPrimaryCountryCodeChange = (value: string) => {
    const numberToValidate =
      value !== germanyCountryCode && primaryNumber.length > 10
        ? primaryNumber.slice(0, 10)
        : primaryNumber;
    if (
      numberToValidate &&
      !isValidPhoneNumber(numberToValidate, getCountryId(value))
    ) {
      setError({ ...error, primaryNum: 'Invalid phone number' });
    } else {
      setError({ ...error, primaryNum: '' });
    }
    if (numberToValidate !== primaryNumber) {
      setPrimaryNumber(numberToValidate);
    }
    setPrimaryCountryCode(value);
  };

  const onPrimaryNumberBlur = (value: string) => {
    if (!value) return;
    let errorMessage = '';
    if (!isValidPhoneNumber(value, getCountryId(primaryCountryCode)))
      errorMessage = 'Invalid phone number';
    if (errorMessage !== '') setError({ ...error, primaryNum: errorMessage });
    if (
      value &&
      `${primaryCountryCode}${value}` === `${altCountryCode}${altNumber}`
    )
      setError({ ...error, altNum: duplicatePhoneNumberErrorMessage });
  };

  const onAltNumberChange = (value: string) => {
    if (altNumber === primaryNumber) {
      setError({ ...error, primaryNum: '', altNum: '' });
    } else {
      setError({ ...error, altNum: '' });
    }
    const maxLength = altCountryCode === germanyCountryCode ? 11 : 10;
    if (value.length <= maxLength) {
      setAltNumber(value);
    }
  };

  const onAltCountryCodeChange = (value: string) => {
    const numberToValidate =
      value !== germanyCountryCode && altNumber.length > 10
        ? altNumber.slice(0, 10)
        : altNumber;
    if (
      numberToValidate &&
      !isValidPhoneNumber(numberToValidate, getCountryId(value))
    ) {
      setError({ ...error, altNum: 'Invalid phone number' });
    } else {
      setError({ ...error, altNum: '' });
    }
    if (numberToValidate !== altNumber) {
      setAltNumber(numberToValidate);
    }
    setAltCountryCode(value);
  };

  const onAltNumberBlur = (value: string) => {
    if (!value) return;
    let errorMessage = '';
    if (!isValidPhoneNumber(value, getCountryId(altCountryCode)))
      errorMessage = 'Invalid phone number';
    else if (
      value &&
      `${primaryCountryCode}${primaryNumber}` === `${altCountryCode}${value}`
    )
      errorMessage = duplicatePhoneNumberErrorMessage;
    if (errorMessage !== '') setError({ ...error, altNum: errorMessage });
  };

  const onAmountChange = (value: string) => {
    setError({ ...error, amount: '' });
    const sanitizedAmount = sanitizeNumberInput(value);
    setAmount(sanitizedAmount);
  };

  const onAmountBlur = (value: string) => {
    if (value.length && Number(value) < Number(groupMemberMinAmount)) {
      setError({
        ...error,
        amount: getAmountErrorMessage(groupMemberMinAmount)
      });
    }
  };

  const formattedDuesDate = () => {
    const dueDateValue = dueDate.day || groupDueDate || '';
    // const formattedDate = formatISODate({
    //   date:
    //     groupStartDate && isTodayOrFutureDate(groupStartDate)
    //       ? findDueDateFromStartDate(
    //           paymentCollectionType,
    //           dueDateValue,
    //           groupStartDate
    //         )
    //       : dueDateValue,
    //   separator: '-',
    //   format: 'yyyy-mm-dd'
    // });
    // return formattedDate;
    return dueDateValue;
  };

  // Todo: update primaryNumber type Sec
  const saveCurrentMember = () => {
    addMember({
      primaryNumber,
      name,
      altNumber: altNumber || '',
      amount: amount || groupAmount || '',
      dueDate: formattedDuesDate(),
      paymentCollectionType: dueDate.paymentCollectionType,
      interval: dueDate.interval,
      feeCategoryData: feeCategories,
      id:
        type === AddMemberModalType.EDIT
          ? memberId
          : `member_${id}_${name}${primaryCountryCode}${primaryNumber}`,
      primaryNumberCountryCode: primaryCountryCode,
      altNumberCountryCode: altCountryCode,
      startDate,
      email,
      addressLine1,
      addressLine2,
      country,
      state,
      city,
      pincode
    });
  };

  const onSave = () => {
    const hasDuplicate = memberList.some(
      (member) =>
        `${member.name}_${member.primaryNumberCountryCode}${member.primaryNumber}` ===
          `${name}_${primaryCountryCode}${primaryNumber}` &&
        member.id !== memberId
    );

    if (hasDuplicate) {
      createErrorAlert('Member already exists');
      return;
    }

    saveCurrentMember();
    handleCancel();
  };

  const getFeeConfigValue = (categoryItem: any, index: number) => {
    const category = selectedmember?.feeCategoryData?.find(
      (item) =>
        item.id === (categoryItem.branch_fee_category_id ?? categoryItem.id)
    );
    if (category?.isManuallyUpdated) return category.value;
    return (
      (feeCategoriesValues && feeCategoriesValues[index]?.value) ||
      categoryItem.amount ||
      categoryItem.value ||
      0
    );
  };

  const isFeeConfigUpdated = (categoryItem: any) => {
    const category = selectedmember?.feeCategoryData?.find(
      (item) =>
        item.id === (categoryItem.branch_fee_category_id ?? categoryItem.id)
    );
    return !!category?.isManuallyUpdated;
  };

  const resetFeeCategory = () => {
    const formattedData = feeCategoryData?.map((item: any, index: number) => ({
      id: item.branch_fee_category_id ?? item.id,
      label: item.category || item.label,
      value: getFeeConfigValue(item, index),
      isManuallyUpdated: isFeeConfigUpdated(item)
    }));

    setFeeCategories(formattedData || []);
  };

  const getFeeCofigTotalAmount = () =>
    feeCategoryData
      ?.reduce(
        (sum, item, index) => sum + Number(getFeeConfigValue(item, index)),
        0
      )
      .toString() || '0';

  const onSaveAndAddAnother = () => {
    const hasDuplicate = memberList.some(
      (member) =>
        `${member.name}_${member.primaryNumberCountryCode}${member.primaryNumber}` ===
          `${name}_${primaryCountryCode}${primaryNumber}` &&
        member.id !== memberId
    );

    if (hasDuplicate) {
      createErrorAlert('Member already exists');
      return;
    }

    saveCurrentMember();
    setName('');
    setPrimaryNumber('');
    setAltNumber('');
    setAmount(groupAmount || '');
    setDueDate({
      day: groupDueDate || '',
      paymentCollectionType,
      interval: String(groupFrequency) || '1'
    });
    setIsCalendarVisible(false);
    setEmail('');
    setAddressLine1('');
    setAddressLine2('');
    setCountry('');
    setState('');
    setCity('');
    setPincode('');
    setOpenSection(SectionHeaders.BASICINFO);
    setAltCountryCode(defaultCountryCode);
    setPrimaryCountryCode(defaultCountryCode);
    resetFeeCategory();
    setStartDate('');
  };

  const onDueDateChange = (data: PaymentCollectionArg) => {
    // setDueDate(String(data.date));
    setDueDate({
      day: data.date as string,
      interval: data.interval,
      paymentCollectionType: data.type
    });
    setIsCalendarVisible(false);
  };

  const areAllFeeCategoriesFilled =
    feeCategories.length > 0 &&
    feeCategories.every(
      (category: any) => category.value !== undefined && category.value !== ''
    );

  const isSaveDisabled =
    (amount &&
      isInvalidAmount(amount, groupMemberMaxAmount, groupMemberMinAmount)) ||
    isInvalidMemberName(name, memberNameMaxLength, memberNameMinLength) ||
    !isValidPhoneNumber(primaryNumber, getCountryId(primaryCountryCode)) ||
    !!(
      altNumber && !isValidPhoneNumber(altNumber, getCountryId(altCountryCode))
    ) ||
    `${altCountryCode}_${altNumber}` ===
      `${primaryCountryCode}_${primaryNumber}` ||
    (feeCategories.length > 0 &&
      isFeeCategoryEnabled &&
      !areAllFeeCategoriesFilled) ||
    !!(email && isInvalidEmail(email)) ||
    !!(pincode && isInvalidPincode(pincode));

  const hasChanges =
    selectedmember?.name !== name ||
    selectedmember?.primaryNumber !== primaryNumber ||
    selectedmember.altNumber !== altNumber ||
    selectedmember.amount !== amount ||
    selectedmember.dueDate !== dueDate.day ||
    selectedmember.interval !== dueDate.interval ||
    selectedmember.paymentCollectionType !== dueDate.paymentCollectionType ||
    selectedmember.startDate !== startDate ||
    selectedmember.altNumberCountryCode !== altCountryCode ||
    selectedmember.primaryNumberCountryCode !== primaryCountryCode ||
    selectedmember.email !== email ||
    selectedmember.addressLine1 !== addressLine1 ||
    selectedmember.addressLine2 !== addressLine2 ||
    selectedmember.country !== country ||
    selectedmember.state !== state ||
    selectedmember.city !== city ||
    selectedmember.pincode !== pincode;

  const onCategoryAmountChange = (index: number, value: string) => {
    const updatedCategories = [...feeCategories];
    updatedCategories[index].value = sanitizeNumberInput(value);
    updatedCategories[index].isManuallyUpdated = true;
    setFeeCategories(updatedCategories);

    const totalAmount = updatedCategories.reduce(
      (sum, category) => sum + (Number(category.value) || 0),
      0
    );
    // Update the main amount input
    setAmount(totalAmount.toString());
  };

  const formattedFeeCategories = feeCategories.map(({ label, value }) => ({
    category: label,
    amount: value
  }));

  const onClickSectionHeader = (value: SectionHeadersType) => {
    if (openSection !== value) setOpenSection(value);
    else
      setOpenSection(
        value === SectionHeaders.BASICINFO
          ? SectionHeaders.ADDRESS
          : SectionHeaders.BASICINFO
      );
  };

  useEffect(() => {
    if (type === AddMemberModalType.EDIT) {
      setName(selectedmember?.name || '');
      setPrimaryNumber(selectedmember?.primaryNumber || '');
      setPrimaryCountryCode(
        selectedmember?.primaryNumberCountryCode || defaultCountryCode
      );
      setAltNumber(selectedmember?.altNumber || '');
      setAltCountryCode(
        selectedmember?.altNumberCountryCode || defaultCountryCode
      );
      setAmount(
        isFeeCategoryEnabled && feeCategoryData
          ? getFeeCofigTotalAmount()
          : selectedmember?.amount || groupAmount || ''
      );
      setDueDate({
        day: selectedmember?.dueDate || groupDueDate || '',
        interval: selectedmember?.interval || String(groupFrequency) || '1',
        paymentCollectionType:
          selectedmember?.paymentCollectionType ||
          paymentCollectionType ||
          PaymentCollectionType.MONTHLY
      });
      setMemberId(selectedmember?.id || '');
      setEmail(selectedmember?.email || '');
      setAddressLine1(selectedmember?.addressLine1 || '');
      setAddressLine2(selectedmember?.addressLine2 || '');
      setCountry(selectedmember?.country || '');
      setState(selectedmember?.state || '');
      setCity(selectedmember?.city || '');
      setPincode(selectedmember?.pincode || '');
      setStartDate(selectedmember?.startDate || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (isCalendarVisible) {
      const element = document.getElementById('due-date-calendar');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        });
      }
    }
  }, [isCalendarVisible]);

  useEffect(() => {
    resetFeeCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeCategoryData]);

  return (
    <Modal isOpen={isOpen} onCancel={handleCancel} isCloseIconRequired>
      <div className="relative flex w-200 flex-col justify-start rounded-lg bg-theme p-6">
        <div className="mb-3 text-xl font-semibold">
          {type === AddMemberModalType.EDIT ? 'Edit Member' : 'Add Member'}
        </div>
        <div className="">
          <div
            className="flex w-full justify-between py-6"
            role="presentation"
            onClick={() => onClickSectionHeader(SectionHeaders.BASICINFO)}
          >
            <p className="text-lg font-semibold">Basic Info</p>
            <span>
              <Icon
                name="down-icon-dark"
                className={`${
                  openSection === SectionHeaders.BASICINFO ? 'rotate-180' : ''
                }`}
              />
            </span>
          </div>
          {openSection === SectionHeaders.BASICINFO && (
            <div className="no-scrollbar max-h-[400px] overflow-y-auto">
              <div className="grid w-full grid-cols-2 gap-4 py-3">
                <div className="gap-4">
                  <Label label="Name" isRequired />
                  <Input
                    value={name}
                    handleInputChange={onMemberNameChange}
                    inputRegex={invalidCharInNameRegex}
                    type="text"
                    placeholder="Name"
                    maxLength={memberNameMaxLength}
                    minLength={memberNameMinLength}
                    error={!!error.name}
                    errorMessage={error.name}
                    handleInputBlur={onMemberNameBlur}
                  />
                </div>

                <div className="gap-4">
                  <Label label="Primary Number" isRequired />
                  <div className="flex gap-3">
                    <CountryCode
                      hasSelection
                      countryCode={primaryCountryCode}
                      setCountryCode={onPrimaryCountryCodeChange}
                    />
                    <Input
                      className="w-full"
                      value={primaryNumber}
                      handleInputChange={onPrimaryNumberChange}
                      placeholder="Enter phone number"
                      type="phone"
                      error={!!error.primaryNum}
                      errorMessage={error.primaryNum}
                      handleInputBlur={onPrimaryNumberBlur}
                    />
                  </div>
                </div>

                <div className="gap-4">
                  <Label label="Alternate Number" />
                  <div className="flex gap-3">
                    <CountryCode
                      hasSelection
                      countryCode={altCountryCode}
                      setCountryCode={onAltCountryCodeChange}
                    />
                    <Input
                      className="w-full"
                      value={altNumber}
                      handleInputChange={onAltNumberChange}
                      placeholder="Enter phone number"
                      type="phone"
                      error={!!error.altNum}
                      errorMessage={error.altNum}
                      handleInputBlur={onAltNumberBlur}
                      errorStyle={
                        error.altNum === duplicatePhoneNumberErrorMessage
                          ? '!-bottom-10'
                          : ''
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="flex gap-4">
                    <Label label="Amount" className="mr-2" />
                    <AmountInputTooltip
                      maxAmount={groupMemberMaxAmount}
                      minAmount={groupMemberMinAmount}
                      tooltipId="add-member-modal"
                    />
                  </div>
                  <AmountInput
                    value={amount}
                    placeholder="Enter Amount"
                    onAmountChange={onAmountChange}
                    maxAmount={groupMemberMaxAmount}
                    handleInputBlur={onAmountBlur}
                    error={!!error.amount}
                    errorMessage={error.amount}
                    disabled={isFeeCategoryEnabled}
                  />
                </div>

                {!isImmediatePayment && (
                  <MemberDueDate
                    dueDate={dueDate}
                    memberPaymentCollectionType={
                      // eslint-disable-next-line max-len
                      selectedmember?.paymentCollectionType as PaymentCollectionType
                    }
                    memberInterval={selectedmember?.interval as string}
                    memberDueDate={selectedmember?.dueDate as string}
                    paymentCollectionType={paymentCollectionType}
                    groupFrequency={groupFrequency as number}
                    onDueDateChange={onDueDateChange}
                  />
                )}
                {/* Start date */}
                {paymentCollectionType !== PaymentCollectionType.ONCE && (
                  <MemberStartDate
                    startDate={startDate}
                    onStartDateChange={onStartDateChange}
                    onClearStartDate={onClearStartDate}
                  />
                )}

                {isEmailEnabled && (
                  <div className="gap-4">
                    <Label label="Email" />
                    <Input
                      value={email}
                      handleInputChange={onMemberEmailChange}
                      type="text"
                      placeholder="Email"
                      error={!!error.email}
                      errorMessage={error.email}
                      handleInputBlur={onEmailBlur}
                    />
                  </div>
                )}

                {isFeeCategoryEnabled && (
                  <div className="col-span-2">
                    <FeeConfigInput
                      feeCategories={formattedFeeCategories}
                      onCategoryAmountChange={({ index, value }) => {
                        onCategoryAmountChange(index, value);
                      }}
                      memberActionType="add"
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            className="flex w-full justify-between border-t border-t-grey30 py-6"
            role="presentation"
            onClick={() => onClickSectionHeader(SectionHeaders.ADDRESS)}
          >
            <p className="text-lg font-semibold">Address</p>
            <span>
              <Icon
                name="down-icon-dark"
                className={`${
                  openSection === SectionHeaders.ADDRESS ? 'rotate-180' : ''
                }`}
              />
            </span>
          </div>
          {openSection === SectionHeaders.ADDRESS && (
            <MemberAddress
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              country={country}
              state={state}
              city={city}
              pincode={pincode}
              onLine1Change={onLine1Change}
              onLine2Change={onLine2Change}
              onCountryChange={onCountryChange}
              onStateChange={onStateChange}
              onCityChange={onCityChange}
              onPincodeChange={onPincodeChange}
              pincodeError={error.pincode}
              onBlurPincode={() => onBlurPincode(pincode)}
            />
          )}
        </div>

        {/* TODO: button disabled needs to be handled */}
        <div className="mt-2 flex h-auto w-full gap-6">
          {type === AddMemberModalType.ADD && (
            <Button
              label="Save and add another"
              variant="secondary"
              handleClick={onSaveAndAddAnother}
              disabled={isSaveDisabled}
              className="w-full"
            />
          )}
          <Button
            label="Save"
            handleClick={onSave}
            disabled={isSaveDisabled || !hasChanges}
            className={type === AddMemberModalType.EDIT ? 'w-full' : 'w-full'}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddMemberModal;
