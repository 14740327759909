import ProgressBarWithAnimation from '@components/progress-bar/ProgressBarWithAnimation';
import { Tooltip } from '@components';

import { GroupPerfomanceType } from '../../types';
import { formattedValue, getSplitValues } from '../../helper';
import { colorListForGroupPerformance } from '../../constants';
import theme from '../../../../../themes/colors';

interface DashboardHorizontalChartProps {
  groupPerfomanceData?: GroupPerfomanceType[];
}

const DashboardHorizontalChart = ({
  groupPerfomanceData
}: DashboardHorizontalChartProps) => {
  const { maxValue, splitValues } = getSplitValues(groupPerfomanceData);

  const lines = Array(4).fill(null);

  const branchColors: {
    [key: string]: { backgroundColor: string; color: string };
  } = {};

  const getBranchStyle = (branchId: string) => {
    if (branchColors[branchId]) return branchColors[branchId];
    const index = Object.keys(branchColors).length;
    branchColors[branchId] = colorListForGroupPerformance[index % 5];
    return branchColors[branchId];
  };

  const getProgressPerc = (group: GroupPerfomanceType) => {
    const totalAmount =
      parseFloat(group.amount_due) + parseFloat(group.amount_collected);
    if (totalAmount === 0) return 0;
    return (parseFloat(group.amount_collected) * 100) / totalAmount;
  };

  const getTotalPerc = (group: GroupPerfomanceType) => {
    const totalAmount =
      parseFloat(group.amount_due) + parseFloat(group.amount_collected);
    if (maxValue === 0) return 0;
    return (totalAmount * 100) / maxValue;
  };

  return (
    <div className="relative my-6 h-4/5 w-full pb-6">
      {/* Lines */}
      <div className="absolute left-0 top-0 z-10 flex size-full flex-col border-x-2 border-dashed">
        <div className="flex size-full justify-evenly">
          {lines.map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="border-l-2 border-dashed " />
          ))}
        </div>
        <div className="-mx-1 flex h-6 justify-between bg-white pt-2">
          {splitValues.map((value, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="text-sm text-grey">
              {formattedValue(value)}
            </div>
          ))}
        </div>
      </div>
      <div className="flex size-full flex-col justify-between gap-4">
        {groupPerfomanceData ? (
          groupPerfomanceData.map((group) => (
            <div
              key={`group_${group.group_id}`}
              className="z-20 flex flex-col gap-2"
            >
              <div className="flex items-center gap-2 px-1">
                <div className="text-sm text-content">{group.group_name}</div>
                <div
                  className="rounded-full px-2 py-1 text-xs"
                  style={getBranchStyle(group.branch_id)}
                >
                  {group.branch_name}
                </div>
              </div>
              <div>
                <ProgressBarWithAnimation
                  progress={getProgressPerc(group)}
                  bgColor={theme.grey50}
                  progressBarcolor={theme.primary}
                  wrapperStyle=""
                  backgroundProgress={getTotalPerc(group)}
                  tooltipId={`${group.group_id}-progress-info`}
                />
              </div>
              <Tooltip id={`${group.group_id}-progress-info`}>
                <div className="flex min-w-30 flex-col">
                  <div className="flex w-full justify-between gap-4">
                    <div>Collected</div>
                    <div>{group.amount_collected}</div>
                  </div>
                  <div className="flex justify-between gap-4">
                    <div>Due</div>
                    <div>{group.amount_due}</div>
                  </div>
                </div>
              </Tooltip>
            </div>
          ))
        ) : (
          <div>No data</div>
        )}
      </div>
    </div>
  );
};

export default DashboardHorizontalChart;
