import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { DOCS } from '@constants/routes';

const OverviewPage = lazy(
  () => import('@features/docs/pages/overview/Overview')
);
const APIReference = lazy(
  () => import('@features/docs/pages/api-reference/ApiReference')
);
const ApiKeys = lazy(() => import('@features/docs/pages/api-keys/ApiKeys'));
const Webhooks = lazy(() => import('@features/docs/pages/webhooks/Webhooks'));

const routesConfig: { [key: string]: React.ElementType } = {
  [DOCS.OVERVIEW]: OverviewPage,
  [DOCS.API_KEYS]: ApiKeys,
  [DOCS.WEBHOOKS]: Webhooks,
  [DOCS.API_REFERENCE]: APIReference,
  [DOCS.CREATE_PAYMENT_ORDER]: APIReference,
  [DOCS.GET_PAYMENT_ORDER_BY_ORDER_ID]: APIReference,
  [DOCS.GET_PAYMENT_ORDER_BY_REFERENCE_ID]: APIReference,
  [DOCS.CANCEL_PAYMENT_ORDER]: APIReference,
  [DOCS.PAYMENT_ORDER_REQUEST_SCHEMA]: APIReference,
  [DOCS.PAYMENT_ORDER_RESPONSE_SCHEMA]: APIReference,
  [DOCS.ERROR_RESPONSE_SCHEMA]: APIReference
};

const DocsRoutes: RouteObject[] = Object.entries(routesConfig).map(
  ([path, Component]) => ({
    path,
    element: <Component />
  })
);

export default DocsRoutes;
