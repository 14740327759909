import { UserFlow } from '@constants/generic';

// eslint-disable-next-line import/prefer-default-export
export const getOrganizationId = (userFlow: string) => {
  switch (userFlow) {
    case UserFlow.CREATE_ORG:
      return localStorage.getItem('newOrgId') || '';
    case UserFlow.USER_ONBOARD:
      return localStorage.getItem('userOrganizationId') || '';
    case UserFlow.UPGRADE_TO_ORG:
      return localStorage.getItem('upgradedOrgId') || '';
    default:
      return localStorage.getItem('organizationId') || '';
  }
};

export const getBranchId = (userFlow: string) => {
  switch (userFlow) {
    case UserFlow.CREATE_ORG:
      return localStorage.getItem('newBranchId') || '';
    case UserFlow.USER_ONBOARD:
      return localStorage.getItem('userBranchId') || '';
    case UserFlow.UPGRADE_TO_ORG:
      return localStorage.getItem('upgradedBranchId') || '';
    default:
      return localStorage.getItem('branchId') || '';
  }
};
