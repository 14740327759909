export enum FileUploadType {
  DEFAULT = 'default',
  RE_UPLOAD = 're-upload'
}

export interface FileUploadProps {
  handleAdd: (doc: any) => void;
  handleRemove: () => void;
  label: string;
  type?: FileUploadType;
  reason?: string;
  uploadedFile?: File | null;
}

export interface EventDocumentUploadProps extends FileUploadProps {
  allowedFileSize: number;
  allowedFileSizeLabel: string;
  allowedDocumentTypes: string[];
  acceptedDocInputTypes: string;
  subText?: string;
  onError: (error: string) => void;
  uploadedFileUrl?: string;
}
