import React, { useCallback, useEffect, useRef } from 'react';

import Spinner from '../spinner/Spinner';

interface InfiniteScrollProps {
  handleScroll: () => void;
  isDisable: boolean;
  isLoading: boolean;
  targetElement?: HTMLElement | null;
  spinnerStyle?: string;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  handleScroll,
  isDisable,
  isLoading,
  targetElement,
  spinnerStyle
}) => {
  const loaderRef = useRef(null);
  const handleObserver = useCallback((entries: any) => {
    const target = entries[0];
    if (target.isIntersecting && target.intersectionRatio > 0) {
      handleScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 1
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (targetElement && !isDisable) {
      observer.observe(targetElement);
    } else if (loaderRef.current) observer.observe(loaderRef.current);
    return () => {
      if (targetElement && !isDisable) {
        observer.unobserve(targetElement);
      } else if (loaderRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(loaderRef.current);
      }
    };
  }, [handleObserver, isDisable, targetElement]);

  return isDisable ? null : (
    <div ref={loaderRef} className="mt-4 flex justify-center">
      {isLoading && <Spinner className={spinnerStyle} />}
    </div>
  );
};

export default InfiniteScroll;
