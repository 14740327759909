import { useEffect, useState } from 'react';

import { EmptyScreen, Spinner } from '@components';
import { getLocalStorageItem } from '@utils/storage';
import { NoResultPaymentGraph } from '@images';

import GroupPerfomancePagination from './GroupPerfomancePagination';
import DashboardHorizontalChart from './dashboard-horizontal-chart/DashboardHorizontalChart';
import { GroupPerfomancePageLimit } from '../constants';
import { DateRangeType } from '../types';
import { useLazyGetGroupPerfomanceQuery } from '../queries';
import { getFormattedDateForChartAPI } from '../helper';

interface GroupPerfomanceProps {
  selectedDateRange: DateRangeType;
  selectedBranchId: string;
}

const GroupPerfomance = ({
  selectedDateRange,
  selectedBranchId
}: GroupPerfomanceProps) => {
  const organizationId = getLocalStorageItem('organizationId');

  const [page, setPage] = useState<number>(1);

  const [
    getGroupPerfomance,
    { data: groupPerfomance, isLoading, isFetching, isUninitialized }
  ] = useLazyGetGroupPerfomanceQuery();

  const onClickNext = () => {
    setPage(page + 1);
  };

  const onClickPrev = () => {
    setPage(page - 1);
  };

  const totalGroups = groupPerfomance?.pagination?.total_rows || 0;

  const getGroupPerfomanceData = async () => {
    const { startDate, endDate } =
      getFormattedDateForChartAPI(selectedDateRange);

    await getGroupPerfomance({
      organizationId,
      queryString: `?from_timestamp=${startDate}&to_timestamp=${endDate}&branch_id=${selectedBranchId}&page=${
        page - 1
      }&limit=${GroupPerfomancePageLimit}`
    });
  };

  useEffect(() => {
    getGroupPerfomanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange, page]);

  useEffect(() => {
    if (page !== 1) setPage(1);
    else getGroupPerfomanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBranchId]);

  const renderGroupPerformanceChart = () => {
    if (isLoading || isFetching || isUninitialized)
      return (
        <div className="flex h-90 items-center justify-center">
          <Spinner />
        </div>
      );
    if (groupPerfomance?.data === null)
      return (
        <div className="h-90">
          <EmptyScreen
            title="No data to show !"
            description=""
            emptyScreenImage={NoResultPaymentGraph}
          />
        </div>
      );
    return (
      <DashboardHorizontalChart groupPerfomanceData={groupPerfomance?.data} />
    );
  };

  return (
    <div className="w-full rounded-lg bg-white p-6 shadow-md lg:w-2/5">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="text-lg font-bold text-content">Group Perfomance</div>
          {/* <InfoIconTooltip
            id="group-perfomance"
            tooltipText="Group Perfomance Tooltip"
          /> */}
        </div>
        {groupPerfomance?.data !== null && (
          <GroupPerfomancePagination
            page={page}
            totalGroups={totalGroups}
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
          />
        )}
      </div>
      {renderGroupPerformanceChart()}
    </div>
  );
};

export default GroupPerfomance;
