import { useEffect, useState } from 'react';

import { DateRangePicker, Modal } from '@components';
import { DatePickerType } from '@constants/groups';
import {
  customDateRangeMinDaysDiff,
  customDefaultDateRange,
  DashboardDateRange,
  DashboardDateRangePickerTypes,
  DashboardDateRangePickerTypesEnum
} from '@features/dashboard/constants';
import { getSelectedDateRange } from '@features/dashboard/helper';
import { DateRange } from '@types';
import {
  formatISODate,
  getLastYearDate,
  getStartAndEndDateOfLastMonth,
  getStartAndPresentDateOfCurrentMonth
} from '@utils/date';

interface DashboardDateRangePickerProps {
  handleDateRangeSelect: (
    data: DashboardDateRange,
    dateRangeType: DashboardDateRangePickerTypesEnum
  ) => void;
}

const DashboardDateRangePicker = ({
  handleDateRangeSelect
}: DashboardDateRangePickerProps) => {
  const { startDate, endDate } = getStartAndPresentDateOfCurrentMonth();

  const [selectedDateRangeType, setSelectedDateRangeType] =
    useState<DashboardDateRangePickerTypesEnum>(
      DashboardDateRangePickerTypes[0].key
    );
  const [showCustomDateModal, setShowCustomDateModal] =
    useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({
    startDate: new Date(startDate),
    endDate: new Date(endDate)
  });

  const handleDateOptionClick = (
    dateRangeType: DashboardDateRangePickerTypesEnum
  ) => {
    if (dateRangeType === selectedDateRangeType) return;

    setSelectedDateRangeType(dateRangeType);
    if (dateRangeType === DashboardDateRangePickerTypesEnum.thisMonth) {
      setSelectedDateRange(getStartAndPresentDateOfCurrentMonth());
      handleDateRangeSelect(
        getSelectedDateRange(getStartAndPresentDateOfCurrentMonth()),
        dateRangeType
      );
    } else if (dateRangeType === DashboardDateRangePickerTypesEnum.lastMonth) {
      setSelectedDateRange(getStartAndEndDateOfLastMonth());
      handleDateRangeSelect(
        getSelectedDateRange(getStartAndEndDateOfLastMonth()),
        dateRangeType
      );
    } else if (dateRangeType === DashboardDateRangePickerTypesEnum.custom) {
      setSelectedDateRange(customDefaultDateRange);
      handleDateRangeSelect(
        getSelectedDateRange(customDefaultDateRange),
        dateRangeType
      );
    }
  };

  const displayStartDate = formatISODate({
    date: selectedDateRange.startDate,
    format: 'dd-mm-yyyy'
  });

  const displayEndDate = formatISODate({
    date: selectedDateRange.endDate,
    format: 'dd-mm-yyyy'
  });

  const onClickDisplayDateRange = () => {
    handleDateOptionClick(DashboardDateRangePickerTypesEnum.custom);
    setShowCustomDateModal(true);
  };

  useEffect(() => {
    handleDateOptionClick(DashboardDateRangePickerTypesEnum.thisMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-wrap gap-4 lg:flex-nowrap">
      <div className="flex w-fit gap-1 rounded-lg border border-grey30 bg-grey10 p-1">
        {DashboardDateRangePickerTypes.map((item) => (
          <div
            className={`cursor-pointer rounded-lg px-4 py-3 text-sm ${
              selectedDateRangeType === item.key
                ? 'border border-grey30 bg-white font-medium'
                : 'font-normal text-grey'
            }`}
            key={item.key}
            onClick={() => handleDateOptionClick(item.key)}
            role="presentation"
          >
            {item.label}
          </div>
        ))}
      </div>
      <div
        className={`flex w-fit min-w-55 cursor-pointer items-center rounded-lg border border-grey30 px-3 ${
          selectedDateRangeType === DashboardDateRangePickerTypesEnum.custom
            ? 'bg-white'
            : 'bg-grey10 text-bgGrey50'
        }`}
        onClick={onClickDisplayDateRange}
        role="presentation"
      >{`${displayStartDate} - ${displayEndDate}`}</div>
      {showCustomDateModal && (
        <Modal
          isOpen={showCustomDateModal}
          onCancel={() => setShowCustomDateModal(false)}
        >
          <DateRangePicker
            title="Select Date Range"
            cancelButtonLabel="Cancel"
            confirmButtonLabel="Apply"
            handleConfirm={(dateRange) => {
              setShowCustomDateModal(false);
              setSelectedDateRange(dateRange);
              handleDateRangeSelect(
                getSelectedDateRange(dateRange),
                DashboardDateRangePickerTypesEnum.custom
              );
            }}
            handleCancel={() => setShowCustomDateModal(false)}
            defaultDatePickerType={DatePickerType.CUSTOM}
            showPicker={false}
            defaultDateRange={selectedDateRange}
            minimumDateDifference={customDateRangeMinDaysDiff}
            minDate={getLastYearDate()}
          />
        </Modal>
      )}
    </div>
  );
};

export default DashboardDateRangePicker;
