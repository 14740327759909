import { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NoResultPaymentGraph } from '@images';

import {
  EmptyScreen,
  LineChart,
  MulitSelectDropdownWithApplyButton,
  Spinner
} from '@components';
import { AccountType } from '@constants/generic';
import { Event, sendMixpanelEvent } from '@core/analytics';
import { useLazyGetAllBranchesQuery } from '@features/branch-management/queries';
import { Branch } from '@types';
import { getLocalStorageItem } from '@utils/storage';
import { DropdownOption } from 'types/dropdown';

import { allBranches, customBranchOption } from '../constants';
import {
  formatBranchRevenueChartData,
  getFormattedDateForChartAPI
} from '../helper';
import { useLazyGetBranchRevenueQuery } from '../queries';
import { DateRangeType } from '../types';

interface BranchRevenueChartProps {
  selectedBranch: string;
  selectedDateRange: DateRangeType;
  selectedBranchId: string;
}

const BranchRevenueChart = ({
  selectedBranch,
  selectedDateRange,
  selectedBranchId
}: BranchRevenueChartProps) => {
  const organizationId = getLocalStorageItem('organizationId');
  const { accountType } = useSelector((state: any) => state.user);

  const [branchesList, setBranchesList] = useState<Branch[]>([]);
  const [selectedBranchesOption, setSelectedBranchesOptions] = useState<
    DropdownOption[]
  >([customBranchOption]);

  const [getAllBranches] = useLazyGetAllBranchesQuery();
  const [
    getBranchRevenue,
    { data: branchRevenueData, isLoading, isUninitialized, isFetching }
  ] = useLazyGetBranchRevenueQuery();

  const isBranchRevenueDataLoading = isLoading || isFetching || isUninitialized;

  const branchListOptions = branchesList?.map((branch: Branch) => ({
    id: branch.id,
    value: branch.name,
    label: branch.name
  }));

  const handleGetAllBranches = async () => {
    const res = await getAllBranches({
      organizationId,
      queryString: ''
    });
    if (res.data) {
      setBranchesList(res.data.data);
    }
  };

  const isAllBranch = selectedBranch === allBranches.value;

  const startDateString = selectedDateRange.startDate;
  const endDateString = selectedDateRange.endDate;

  const { dateArray, series } = formatBranchRevenueChartData(
    startDateString,
    endDateString,
    branchRevenueData || [],
    isAllBranch
  );

  const getSelectedBranch = () => {
    if (isAllBranch) {
      // if selected branch is custom option (top 5 branches)
      if (
        selectedBranchesOption.length === 1 &&
        selectedBranchesOption[0].id === customBranchOption.id
      )
        return '';
      return selectedBranchesOption.map((item) => item.id).join(',');
    }
    // if single branch selected do not show branch selection dropdown
    // for branch revenue graph
    return selectedBranchId;
  };

  const getBranchRevenueData = () => {
    const { startDate, endDate } =
      getFormattedDateForChartAPI(selectedDateRange);

    const branchId = getSelectedBranch();
    getBranchRevenue({
      organizationId,
      queryString: `?from_timestamp=${startDate}&to_timestamp=${endDate}&branch_id=${branchId}`
    });
  };

  useEffect(() => {
    getBranchRevenueData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange, selectedBranchesOption, selectedBranchId]);

  useEffect(() => {
    if (accountType.type === AccountType.ORGANIZATION) handleGetAllBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendMixpanelEvent(Event.DASHBOARD_SELECTED_BRANCH_FOR_BRANCH_REVENUE, {
      selectedBranchIds: selectedBranchesOption?.map((item) => item.id)
    });
  }, [selectedBranchesOption]);

  useEffect(() => {
    if (!(accountType.type === AccountType.ORGANIZATION && isAllBranch)) {
      setSelectedBranchesOptions([customBranchOption]);
    }
  }, [accountType.type, isAllBranch]);

  const renderBranchRevenueChart = () => {
    if (isBranchRevenueDataLoading)
      return (
        <div className="flex h-90 items-center justify-center">
          <Spinner />
        </div>
      );
    if (branchRevenueData === null)
      return (
        <div className="h-90">
          <EmptyScreen
            title="No data to show !"
            description=""
            emptyScreenImage={NoResultPaymentGraph}
          />
        </div>
      );
    return (
      <Suspense
        fallback={
          <div className="flex h-90 items-center justify-center">
            <Spinner />
          </div>
        }
      >
        <LineChart
          title=""
          className="w-full"
          xAxisValues={dateArray}
          seriesData={series}
          branchName={isAllBranch ? '' : selectedBranch}
          startDate={startDateString}
          endDate={endDateString}
        />
      </Suspense>
    );
  };

  return (
    <div className="w-full rounded-lg bg-white p-6 shadow-md lg:w-3/5">
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-lg font-bold text-content">Branch Revenue</h1>
          {/* <InfoIconTooltip
            id="branch-revenue"
            tooltipText="Branch Revenue Tooltip"
          /> */}
        </div>
        {accountType.type === AccountType.ORGANIZATION && isAllBranch && (
          <MulitSelectDropdownWithApplyButton
            label="Select branch"
            options={branchListOptions}
            selectedOptions={selectedBranchesOption}
            onApply={(elements) => setSelectedBranchesOptions(elements)}
            className="!w-1/2"
            chipCloseRequired={false}
            plusMoreClickRequired={false}
            minSelectOptionsCount={1}
            maxSelectOptionsCount={5}
            customOptionLabel="Top 5 branches"
            customOption={customBranchOption}
          />
        )}
      </div>
      {renderBranchRevenueChart()}
    </div>
  );
};

export default BranchRevenueChart;
