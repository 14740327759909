import { BankInfoType, VerifiedBankInfoType } from './types';

export const BankInfoDefaultValue: BankInfoType = {
  accountNumber: '',
  confirmAccountNumber: '',
  ifsc: ''
};

export const VerifiedBankInfoDefaultValue: VerifiedBankInfoType = {
  bankName: '',
  userName: ''
};

export const PayerCountChipValues = [50, 150, 200, 500];
