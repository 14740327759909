import { RefObject, useEffect, useRef, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

import Icon from '@components/icon/Icon';
import Tooltip from '@components/tooltip/Tooltip';
import testId from '@constants/testId';
import { Event, sendMixpanelEvent } from '@core/analytics';
import { ActionsProps } from '@features/transactions/types';
import { downloadQRCode } from '@utils/downloadFile';
import useOnClickOutside from '@utils/hooks';

import theme from '../../../themes/colors';

interface ThreeDotActionButtonProps {
  id: string;
  actions?: ActionsProps[];
  isDisabled?: boolean;
  onToggle?: (isOpen: boolean) => void;
  source?: string;
  stroke?: string;
}

const disableButtonStyle =
  '!text-grey opacity-50 cursor-not-allowed pointer-events-none';

const ThreeDotActionButton = ({
  id,
  actions,
  isDisabled,
  onToggle,
  source,
  stroke = theme.grey
}: ThreeDotActionButtonProps) => {
  const ref = useRef(null);
  const canvasRef = useRef<HTMLDivElement>(null);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [tooltipText, setTooltipText] = useState<string>('');
  const [openAbove, setOpenAbove] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent) => {
    if (isDisabled) return;
    event.stopPropagation();

    const buttonRect = (event.target as HTMLElement).getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    if (viewportHeight - buttonRect.bottom < 250) {
      setOpenAbove(true);
    } else {
      setOpenAbove(false);
    }

    setModalOpen((prev) => !prev);
  };

  useOnClickOutside(ref, (e) => {
    setModalOpen(false);
    e.stopPropagation();
  });

  useEffect(() => {
    onToggle?.(modalOpen);
  }, [onToggle, modalOpen]);

  useEffect(() => {
    setTimeout(() => {
      if (tooltipText !== '') setTooltipText('');
    }, 1000);
  }, [tooltipText]);

  return (
    <div
      className="relative max-w-8"
      ref={ref}
      data-testid={testId.transactionsPage.overflowMenu}
    >
      <div
        role="presentation"
        key={id}
        onClick={(event) => handleClick(event)}
        className={`p-2 ${isDisabled ? 'cursor-not-allowed' : ''}`}
      >
        <Icon
          name="three-dots-grey"
          stroke={stroke}
          className={isDisabled ? 'opacity-30' : ''}
        />
      </div>

      {modalOpen && (
        <div
          className={`absolute right-4 ${
            openAbove ? 'bottom-8' : 'top-8'
          } z-50 w-55 rounded-lg bg-white shadow-md`}
        >
          {actions?.map((action, index) => {
            if (action.onClick || action.qrLink)
              return (
                <div
                  role="presentation"
                  className={`relative flex gap-2 p-3 hover:bg-gray-50 ${
                    index === 0 ? 'rounded-t-lg' : ''
                  } ${action.isDisabled ? `${disableButtonStyle}` : ''} ${
                    action.style
                  } ${
                    index + 1 !== actions.length
                      ? 'border-b border-grey40'
                      : 'rounded-b-lg'
                  }`}
                  onClick={(event) => {
                    if (action.tooltipText) setTooltipText(action.tooltipText);
                    if (action.downloadQrCode) {
                      if (source)
                        sendMixpanelEvent(
                          Event.DOWNLOAD_QR_CODE_IN_EVENTS_CARD
                        );
                      event.stopPropagation();
                      downloadQRCode(
                        canvasRef as RefObject<HTMLDivElement>,
                        'qr code'
                      );
                    }
                    if (!action.isDisabled) action.onClick?.(event);
                  }}
                  data-tooltip-id={`copy-tooltip-${action.id}`}
                  key={action.id}
                >
                  {action.label}
                  {action.iconName && (
                    <Icon name={action.iconName} stroke={theme.primary} />
                  )}
                  {tooltipText && action.tooltipText && (
                    <Tooltip
                      id={`copy-tooltip-${action.id}`}
                      type="text"
                      showTooltip={tooltipText !== ''}
                      tooltipText="Copied!"
                    />
                  )}
                  {action.qrLink && (
                    <div ref={canvasRef} className="hidden">
                      <QRCodeCanvas
                        value={action.qrLink}
                        bgColor="white"
                        fgColor="black"
                        marginSize={4}
                      />
                    </div>
                  )}
                </div>
              );

            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default ThreeDotActionButton;
