import { KeyboardEvent } from 'react';
import { RupeeIcon } from '@icons';
import clsx from 'clsx';

import testId from '@constants/testId';
import { sanitizeNumberInput } from '@utils/generic';
import { AmountInputProps } from 'types/input';

import { inputStyles, sizeStyle } from '../input/input.config';

const AmountInput = (props: AmountInputProps) => {
  const {
    className,
    name,
    type = 'text',
    inputClassNames,
    inputSize = 'medium',
    value,
    disabled,
    placeholder = '0',
    onAmountChange,
    maxAmount,
    error,
    errorMessage,
    handleInputBlur,
    allowZero,
    onClick,
    allowDecimals = false,
    ...rest
  } = props;

  const handleOnClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    // Prevent the default behavior for arrow keys
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };

  const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    // Prevent value incrementing / decrementing on scroll
    e.preventDefault();
    e.currentTarget.blur();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    // Determine allowed pattern based on the allowDecimals prop
    const regex = allowDecimals ? /^[0-9]*\.?[0-9]{0,2}$/ : /^[0-9]*$/;

    const inputValue = event.target.value;

    if (!regex.test(inputValue)) {
      return;
    }

    const amount = sanitizeNumberInput(inputValue, allowDecimals);
    let formattedAmount = amount;

    if (
      !allowZero &&
      amount !== '' &&
      (Number(formattedAmount) <= 0 ||
        Number(formattedAmount) > Number(maxAmount))
    ) {
      formattedAmount = '';
      return;
    }

    formattedAmount = amount;
    onAmountChange(formattedAmount);
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (handleInputBlur) {
      const inputValue = e.target.value.trim();
      handleInputBlur(inputValue);
    }
  };

  return (
    <div
      className={`${
        inputStyles.common
      } relative flex items-center rounded-lg px-4 py-2.5 ${
        error ? `${inputStyles.error}` : 'hover:focus-within:border-primary'
      } ${sizeStyle[inputSize]} ${className}`}
      onClick={onClick}
      onKeyDown={() => null}
      role="presentation"
    >
      <div className="flex h-full items-center justify-center ">
        <RupeeIcon className="h-5 w-3.5" />
      </div>
      <div className="h-9 border border-transparent border-r-grey40 pr-3.5" />
      <input
        id={name}
        name={name}
        type={type}
        className={clsx(
          inputClassNames,
          inputStyles,
          'no-spinners w-full bg-transparent pl-3 focus:outline-none'
        )}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onWheel={handleWheel}
        onClick={onClick || handleOnClick}
        onChange={handleInputChange}
        onBlur={onInputBlur}
        {...rest}
      />
      {error && errorMessage && (
        <div
          className="absolute -bottom-5 left-0 flex items-center text-xs text-error"
          data-testid={testId.amountInput.errorMessage}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default AmountInput;
