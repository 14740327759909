import {
  ApiKeysOverview,
  CancelPaymentOrderOverview,
  CreatePaymentOrderOverview,
  GetPaymentByOrderIdOverview,
  GetPaymentByReferenceIdOverview,
  WebhooksOverview
} from '@images';

import { DOCS } from '@constants/routes';

export const coFeeApiDetails = [
  {
    id: 'create-payment-order',
    title: 'Create a Payment Order',
    desc: 'This endpoint is used to create a new payment order. A merchant initiates a payment request by providing order details, customer information, and optional notification preferences.',
    route: DOCS.CREATE_PAYMENT_ORDER,
    imageSrc: CreatePaymentOrderOverview
  },
  {
    id: 'get-payment-order-by-id',
    title: 'Get Payment by Order ID',
    desc: 'Retrieves the details of a payment order using its unique cofee order-id. Can be used  to check the payment status of a previously created order.',
    route: DOCS.GET_PAYMENT_ORDER_BY_ORDER_ID,
    imageSrc: GetPaymentByOrderIdOverview
  },
  {
    id: 'get-payment-order-by-reference-id',
    title: 'Get Payment by Reference ID',
    desc: 'Fetches an order based on a unique reference-id assigned by the merchant. Can be used  to retrieve an order using an internal reference.',
    route: DOCS.GET_PAYMENT_ORDER_BY_REFERENCE_ID,
    imageSrc: GetPaymentByReferenceIdOverview
  },
  {
    id: 'cancel-payment-order',
    title: 'Cancel a Payment Order',
    desc: 'Cancels a pending payment order. If the order has already been completed, it cannot be canceled',
    route: DOCS.CANCEL_PAYMENT_ORDER,
    imageSrc: CancelPaymentOrderOverview
  }
];

export const coFeeConfigurationDetails = [
  {
    id: 'api-keys',
    title: 'API Keys',
    desc: 'API keys are used to authenticate requests to the CoFee API. They are unique to each merchant and are used to identify the merchant making the request.',
    route: DOCS.API_KEYS,
    imageSrc: ApiKeysOverview
  },
  {
    id: 'webhooks',
    title: 'Webhooks',
    desc: 'Webhooks are used to receive real-time notifications of events that occur in the CoFee system. Merchants can configure webhooks to receive notifications for specific events.',
    route: DOCS.WEBHOOKS,
    imageSrc: WebhooksOverview
  }
];

export const jsonDataWebhook = `{
  event_name: 'payment-order.paid',
  data: {
    amount: '3',
    created_at: '2025-02-18T09:28:52.248971Z',
    currency: 'INR',
    customer_details: {
      mobile: '9876543210',
      name: 'John Doe'
    },
    merchant_order_id: 'ord_BKBWYKFcc88511',
    order_id: 'ord_BKBWYKFccxxxxx',
    order_purpose: 'demo',
    order_status: 'processing', // possible values: processing, success
    payment_mode: 'online' // possible values: online, offline
  }
}`;

export enum CoFeeApiDetails {
  POST = 'POST',
  GET = 'GET',
  PATCH = 'PATCH'
}

export const apiMethodColorMapper: Record<CoFeeApiDetails, string> = {
  [CoFeeApiDetails.POST]: 'bg-[#186FAF]',
  [CoFeeApiDetails.GET]: 'bg-[#2F8132]',
  [CoFeeApiDetails.PATCH]: 'bg-[#BF581D]'
};
