import { useEffect, useState } from 'react';

import Icon from '@components/icon/Icon';
import RadioGroup from '@components/radio-button/RadioGroup';
import { PaymentCollectionType } from '@features/group-management/constants';
import { CardProps } from '@types';
import { getOrdinalDateString } from '@utils/date';

import { monthCardOptions, MonthDay } from '../constants';

import theme from '../../../../themes/colors';

const TermCard = ({
  handleActivationDate,
  handleIntervalFrequency,
  selectedDate,
  selectedFrequency,
  type,
  isTermDisabled
}: // nextDueDate
CardProps) => {
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>();
  const [selectedTerm, setSelectedTerm] = useState<number>(2);
  const [isDayPickerVisible, setIsDayPickerVisible] = useState<boolean>(false);

  const handleDayClick = (item: string) => {
    setSelectedDay(item);
    // const dueDate = handleTermDateSelect({
    //   nextDueDate,
    //   item
    // });
    // handleActivationDate(new Date(dueDate));
    handleActivationDate(item);
  };

  const handleTypeSelect = (item: string) => {
    setSelectedType(item);
    if (item !== MonthDay.CUSTOM) {
      setIsDayPickerVisible(false);
      setSelectedDay('');
      handleActivationDate('1');
    }
    if (item === MonthDay.LAST) {
      // const isDueMonth =
      //   new Date(nextDueDate).getMonth() === new Date().getMonth();
      // const nextDueMonth = isDueMonth
      //   ? new Date(nextDueDate).getMonth()
      //   : new Date().getMonth();
      // const lastDay = getLastDayOfMonthUTC(nextDueMonth);
      // const lastDayDate = lastDay.getUTCDate();
      // const dueDate = handleTermDateSelect({
      //   nextDueDate,
      //   item: lastDayDate.toString()
      // });
      // handleActivationDate(new Date(dueDate));
      handleActivationDate('last_day');
    }
    if (item === MonthDay.FIRST) {
      // const dueDate = handleTermDateSelect({
      //   nextDueDate,
      //   item: '1'
      // });
      // handleActivationDate(new Date(dueDate));
      handleActivationDate('first_day');
    }
    if (item === MonthDay.CUSTOM && selectedDay === '') {
      setSelectedDay('1');
      setIsDayPickerVisible(true);
      handleActivationDate('1');
    }
  };

  const handleAdd = () => {
    if (selectedTerm <= 11) setSelectedTerm((prev) => prev + 1);
  };

  const handleMinus = () => {
    if (selectedTerm >= 3) setSelectedTerm((prev) => prev - 1);
  };

  const handleBack = () => {
    if (selectedDay === '') setSelectedType(MonthDay.FIRST);
    setIsDayPickerVisible(false);
  };

  useEffect(() => {
    handleIntervalFrequency(String(selectedTerm));
    if (selectedDate) {
      handleActivationDate(selectedDate as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTerm]);

  useEffect(() => {
    if (!selectedDate || type !== PaymentCollectionType.BY_TERM) {
      // const dueDate = handleTermDateSelect({ nextDueDate, item: '1' });
      // handleActivationDate(new Date(dueDate));
      setSelectedType(MonthDay.FIRST);
      handleActivationDate('first_day');
    } else if (selectedDate && type === PaymentCollectionType.BY_TERM) {
      // const dayType = getDayType(selectedDate);
      // setSelectedType(dayType);
      if (selectedDate === 'first_day') {
        setSelectedType(MonthDay.FIRST);
        handleActivationDate('first_day');
      } else if (selectedDate === 'last_day') {
        setSelectedType(MonthDay.LAST);
        handleActivationDate('last_day');
      } else {
        setSelectedType(MonthDay.CUSTOM);
        setIsDayPickerVisible(true);
        handleActivationDate(selectedDate as string);
        setSelectedDay(selectedDate as string);
      }
      // if (dayType === MonthDay.CUSTOM) {
      //   const day = new Date(selectedDate).getDate();
      //   setSelectedDay(String(day));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    if (!selectedFrequency || selectedFrequency <= 1) setSelectedTerm(2);
    else setSelectedTerm(Number(selectedFrequency));
  }, [selectedFrequency]);

  const renderCustomDayPicker = () => (
    <>
      <div className="mb-4 mt-2 flex w-full gap-1.5 text-sm font-normal">
        <Icon name="back" className="cursor-pointer" onClick={handleBack} />
        <div>Select custom day</div>
      </div>
      <div className="grid grid-cols-7 gap-2 pb-6 pt-1">
        {Array.from({ length: 28 }, (_, i) => (
          <div
            key={i}
            className={`flex size-8 cursor-pointer items-center justify-center rounded-full text-grey ${
              Number(selectedDay) === i + 1 ? 'bg-primary text-theme' : ''
            }`}
            role="presentation"
            onClick={() => {
              handleDayClick(String(i + 1));
            }}
          >
            {i + 1}
          </div>
        ))}
      </div>
    </>
  );

  const renderFirstLastOrCustomOptions = () => (
    <>
      <RadioGroup
        options={monthCardOptions}
        handleChange={handleTypeSelect}
        orientation="vertical"
        selectedValue={selectedType}
      />
      {!!selectedDay && (
        <div className="-mt-4 mb-4 ml-36 flex w-full items-center gap-2 text-sm text-grey">
          <div>{`(${getOrdinalDateString(selectedDay)} of the month)`}</div>
          <Icon
            className="size-3 cursor-pointer"
            name="edit"
            stroke={theme.primary}
            size="none"
            onClick={() => setIsDayPickerVisible(true)}
          />
        </div>
      )}
    </>
  );

  return (
    <div className="mb-3 flex flex-col items-center justify-center">
      {!isTermDisabled && (
        <div className="mb-4 flex items-center justify-center gap-4">
          <Icon
            name="minus"
            stroke={selectedTerm > 2 ? theme.primary : theme.grey}
            onClick={handleMinus}
            size="none"
            className="size-6 cursor-pointer"
          />
          <div className="flex h-13.5 w-36 items-center justify-center rounded-xl bg-grey10 text-32">
            {selectedTerm}
          </div>
          <Icon
            name="plus"
            stroke={selectedTerm < 12 ? theme.primary : theme.grey}
            onClick={handleAdd}
            size="none"
            className="size-6 cursor-pointer"
          />
        </div>
      )}
      {!isTermDisabled && (
        <div className="mb-6 flex items-center justify-center gap-2">
          {[3, 6, 12].map((item) => (
            <div
              key={item}
              className={`${
                selectedTerm === item
                  ? 'border-primary text-primary'
                  : 'border-grey10 text-grey'
              } cursor-pointer rounded-lg border bg-grey10 px-2 py-1.5 text-sm `}
              role="presentation"
              onClick={() => setSelectedTerm(item)}
            >
              {`${String(item)} months`}
            </div>
          ))}
        </div>
      )}
      {isDayPickerVisible
        ? renderCustomDayPicker()
        : renderFirstLastOrCustomOptions()}
    </div>
  );
};

export default TermCard;
