import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { ORG_DASHBOARD, ORG_SETTINGS } from '@constants/routes';
import OrgDashboard from '@features/dashboard/pages/OrgDashboard';

const SettingsHome = lazy(
  () => import('@features/settings/pages/SettingsHome')
);

export const OrgRoutes: RouteObject[] = [
  {
    path: ORG_SETTINGS,
    element: <SettingsHome />
  },
  {
    path: ORG_DASHBOARD,
    element: <OrgDashboard />
  }
];

export default OrgRoutes;
