import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DropdownWithAction } from '@components';
import { AccountType } from '@constants/generic';
import UserPermissionTypes from '@constants/permissions';
import testId from '@constants/testId';
import { useLazyGetAllBranchesQuery } from '@features/branch-management/queries';
import useCheckPermission from '@hooks/checkPermission';
import { Branch } from '@types';
import { getLocalStorageItem, setLocalStorageItem } from '@utils/storage';

interface BranchSelectionProps {
  onBranchChange: (branchId: string, branchName: string) => void;
  initialBranchName: string;
  handleAddNewBranch: () => void;
}

const BranchSelection = ({
  onBranchChange,
  initialBranchName,
  handleAddNewBranch
}: BranchSelectionProps) => {
  const { currentOrg, accountType } = useSelector((state: any) => state.user);

  const organizationId = getLocalStorageItem('organizationId');
  const branchName = initialBranchName || currentOrg?.default_branch_name;

  const [branchesList, setBranchesList] = useState<Branch[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>(branchName);
  const { checkPermission } = useCheckPermission();

  const hasCreateBranchAccess = checkPermission([
    UserPermissionTypes.BRANCH_CREATE
  ]);

  const [
    getAllBranches,
    { isLoading: isLoadingBranches, isFetching: isFetchingBranches }
  ] = useLazyGetAllBranchesQuery();

  const handleGetAllBranches = async () => {
    const res = await getAllBranches({
      organizationId
    });
    if (res?.data) {
      setBranchesList(res.data?.data);
      if (res.data.data.length === 1) {
        setSelectedBranch(res.data.data[0].name);
        setLocalStorageItem('branchName', res.data.data[0].name);
      }
    }
  };

  const handleBranchChange = (branch: string) => {
    setSelectedBranch(branch);
    const selectedBranchId = branchesList?.find(
      (b: Branch) => b.name === branch
    )?.id;
    if (selectedBranchId) onBranchChange(selectedBranchId, branch);
  };

  const branchListOptions = branchesList?.map((branch: Branch) => ({
    id: branch.id,
    value: branch.name,
    label: branch.name
  }));

  useEffect(() => {
    if (
      accountType.type === AccountType.ORGANIZATION &&
      checkPermission([UserPermissionTypes.BRANCH_VIEW])
    ) {
      handleGetAllBranches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType.type, initialBranchName]);

  useEffect(() => {
    if (branchName) setSelectedBranch(branchName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchName]);

  return (
    <div className="flex justify-end">
      {accountType.type === AccountType.ORGANIZATION &&
        (branchListOptions.length > 1 || hasCreateBranchAccess ? (
          <DropdownWithAction
            dropdownId={testId.transactionsPage.filterDropdown}
            currentFilter={selectedBranch}
            filterOptions={branchListOptions}
            onSelectFilter={handleBranchChange}
            optionsStyle="left-0 !min-w-52 !max-w-52"
            className="w-full !min-w-52 !max-w-52 rounded-md !border !border-darkGrey !bg-bgGrey"
            labelStyle="text-theme font-bold"
            isLoading={isLoadingBranches || isFetchingBranches}
            loadingStyles="w-42"
            actionLabel={hasCreateBranchAccess ? 'New branch' : ''}
            actionOnClick={handleAddNewBranch}
            listId="branch"
          />
        ) : (
          <div className="flex items-center gap-2">
            <div className="min-w-48 rounded-lg border border-darkGrey bg-bgGrey px-4 py-2.5 text-sm font-bold text-theme ">
              {selectedBranch}
            </div>
          </div>
        ))}
    </div>
  );
};

export default BranchSelection;
