import { useState } from 'react';

import DatePicker from '@components/date-picker/DatePicker';
import Input from '@components/input/Input';
import Label from '@components/label/Label';
import { formatISODate } from '@utils/date';

interface MemberStartDateProps {
  startDate: string;
  onStartDateChange: (date: string) => void;
  onClearStartDate: () => void;
}

const MemberStartDate = ({
  startDate,
  onStartDateChange,
  onClearStartDate
}: MemberStartDateProps) => {
  const [isStartDatePickerVisible, setIsStartDatePickerVisible] =
    useState<boolean>(false);

  return (
    <div className="gap-4">
      <Label
        label="Start Date"
        tooltipText="If not given, the start date of group will be taken into account"
        tooltipId="startDate"
      />
      <Input
        readOnly
        leftIconName="calendar"
        value={startDate ? formatISODate({ date: startDate }) : ''}
        onClick={() => {
          setIsStartDatePickerVisible(true);
        }}
        placeholder="Select payment start day"
        onClearIconAction={onClearStartDate}
      />

      {isStartDatePickerVisible && (
        <div
          className="fixed left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 rounded-lg border bg-theme shadow-lg"
          id="start-date-calendar"
        >
          <DatePicker
            confirmButtonLabel="Apply"
            confirmButtonStyle="text-primary"
            handleConfirm={(date: string) => {
              onStartDateChange(date);
              setIsStartDatePickerVisible(false);
            }}
            cancelButtonLabel="Cancel"
            handleCancel={() => {
              setIsStartDatePickerVisible(false);
            }}
            selectedDate={(startDate && new Date(startDate)) || new Date()}
            calendarStyle="!bg-bgBase"
            title="Select Start Date"
          />
        </div>
      )}
    </div>
  );
};

export default MemberStartDate;
