import { END_POINTS } from '@config';
import { api } from '@core/baseApi';
import { HTTPResponse } from '@types';

const extendedBaseQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    getBranchRevenue: builder.query<
      HTTPResponse<any>,
      { organizationId: string; queryString: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.DASHBOARD.GET_BRANCH_REVENUE(req.organizationId)}${
          req.queryString || ''
        }`,
        method: 'GET'
      })
    }),
    getGroupPerfomance: builder.query<
      HTTPResponse<any>,
      { organizationId: string; queryString: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.DASHBOARD.GET_GROUP_PERFOMANCE(req.organizationId)}${
          req.queryString || ''
        }`,
        method: 'GET'
      })
    }),
    getPaymentLinksAndModes: builder.query<
      HTTPResponse<any>,
      { organizationId: string; queryString: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.DASHBOARD.GET_PAYMENT_LINKS_AND_MODES(
          req.organizationId
        )}${req.queryString || ''}`,
        method: 'GET'
      })
    }),
    getPaymentOverview: builder.query<
      HTTPResponse<any>,
      { orgId: string; queryString: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.DASHBOARD.GET_PAYMENT_OVERVIEW(req.orgId)}${
          req.queryString
        }`,
        method: 'GET'
      })
    })
  })
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useLazyGetBranchRevenueQuery,
  useLazyGetGroupPerfomanceQuery,
  useLazyGetPaymentLinksAndModesQuery,
  useLazyGetPaymentOverviewQuery
} = extendedBaseQuery;
