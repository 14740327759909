import { useEffect, useState } from 'react';

import './ProgressBar.css';

interface ProgressBarProps {
  progress: number;
  progressBarcolor: string;
  bgColor: string;
  wrapperStyle?: string;
  progressBarStyle?: string;
  backgroundProgress?: number;
  tooltipId?: string;
}

const ProgressBarWithAnimation = ({
  progress,
  progressBarcolor,
  bgColor,
  wrapperStyle,
  progressBarStyle,
  backgroundProgress = 100,
  tooltipId = ''
}: ProgressBarProps) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const styles = {
    progressWrapperStyle: {
      backgroundColor: bgColor,
      width: `${backgroundProgress}%`
    },
    progressBarStyle: {
      width: `${progress}%`,
      backgroundColor: progressBarcolor
    }
  };

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  return (
    <div className="flex h-2 w-full overflow-hidden">
      <div
        style={styles.progressWrapperStyle}
        className={`h-full rounded-full transition-all duration-500 ease-out ${wrapperStyle} ${
          isMounted ? 'progress-animation' : 'hidden'
        }`}
        data-tooltip-id={tooltipId}
      >
        <div
          style={styles.progressBarStyle}
          className={`h-full rounded-full ${progressBarStyle}`}
        />
      </div>
    </div>
  );
};

export default ProgressBarWithAnimation;
