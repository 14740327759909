import { TabPill } from 'types/tabs';
import { Step } from 'types/vertical-stepper';

import { SortDropdownOption } from './components/event-sort-dropdown';
import { Question, QuestionType } from './types';

export enum EventListTab {
  ALL,
  ONGOING,
  COMPLETED,
  CANCELLED
}

export const eventTabs: TabPill[] = [
  { id: EventListTab.ALL, label: 'All' },
  { id: EventListTab.ONGOING, label: 'Ongoing' },
  { id: EventListTab.COMPLETED, label: 'Completed' },
  { id: EventListTab.CANCELLED, label: 'Cancelled' }
];

export const eventSortOptions: SortDropdownOption[] = [
  {
    label: 'Start Date',
    value: 'start_date',
    subOptions: [
      { label: 'Asc', optionLabel: 'Ascending', value: 'asc' },
      { label: 'Desc', optionLabel: 'Descending', value: 'desc' }
    ]
  },
  { label: 'Registration Close Date', value: 'registration_end_date' },
  { label: 'Registration Start Date', value: 'registration_start_date' },
  { label: 'Alphabetically (A-Z)', value: 'name' }
];

export const eventAttendeTableColumn = [
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Mobile Number',
    accessor: 'mobile'
  },
  {
    Header: 'Booking ID',
    accessor: 'bookingId'
  },
  // { Header: 'Quantity', accessor: 'quantity' },
  { Header: 'Ticket Name', accessor: 'ticketName' },
  { Header: 'Ticket Price', accessor: 'ticketPrice' },
  { Header: 'Payment Status', accessor: 'paymentStatus' },
  { Header: 'Check In Status', accessor: 'checkInStatus' }
];

export const InputTypeConfig = {
  [QuestionType.TEXT]: {
    label: 'Text Input',
    icon: 'text',
    placeholder: 'Enter your answer here'
  },
  [QuestionType.TEXT_AREA]: {
    label: 'Text Area',
    icon: 'text-area',
    placeholder: 'Enter your answer here'
  },
  [QuestionType.NUMBER]: {
    label: 'Number',
    icon: 'number-input',
    placeholder: 'Enter your answer here'
  },

  [QuestionType.MOBILE_NUMBER]: {
    label: 'Mobile Number',
    icon: 'number-input',
    placeholder: 'Enter your answer here'
  },
  [QuestionType.SINGLE_CHOICE]: {
    label: 'Single Choice',
    icon: 'single-choice',
    placeholder: 'Enter your answer here'
  },
  [QuestionType.MULTIPLE_CHOICE]: {
    label: 'Multiple Choice',
    icon: 'multiple-choice',
    placeholder: 'Enter your answer here'
  },
  [QuestionType.DROP_DOWN]: {
    label: 'Drop Down',
    icon: 'down',
    placeholder: 'Enter your answer here'
  },
  [QuestionType.DATE]: {
    label: 'Date',
    icon: 'calendar',
    placeholder: 'Enter your answer here'
  },
  [QuestionType.FILE_UPLOAD]: {
    label: 'File Upload',
    icon: 'upload-file-neutral',
    placeholder: 'Enter your answer here'
  }
};

export const maxNumberOfOptions = 15;

export const maxFileSize = 10;
export const minFileSize = 1;

export const textAreaMaxLength = 500;

export const EVENT_CREATION_STEPS: Step[] = [
  {
    id: 'details',
    label: 'Event Details',
    completed: false,
    hasSubsteps: true,
    substeps: [
      {
        id: 'basic',
        label: 'Basic Details',
        completed: false
      },
      { id: 'other', label: 'Other Details', completed: false }
    ]
  },
  {
    id: 'tickets',
    label: 'Tickets',
    completed: false,
    hasSubsteps: false
  },
  {
    id: 'questions',
    label: 'Add Form Questions',
    completed: false,
    hasSubsteps: false
  },
  {
    id: 'preview',
    label: 'Preview & Publish',
    completed: false,
    hasSubsteps: false
  }
];

export const defaultQuestions: Question[] = [
  {
    id: '1',
    question: 'Name',
    type: QuestionType.TEXT,
    required: true,
    questionMetaData: null,
    isDefault: true,
    displayOrder: 1
  },
  {
    id: '2',
    question: 'Mobile Number',
    type: QuestionType.MOBILE_NUMBER,
    required: true,
    questionMetaData: null,
    isDefault: true,
    displayOrder: 2
  }
];

export const defaultQuestionNames = defaultQuestions.map(
  (question) => question.question
);
