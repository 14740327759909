import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { EVENTS } from '@constants/routes';

import AttendeeDetails from './pages/attendee-details/AttendeeDetails';

const Events = lazy(
  () => import('@features/event-registration/pages/events-home/Events')
);

const EventDetails = lazy(
  () => import('@features/event-registration/pages/event-details/EventDetails')
);

const CreateEvent = lazy(
  () => import('@features/event-registration/pages/create-event/CreateEvent')
);

const CreateEventSuccess = lazy(
  () =>
    import(
      '@features/event-registration/pages/create-event-success/CreateEventSuccess'
    )
);

export const EventRoutes: RouteObject[] = [
  {
    path: EVENTS.HOME,
    element: <Events />
  },
  {
    path: EVENTS.CREATE,
    element: <CreateEvent />
  },
  {
    path: EVENTS.EDIT,
    element: <CreateEvent />
  },
  { path: EVENTS.EVENT_DETAILS, element: <EventDetails /> },
  {
    path: EVENTS.SUCCESS,
    element: <CreateEventSuccess />
  },
  {
    path: EVENTS.ATTENDEE_DETAILS,
    element: <AttendeeDetails />
  }
];

export default EventRoutes;
