import clsx from 'clsx';

import { RadioCardProps } from '@types';
import { getAccountLabel, getIconName } from '@utils/authentication';

const RadioCard = ({
  isSelected,
  type,
  description,
  accountLabelStyle,
  radioCardStyle,
  handleClick,
  showIcon = true
}: RadioCardProps) => {
  const accountLabel = getAccountLabel[type];

  const getAccountLabelStyle = () => {
    let style: string = '';
    if (description) style = 'font-bold text-content text-base';
    else if (isSelected) style = 'font-medium text-sm';
    else style = 'text-grey font-medium text-sm';
    return style;
  };

  const IconComponent = showIcon
    ? getIconName[type as keyof typeof getIconName]
    : null;

  return (
    <div
      className={clsx(
        'min-h-16.5 w-full cursor-pointer rounded-xl border border-greyStroke p-4',
        isSelected ? 'border-primary' : '',
        !description ? 'flex flex-col justify-center' : '',
        radioCardStyle || ''
      )}
      role="presentation"
      onClick={handleClick}
    >
      {showIcon && IconComponent ? (
        <div className="flex w-full items-center gap-x-3">
          <IconComponent
            className={clsx(
              'rounded-full',
              isSelected
                ? `bg-primary text-theme`
                : `border border-grey bg-theme text-grey`
            )}
          />
          <div
            className={clsx(
              isSelected ? 'text-primary' : '',
              getAccountLabelStyle(),
              accountLabelStyle || ''
            )}
          >
            {accountLabel}
          </div>
        </div>
      ) : (
        <div className="flex w-full items-center justify-between">
          <div
            className={clsx(
              isSelected ? 'text-primary' : '',
              getAccountLabelStyle(),
              accountLabelStyle || ''
            )}
          >
            {accountLabel}
          </div>
          <div>
            <input
              type="radio"
              onChange={() => {}}
              value={accountLabel}
              checked={isSelected}
              className="accent-green-700"
            />
          </div>
        </div>
      )}

      {description && (
        <div
          className={`mt-3 text-xs font-normal leading-5 ${
            isSelected ? 'text-primary' : 'text-grey'
          }`}
        >
          {description}
        </div>
      )}
    </div>
  );
};

export default RadioCard;
