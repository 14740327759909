import { ChangeEventHandler, FocusEventHandler } from 'react';
import clsx from 'clsx';

import { inputStyles } from '@components/input/input.config';
import Label from '@components/label/Label';

interface TextAreaProps {
  value?: string;
  onChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  rows?: number;
  error?: boolean;
  errorMessage?: string;
  inputStyle?: string;
  maxLength?: number;
  handleChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
}

const TextArea = ({
  value,
  onChange,
  label,
  placeholder,
  disabled,
  className = '',
  rows = 4,
  error,
  errorMessage,
  inputStyle = '',
  maxLength,
  handleChange,
  onBlur
}: TextAreaProps) => (
  <div
    className={`relative ${disabled ? 'cursor-not-allowed' : ''} ${className}`}
  >
    {label && <Label label={label} className="!font-medium" isRequired />}
    <textarea
      value={value}
      onChange={(e) => {
        handleChange?.(e);
        onChange?.(e.target.value);
      }}
      onBlur={onBlur}
      placeholder={placeholder}
      disabled={disabled}
      maxLength={maxLength}
      rows={rows}
      className={clsx(
        inputStyles.common,
        'disabled:cursor-not-allowed disabled:bg-gray-50',
        'max-h-40 resize-none',
        error ? inputStyles.error : inputStyles.common,
        inputStyle,
        disabled ? 'pointer-events-none' : ''
      )}
    />
    {error && errorMessage && (
      <div className="absolute -bottom-5 mt-2 flex items-center text-xs text-error">
        {errorMessage}
      </div>
    )}
  </div>
);

export default TextArea;
