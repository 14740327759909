import {
  // AttendanceManagerIcon,
  BankIcon,
  // BranchAdminIcon,
  // EventManagerIcon,
  // FinanceAdminIcon,
  // GroupAdminIcon,
  IndividualIcon,
  // OrgAdminIcon,
  OrganizationIcon,
  UpiIcon
} from '@icons';

import { RadioCardType } from '@types';

export const getIconName = {
  [RadioCardType.INDIVIDUAL]: IndividualIcon,
  [RadioCardType.ORGANIZATION]: OrganizationIcon,
  [RadioCardType.BANK]: BankIcon,
  [RadioCardType.UPI]: UpiIcon
  // [RadioCardType.ORGANISATION_ADMIN]: OrgAdminIcon,
  // [RadioCardType.BRANCH_ADMIN]: BranchAdminIcon,
  // [RadioCardType.GROUP_ADMIN]: GroupAdminIcon,
  // [RadioCardType.FINANCE_ADMIN]: FinanceAdminIcon,
  // [RadioCardType.ATTENDANCE_MANAGER]: AttendanceManagerIcon,
  // [RadioCardType.EVENT_MANAGER]: EventManagerIcon
};

export const getAccountLabel = {
  [RadioCardType.INDIVIDUAL]: 'Individual',
  [RadioCardType.ORGANIZATION]: 'Organization',
  [RadioCardType.BANK]: 'Bank Account',
  [RadioCardType.UPI]: 'UPI ID',
  [RadioCardType.ORGANISATION_ADMIN]: 'Organization Admin',
  [RadioCardType.BRANCH_ADMIN]: 'Branch Admin',
  [RadioCardType.GROUP_ADMIN]: 'Group Admin',
  [RadioCardType.FINANCE_ADMIN]: 'Finance',
  [RadioCardType.ATTENDANCE_MANAGER]: 'Attendance Manager',
  [RadioCardType.EVENT_MANAGER]: 'Event Manager',
  [RadioCardType.PAYMENT_MANAGER]: 'Payment Manager'
};
