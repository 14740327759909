import {
  AtendeeDetailsPayload,
  EditEventProps,
  EventStatusChangeMutationProps,
  EventTicketStatusChangePayload,
  OrgAndBranchId,
  OrgBranchEventIds,
  OrgBranchGroupIds,
  OrgBranchGroupMemberIds,
  OrgBranchMemberIds,
  OrgBranchOrderIds,
  UpdateGroupStatusParams
} from '@types';

export const API_URL = process.env.REACT_APP_API_URL;
export const END_POINTS = {
  APP_CONFIG: 'v1/config',
  AUTH: {
    GENERATE_OTP: 'v1/auth/generate-otp',
    VALIDATE_OTP: 'v1/auth/validate-otp',
    REFRESH: 'v1/auth/refresh-token',
    LOGOUT: 'v1/user',
    GET_USER_DETAILS: (userId: string) => `v1/user/${userId}`,
    VERIFY_UPI: (userId: string) => `v1/user/${userId}/verify-upi`,
    VERIFY_BANK_DETAILS: (userId: string) =>
      `v1/user/${userId}/verify-bank-account`,
    ONBOARD_USER: (userId: string) => `v1/user/${userId}/onboard`,
    GET_ALL_USER_INVITATIONS: 'v1/invitations',
    VERIFY_PAN: (orgId: string) => `v1/organisation/${orgId}/verify-pan`,
    GET_ROLES_AND_PERMISSIONS: (orgId: string) =>
      `v1/organisation/${orgId}/role_permissions`,
    ONBOARD_ORGANIZATION: (orgId: string) => `v1/organisation/${orgId}/onboard`,
    UPDATE_INVITATION: (inviteId: string) => `v1/invitations/${inviteId}`,
    VERIFY_GSTIN: (orgId: string) => `v1/organisation/${orgId}/verify-gstin`,
    UPGRADE_LITE: (orgId: string) => `v1/organisation/${orgId}/kyc/submit`,
    GET_ALL_PLANS: (orgId: string) => `v1/organisation/${orgId}/plans`,
    ADD_SUBSCRIPTION: (orgId: string) =>
      `v1/organisation/${orgId}/subscription`,
    REFERRAL_CODE: (orgId: string) =>
      `v1/organisation/${orgId}/process-referral-code`,
    REFERRAL_STATUS: (orgId: string) =>
      `v1/organisation/${orgId}/referral-join-status`
  },
  GROUP_MANAGEMENT: {
    GET_ALL_GROUPS_WO_PAGINATION: ({
      organizationId,
      branchId
    }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}/groups`,
    GET_ALL_GROUPS: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group`,
    GET_GROUP_MEMBERS: ({
      organizationId,
      branchId,
      groupId
    }: OrgBranchGroupIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}/members`,
    DELETE_MEMBER: ({ organizationId, branchId, groupId }: OrgBranchGroupIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}`,
    UPDATE_MEMBER: ({
      organizationId,
      branchId,
      groupId,
      memberId
    }: OrgBranchGroupMemberIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}/member/${memberId}`,
    UPDATE_MEMBER_STATUS: ({
      organizationId,
      branchId,
      groupId,
      memberId
    }: OrgBranchGroupMemberIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}/member/${memberId}/status`,
    GET_GROUP_BY_ID: ({
      organizationId,
      branchId,
      groupId
    }: OrgBranchGroupIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}`,
    UPDATE_GROUP_STATUS: ({
      organizationId,
      branchId,
      groupId,
      status
    }: UpdateGroupStatusParams) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}/${status}`,
    CREATE_GROUP: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v4/organisation/${organizationId}/branch/${branchId}/group`,
    UPDATE_GROUP: ({ organizationId, branchId, groupId }: OrgBranchGroupIds) =>
      `v4/organisation/${organizationId}/branch/${branchId}/group/${groupId}`,
    DOWNLOAD_REPORT: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}/payment-order/report`,
    CREATE_MEMBER_IN_EXISTING_GROUP: ({
      organizationId,
      branchId,
      groupId
    }: OrgBranchGroupIds) =>
      `v2/organisation/${organizationId}/branch/${branchId}/group/${groupId}`,
    GET_ALL_PRIMARY_NUMBERS: ({
      organizationId,
      branchId,
      groupId
    }: OrgBranchGroupIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}/members/contact_numbers`,
    GET_ORGANIZATION_CONFIG: (organizationId: string) =>
      `v1/organisation/${organizationId}/config`,
    GET_ORGANIZATION_FEATURE_FLAGS: (organizationId: string) =>
      `v1/organisation/${organizationId}/feature`,
    GET_MEMBER_BY_ID: ({
      organizationId,
      branchId,
      groupId,
      memberId
    }: OrgBranchGroupMemberIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}/member/${memberId}`,
    GET_GROUP_MEMBER_DETAILS: ({
      organizationId,
      branchId,
      groupId
    }: OrgBranchGroupIds) =>
      `v2/organisation/${organizationId}/branch/${branchId}/group/${groupId}/members/contact_numbers`,
    GET_GROUP_TRANSACTIONS: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}/payment-order`,
    SEND_GROUP_REMINDER: ({
      organizationId,
      branchId,
      groupId
    }: OrgBranchGroupIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}/payment-order/remind-all`,
    CHECK_MEMBER_PAYMENT_LINK_SENT: ({
      organizationId,
      branchId,
      groupId,
      memberId
    }: OrgBranchGroupMemberIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}/member/${memberId}/check-payment-link-sent`,
    CHECK_GROUP_PAYMENT_LINK_SENT: ({
      organizationId,
      branchId,
      groupId
    }: OrgBranchGroupIds) =>
      `v4/organisation/${organizationId}/branch/${branchId}/group/${groupId}/check-payment-link-sent`
  },
  UPLOAD: {
    IMAGEUPLOAD: 'v1/image/upload',
    DELETE_IMAGE: 'v1/image/delete'
  },
  BRANCH_MANAGEMENT: {
    GET_ALL_BRANCHES: (organizationId: string) =>
      `v1/organisation/${organizationId}/branch`,
    UPDATE_BRANCH: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}`,
    CREATE_BRANCH: (organizationId: string) =>
      `v1/organisation/${organizationId}/branch`,
    ONBOARD_BRANCH: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}/onboard`,
    GET_BRANCH_BY_ID: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}`
  },

  TEAM_MANAGEMENT: {
    INVITE_MEMBER: (organizationId: string) =>
      `v1/organisation/${organizationId}/invitations`,
    GET_ROLE_COUNT: (organizationId: string) =>
      `v1/organisation/${organizationId}/user-roles/count`,
    GET_ALL_ORGANIZATION_INVITED_MEMBERS: (organizationId: string) =>
      `v1/organisation/${organizationId}/invited_members`,
    GET_ALL_ORGANIZATION_MEMBERS: (organizationId: string) =>
      `v1/organisation/${organizationId}/members`,
    DELETE_ORGANIZATION_MEMBER: (
      organizationId: string,
      user_org_role_Id: string
    ) => `v1/organisation/${organizationId}/user-role/${user_org_role_Id}`,
    EDIT_ROLE: (organizationId: string, user_org_role_Id: string) =>
      `v1/organisation/${organizationId}/user-role/${user_org_role_Id}`,
    GET_USER_ROLE_DETAILS: (organizationId: string, user_org_role_Id: number) =>
      `v1/organisation/${organizationId}/user-role/${user_org_role_Id}`,
    DELETE_UNACCEPTED_INVITATION: (
      organizationId: string,
      invitationId: string
    ) => `v1/organisation/${organizationId}/invitations/${invitationId}`
  },
  ORGANIZATION: {
    GET_ORGANIZATION_LIST: 'v1/organisation',
    GET_ORGANIZATION_ACCOUNT_DETAILS: (organizationId: string) =>
      `v1/organisation/${organizationId}/account-detail`,
    CREATE_ORGANIZATION: 'v1/organisation',
    GET_REUPLOAD_LIST: (organizationId: string) =>
      `v1/organisation/${organizationId}/documents`,
    UPDATE_ORGANIZATION_BY_ID: (organizationId: string) =>
      `v1/organisation/${organizationId}`,
    UPDATE_ORG_CONFIG: (organizationId: string) =>
      `v1/organisation/${organizationId}/config`,
    GET_ALL_WEBHOOKS: 'v1/integrations/trigger/list',
    GET_ALL_SUPPORTED_EVENTS: 'v1/integrations/trigger/supported-events',
    CREATE_WEBHOOK: 'v1/integrations/trigger',
    GET_WEBHOOK_BY_ID: (webhookId: string) =>
      `v1/integrations/trigger/${webhookId}`,
    UPDATE_WEBHOOK: (webhookId: string) =>
      `v1/integrations/trigger/${webhookId}`,
    DELETE_WEBHOOK: (webhookId: string) =>
      `v1/integrations/trigger/${webhookId}`,
    GET_ALL_API_KEYS: 'v1/integrations/org-app/list',
    UPDATE_API_KEY: (apiId: string) => `v1/integrations/org-app/${apiId}`,
    CREATE_API_KEY: 'v1/integrations/org-app',
    DELETE_API_KEY: (apiId: string) => `v1/integrations/org-app/${apiId}`
  },
  ADMIN: {
    GET_ORGANIZATION_LIST: 'v1/admin/organisations',
    GET_ORGANIZATION_DOCUMENTS: (organizationId: string) =>
      `v1/organisation/${organizationId}/documents`,
    GET_ROLES_AND_PERMISSIONS: 'v1/admin/role_permissions',
    UPDATE_ORGANIZATION_STATUS: (organizationId: string) =>
      `v1/admin/organisation/${organizationId}/status`,
    GET_ORGANIZATION_BY_ID: (organizationId: string) =>
      `v1/admin/organisation/${organizationId}`,
    UPDATE_ORG_DOC_STATUS: (docId: string) =>
      `v1/admin/document/${docId}/status`,
    GET_ORGANIZATION_CUSTOMER_LIST: `v1/admin/organisation/customers`,
    UPDATE_ORGANIZATION_CUSTOMER_SETTINGS: (orgId: string) =>
      `v1/admin/organisation/${orgId}/details`
  },
  HELP: {
    GET_FAQ_LIST:
      'v1/app-info?include_help_section=true&include_messages_section=false',
    USER_FEEDBACK: (id: string) => `v1/user/${id}/feedback`
  },
  ATTENDANCE: {
    GET_ATTENDANCE_LIST: (
      organizationId: string,
      branchId: string,
      groupId: string
    ) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}/attendance/list`,
    MARK_UNMARK_HOLIDAY: ({
      organizationId,
      branchId,
      groupId
    }: OrgBranchGroupIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}/mark-unmark-holiday`,
    DOWNLOAD_ATTENDANCE_REPORT: ({
      organizationId,
      branchId,
      groupId
    }: OrgBranchGroupIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}/attendance/report`,
    GET_GROUP_MEMBER_DETAILS: ({
      organizationId,
      branchId,
      groupId,
      memberId
    }: OrgBranchGroupMemberIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}/member/${memberId}`,
    UPDATE_MEMBER_ATTENDANCE: ({
      organizationId,
      branchId,
      groupId
    }: OrgBranchGroupIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/group/${groupId}/attendance`
  },
  TRANSACTIONS: {
    GET_PAYMENT_LIST: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}/payment-order`,
    GET_PAYMENT_ORDER_HISTORY: ({
      organizationId,
      branchId,
      orderId
    }: OrgBranchOrderIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/payment-order/${orderId}`,
    MARK_AS_PAID: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v2/organisation/${organizationId}/branch/${branchId}/payment-order/mark-paid`,
    SEND_PAYMENT_REMINDER: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}/payment-order/remind`,
    DISABLE_PAYMENT_ORDER: ({
      organizationId,
      branchId,
      orderId
    }: OrgBranchOrderIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/payment-order/${orderId}/disable`,
    DOWNLOAD_PAYMENT_RECEIPT: (orderId: string) => `p/${orderId}/download`,
    GET_PAYMENT_DETAILS_BY_ID: ({
      organizationId,
      branchId,
      orderId
    }: OrgBranchOrderIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/payment-order/${orderId}/details`,
    ENABLE_PARTIAL_PAYMENT: ({
      organizationId,
      branchId,
      orderId
    }: OrgBranchOrderIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/payment-order/${orderId}/split-payment/enable`,
    CLOSE_PAYMENT: ({ organizationId, branchId, orderId }: OrgBranchOrderIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/payment-order/${orderId}/close`
  },
  DASHBOARD: {
    GET_BRANCH_REVENUE: (organizationId: string) =>
      `v1/organisation/${organizationId}/branch-revenue`,
    GET_GROUP_PERFOMANCE: (organizationId: string) =>
      `v1/organisation/${organizationId}/group-performance`,
    GET_PAYMENT_LINKS_AND_MODES: (organizationId: string) =>
      `v1/organisation/${organizationId}/payment-links-modes`,
    GET_PAYMENT_OVERVIEW: (orgId: string) =>
      `v1/organisation/${orgId}/payment-summary`
  },
  SUBSCRIPTION: {
    GET_CURRENT_SUBSCRIPTION: (organizationId: string) =>
      `v1/organisation/${organizationId}/subscription`,
    GET_SUBSCRIPTION_PLANS: (organizationId: string) =>
      `v1/organisation/${organizationId}/plans`,
    SELECT_SUBSCRIPTION: (organizationId: string) =>
      `v1/organisation/${organizationId}/subscription`
  },
  REPORTS: {
    GST_REPORT: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}/payment-order/gst-report`,
    REPORTS: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}/payment-order/custom-report`
  },
  CREATE_QUICK_COLLECT_LINK: (organizationId: string, branchId: string) =>
    `v1/organisation/${organizationId}/branch/${branchId}/payment-order/instant-link`,
  MEMBER_MANAGEMENT: {
    GET_MEMBERS: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}/members`,
    GET_MEMBER_DETAILS: ({
      organizationId,
      branchId,
      memberId
    }: OrgBranchMemberIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/member/${memberId}`,
    CREATE_MEMBER: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}/member`,
    UPDATE_MEMBER: ({ organizationId, branchId }: OrgAndBranchId) =>
      `v1/organisation/${organizationId}/branch/${branchId}/member`,
    DELETE_MEMBER: ({
      organizationId,
      branchId,
      memberId
    }: OrgBranchMemberIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/member/${memberId}`
  },
  EVENT: {
    ATTENDEE_LIST: ({ organizationId, branchId, eventId }: OrgBranchEventIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/event/${eventId}/attendee`,
    ATTENDEE_DETAILS: ({
      organizationId,
      branchId,
      eventId,
      attendeeId
    }: AtendeeDetailsPayload) =>
      `v1/organisation/${organizationId}/branch/${branchId}/event/${eventId}/attendee/${attendeeId}`,
    DETAILS: ({ organizationId, branchId, eventId }: OrgBranchEventIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/event/${eventId}/overview`,
    TICKET_STATUS_CHANGE: ({
      organizationId,
      branchId,
      eventId,
      ticketId,
      status
    }: EventTicketStatusChangePayload) =>
      `v1/organisation/${organizationId}/branch/${branchId}/event/${eventId}/ticket/${ticketId}/${status}`,
    EVENT_FORM_DETAILS: ({ eventId }: { eventId: string }) =>
      `v1/event/${eventId}/form`,
    CREATE: ({ organizationId, branchId }: OrgAndBranchId) =>
      `/v1/organisation/${organizationId}/branch/${branchId}/event`,

    EDIT: ({ organizationId, branchId, eventId }: EditEventProps) =>
      `/v1/organisation/${organizationId}/branch/${branchId}/event/${eventId}`,
    LIST: ({ organizationId, branchId }: OrgAndBranchId) =>
      `/v1/organisation/${organizationId}/branch/${branchId}/event`,
    STATUS_CHANGE: ({
      organizationId,
      branchId,
      eventId,
      status
    }: EventStatusChangeMutationProps) =>
      `v1/organisation/${organizationId}/branch/${branchId}/event/${eventId}/${status}`,
    DOWNLOAD_ATTENDEE_LIST: ({
      organizationId,
      branchId,
      eventId
    }: OrgBranchEventIds) =>
      `v1/organisation/${organizationId}/branch/${branchId}/event/${eventId}/attendee/export`
  }
};
