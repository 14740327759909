import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LongLogoBlack } from '@images';

import { Icon, SidebarItem } from '@components';
import { AccountType, OrgStatus } from '@constants/generic';
import { DASHBOARD, DOCS, GROUPS } from '@constants/routes';
import {
  groupsTabPathPattern,
  INDIVIDUAL_MENU_ITEMS,
  INDIVIDUAL_PROFILE_ITEMS,
  ORG_MENU_ITEMS,
  ORG_PROFILE_ITEMS,
  PROFILE_ITEM_PATHS
} from '@constants/sideMenu';
import useCheckPermission from '@hooks/checkPermission';
import useOnClickOutside from '@utils/hooks';
import { Org } from 'types/organization';

import AccountModals from './AccountModals';
import ProfileOptions from './ProfileOptions';

interface SideNavBarProps {
  orgList: Org[];
  subscriptionData: any;
}

const SideNavBar = (props: SideNavBarProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isProfileList = PROFILE_ITEM_PATHS.some((path) =>
    location.pathname.startsWith(path)
  );

  const { orgList, subscriptionData } = props;

  const ref = useRef(null);
  const { currentOrg, accountType } = useSelector((state: any) => state.user);
  const { rolesAndPermissions } = useSelector((state: any) => state.user);
  const { orgFeatureFlags } = useSelector((state: any) => state.user);
  const { currentBranchFilter } = useSelector((state: any) => state.branch);

  const [accountMethods, setAccountMethods] = useState<any>(null);

  useOnClickOutside(ref, (e) => {
    e.stopPropagation();
  });

  const { checkPermission } = useCheckPermission();

  const orgMenuItems = isProfileList ? ORG_PROFILE_ITEMS : ORG_MENU_ITEMS;
  const individualMenuItems = isProfileList
    ? INDIVIDUAL_PROFILE_ITEMS
    : INDIVIDUAL_MENU_ITEMS;

  const menuItems =
    accountType.type === AccountType.ORGANIZATION
      ? orgMenuItems
      : individualMenuItems;

  const permittedSideNavItems = (menuItems as any)?.filter(
    (navItem: any) =>
      !navItem?.permissions ||
      navItem?.permissions.some((permission: any) =>
        checkPermission([permission])
      )
  );

  const isExcludedSideNavItem = (id: string) => {
    if (id === 'subscriptions')
      return (
        currentOrg?.status === OrgStatus.VERIFICATION_PENDING ||
        currentOrg?.status === OrgStatus.VERIFICATION_SUBMITTED
      );

    if (id === 'event-registration')
      return !(orgFeatureFlags && orgFeatureFlags?.includes('events'));

    if (id === 'docs')
      return !(orgFeatureFlags && orgFeatureFlags.includes('api_integrations'));

    return false;
  };

  const backNavigation = () => {
    navigate(
      accountType.type === AccountType.ORGANIZATION ? DASHBOARD : GROUPS.HOME
    );
  };

  const renderSidebarElement = () =>
    permittedSideNavItems.map((item: any) => {
      if (isExcludedSideNavItem(item.id)) return undefined;
      const { id, route } = item;
      let isCurrentRoute = false;
      let path = route;
      if (id === 'groups') {
        path = GROUPS.HOME;
        isCurrentRoute = groupsTabPathPattern.test(location.pathname);
      } else if (!groupsTabPathPattern.test(location.pathname)) {
        isCurrentRoute = location.pathname.startsWith(route);
      }
      return (
        <SidebarItem
          item={item}
          path={path}
          isCurrentRoute={isCurrentRoute}
          key={id}
          branchId={currentBranchFilter?.branchId}
        />
      );
    });

  const renderProfileListItem = () =>
    permittedSideNavItems.map((item: any) => {
      if (isExcludedSideNavItem(item.id)) return undefined;
      const { id, route } = item;
      const haveCommonParentRoute =
        location.pathname.includes(DOCS.DOCS) && route.includes(DOCS.DOCS);
      const isCurrentRoute = haveCommonParentRoute
        ? true
        : location.pathname.includes(route);

      return (
        <SidebarItem
          item={item}
          path={route}
          isCurrentRoute={isCurrentRoute}
          isOrgLevelItem
          key={id}
        />
      );
    });

  const isSandBoxEnv = process.env.REACT_APP_ENVIRONMENT === 'sandbox';

  return (
    <>
      {!rolesAndPermissions?.permissions?.length ? (
        <div className="group absolute left-0 top-0 z-30 flex h-full w-22 flex-col justify-between bg-theme shadow-sm transition-all duration-300 ease-in-out" />
      ) : (
        <div className="no-scrollbar group absolute left-0 top-0 z-30 flex h-full w-56 flex-col justify-between overflow-auto bg-theme px-2 shadow-sm transition-all duration-300 ease-in-out">
          <div className="flex h-full flex-col justify-between ">
            <div
              className={`flex flex-col gap-3 py-3 ${
                isSandBoxEnv ? 'mt-32' : 'mt-18'
              }`}
            >
              {isProfileList ? (
                <>
                  <Icon
                    className="cursor-pointer"
                    name="back"
                    onClick={backNavigation}
                  />
                  <div className="px-1 text-10 font-bold uppercase text-grey60">
                    Account
                  </div>
                  {renderProfileListItem()}
                  <div className="border-t-content/20 border-t px-1">
                    <ProfileOptions
                      orgList={orgList}
                      accountMethods={accountMethods}
                      subscriptionData={subscriptionData}
                      labelStyle="font-medium text-bgGrey"
                      isProfileList={isProfileList}
                    />
                  </div>
                </>
              ) : (
                renderSidebarElement()
              )}
            </div>

            <div className="flex items-center gap-2 px-6 py-4">
              <LongLogoBlack className="h-8 w-auto" />
            </div>
          </div>
        </div>
      )}
      <AccountModals orgList={orgList} setAccountMethods={setAccountMethods} />
    </>
  );
};

export default SideNavBar;
