import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AvatarWithName from '@components/avatar-with-name/AvatarWithName';
import {
  AccountType,
  currentOrgAvatarColor,
  OrgStatus
} from '@constants/generic';
import {
  INDIVIDUAL_PROFILE_ITEMS,
  ORG_PROFILE_ITEMS
} from '@constants/sideMenu';
import { Event, sendMixpanelEvent } from '@core/analytics';
import useCheckPermission from '@hooks/checkPermission';
import ProfileOptions from '@layouts/side-nav-bar/ProfileOptions';
import { getPhoneNumberWith91 } from '@utils/generic';

import { ProfileSettingsProps } from './types';

const ProfileSettings = (props: ProfileSettingsProps) => {
  const { orgList, subscriptionData, accountMethods } = props;
  const { checkPermission } = useCheckPermission();

  const { currentOrg, currentUser, accountType } = useSelector(
    (state: any) => state.user
  );
  const { orgFeatureFlags } = useSelector((state: any) => state.user);

  const menuItems =
    accountType.type === AccountType.ORGANIZATION
      ? ORG_PROFILE_ITEMS
      : INDIVIDUAL_PROFILE_ITEMS;

  const permittedSideNavItems = (menuItems as any)?.filter(
    (navItem: any) =>
      !navItem?.permissions ||
      navItem?.permissions.some((permission: any) =>
        checkPermission([permission])
      )
  );

  const isExcludedSideNavItem = (id: string) => {
    if (id === 'subscriptions')
      return (
        currentOrg?.status === OrgStatus.VERIFICATION_PENDING ||
        currentOrg?.status === OrgStatus.VERIFICATION_SUBMITTED
      );

    if (id === 'docs')
      return !(orgFeatureFlags && orgFeatureFlags.includes('api_integrations'));

    return false;
  };

  const profileItemClick = (label: string) => {
    sendMixpanelEvent(Event.SIDEBAR_TAB_CLICKED, {
      section: label
    });
  };

  const renderSidebarElement = () =>
    permittedSideNavItems.map((item: any) => {
      if (isExcludedSideNavItem(item.id)) return undefined;
      const { id, route } = item;
      return (
        <Link
          key={id}
          to={route}
          className="flex items-center rounded-md p-2 text-sm font-medium text-theme hover:bg-darkGrey"
          onClick={() => profileItemClick(item.label)}
        >
          {item.label}
        </Link>
      );
    });

  return (
    <div className="absolute right-0 top-16 z-20 w-56 min-w-48 cursor-default overflow-hidden rounded-2xl bg-content px-6 py-2 text-sm font-normal shadow-md">
      <div className="border-b border-b-grey py-3">
        <AvatarWithName
          name={currentUser.name}
          subText={getPhoneNumberWith91(currentUser.mobile)}
          bgColor={currentOrgAvatarColor}
          wrapperClass="flex-col gap-4"
          avatarClass="size-16 min-w-10 text-xl text-white"
          nameClass="!text-sm text-theme font-semibold break-all mb-1"
          subTextClass="text-theme opacity-50"
        />
      </div>
      <div className="border-b border-b-grey py-3">
        {permittedSideNavItems && renderSidebarElement()}
      </div>
      <ProfileOptions
        orgList={orgList}
        accountMethods={accountMethods}
        subscriptionData={subscriptionData}
        labelStyle="font-medium text-theme hover:bg-darkGrey"
        isProfileList={false}
      />
    </div>
  );
};

export default ProfileSettings;
