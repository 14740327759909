import { END_POINTS } from '@config';
import ApiTags from '@constants/api-tags';
import { api } from '@core/baseApi';
import {
  Branch,
  HTTPResponse,
  OrgAndBranchId,
  UpdateBranchRequest
} from '@types';

const extendedBaseQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllBranches: builder.query<
      HTTPResponse<Branch>,
      { organizationId: string; queryString?: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.BRANCH_MANAGEMENT.GET_ALL_BRANCHES(
          req.organizationId
        )}${req.queryString || ''}`,
        method: 'GET'
      }),
      providesTags: [ApiTags.BRANCHES]
    }),
    createBranch: builder.mutation<HTTPResponse<any>, any>({
      query: (req) => ({
        url: END_POINTS.BRANCH_MANAGEMENT.CREATE_BRANCH(req.organizationId),
        method: 'POST',
        body: req.body
      })
    }),
    onboardBranch: builder.mutation<HTTPResponse<any>, any>({
      query: (req) => ({
        url: END_POINTS.BRANCH_MANAGEMENT.ONBOARD_BRANCH(req.params),
        method: 'PUT',
        body: req.body
      })
    }),
    updateBranch: builder.mutation<
      HTTPResponse<any>,
      { params: OrgAndBranchId; body: UpdateBranchRequest }
    >({
      query: (reqBody) => ({
        url: END_POINTS.BRANCH_MANAGEMENT.UPDATE_BRANCH(reqBody.params),
        method: 'PATCH',
        body: reqBody.body
      })
    }),
    getOrgAccountDetails: builder.query<
      HTTPResponse<Branch>,
      { organizationId: string; queryString: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.ORGANIZATION.GET_ORGANIZATION_ACCOUNT_DETAILS(
          req.organizationId
        )}${req.queryString}`,
        method: 'GET'
      })
    }),
    getBranchById: builder.query<
      HTTPResponse<any>,
      { params: OrgAndBranchId; queryString: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.BRANCH_MANAGEMENT.GET_BRANCH_BY_ID(req.params)}${
          req.queryString
        }`,
        method: 'GET'
      })
    })
  })
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useLazyGetAllBranchesQuery,
  useUpdateBranchMutation,
  useCreateBranchMutation,
  useOnboardBranchMutation,
  useGetOrgAccountDetailsQuery,
  useLazyGetOrgAccountDetailsQuery,
  useLazyGetBranchByIdQuery
} = extendedBaseQuery;
