import { END_POINTS } from '@config';
import ApiTags from '@constants/api-tags';
import { api } from '@core/baseApi';
import {
  CreateEventRequest,
  CreateEventResponse,
  HTTPResponse,
  OrgAndBranchId,
  OrgBranchEventIds,
  WithBody,
  WithQueryParams
} from '@types';
import { buildQueryString } from '@utils/queryStringBuilder';
import {
  AtendeeDetailsPayload,
  AttendeeDetailsResponse,
  EditEventProps,
  EventDetailsResponse,
  EventFormDetails,
  EventListResponse,
  EventStatusChangeMutationProps,
  EventTicketStatusChangePayload
} from 'types/event';

import {
  convertFromAttendeeDetailsResponse,
  convertFromEventDetailsResponse
} from '../helper';
import { AttendeeDetails, EventDetails } from '../types';

const extendedBaseQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    getEventAttendeeList: builder.query<
      HTTPResponse<any>,
      { params: OrgBranchEventIds; queryString: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.EVENT.ATTENDEE_LIST(req.params)}${req.queryString}`,
        method: 'GET'
      })
    }),
    getAttendeeDetails: builder.query<AttendeeDetails, AtendeeDetailsPayload>({
      query: (payload) => ({
        url: END_POINTS.EVENT.ATTENDEE_DETAILS(payload),
        method: 'GET'
      }),
      transformResponse: (response: AttendeeDetailsResponse) =>
        convertFromAttendeeDetailsResponse(response)
    }),
    createEvent: builder.mutation<
      CreateEventResponse,
      WithBody<CreateEventRequest, OrgAndBranchId>
    >({
      query: ({ organizationId, branchId, body }) => ({
        url: END_POINTS.EVENT.CREATE({
          organizationId,
          branchId
        }),
        body,
        method: 'POST'
      })
    }),
    getEventList: builder.query<
      EventListResponse,
      WithQueryParams<
        OrgAndBranchId,
        'page' | 'limit' | 'sort_by' | 'sort_order' | 'status' | 'search'
      >
    >({
      query: ({ organizationId, branchId, queryParams }) => ({
        url: `${END_POINTS.EVENT.LIST({
          organizationId,
          branchId
        })}${buildQueryString(queryParams)}`,
        method: 'GET'
      }),
      providesTags: [ApiTags.EVENT_LIST]
    }),
    getEventDetails: builder.query<EventDetails, OrgBranchEventIds>({
      query: ({ organizationId, branchId, eventId }) => ({
        url: END_POINTS.EVENT.DETAILS({ organizationId, branchId, eventId }),
        method: 'GET'
      }),
      transformResponse: (response: EventDetailsResponse) =>
        convertFromEventDetailsResponse(response)
    }),
    updateTicketStatus: builder.mutation<any, EventTicketStatusChangePayload>({
      query: (payload) => ({
        url: END_POINTS.EVENT.TICKET_STATUS_CHANGE(payload),
        method: 'PATCH'
      })
    }),
    deleteEvent: builder.mutation<
      any,
      Omit<EventStatusChangeMutationProps, 'status'>
    >({
      query: ({ organizationId, branchId, eventId }) => ({
        url: END_POINTS.EVENT.STATUS_CHANGE({
          organizationId,
          branchId,
          eventId,
          status: 'cancelled'
        }),
        method: 'PATCH'
      }),
      invalidatesTags: [ApiTags.EVENT_LIST]
    }),
    getEventFormDetails: builder.query<
      EventFormDetails,
      { params: { eventId: string } }
    >({
      query: (req) => ({
        url: `${END_POINTS.EVENT.EVENT_FORM_DETAILS(req.params)}`,
        method: 'GET'
      })
    }),
    updateEvent: builder.mutation<
      CreateEventResponse,
      WithBody<CreateEventRequest, EditEventProps>
    >({
      query: ({ organizationId, branchId, eventId, body }) => ({
        url: END_POINTS.EVENT.EDIT({
          organizationId,
          branchId,
          eventId
        }),
        body,
        method: 'PUT'
      }),
      invalidatesTags: [ApiTags.EVENT_LIST]
    }),
    closeRegistration: builder.mutation<
      any,
      Omit<EventStatusChangeMutationProps, 'status'>
    >({
      query: ({ organizationId, branchId, eventId }) => ({
        url: END_POINTS.EVENT.STATUS_CHANGE({
          organizationId,
          branchId,
          eventId,
          status: 'closed'
        }),
        method: 'PATCH'
      }),
      invalidatesTags: [ApiTags.EVENT_LIST]
    }),
    downloadAttendeeDetails: builder.query<
      HTTPResponse<any>,
      { params: OrgBranchEventIds & { include_form_data?: boolean } }
    >({
      query: (req) => ({
        url: `${END_POINTS.EVENT.DOWNLOAD_ATTENDEE_LIST(
          req.params
        )}?include_form_data=${req.params.include_form_data}`,
        method: 'GET',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseHandler: (res) => res.blob()
      })
    })
  })
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useLazyGetEventAttendeeListQuery,
  useLazyGetAttendeeDetailsQuery,
  useCreateEventMutation,
  useLazyGetEventListQuery,
  useDeleteEventMutation,
  useLazyGetEventDetailsQuery,
  useUpdateTicketStatusMutation,
  useLazyGetEventFormDetailsQuery,
  useUpdateEventMutation,
  useCloseRegistrationMutation,
  useLazyDownloadAttendeeDetailsQuery
} = extendedBaseQuery;
