import { RefObject } from 'react';

interface DownloadFileProps {
  data?: any; // for xlsx download
  fileUrl?: string;
  fileName: string;
  type: 'xlsx' | 'image';
}

export const downloadFileFromUrl = (
  url: string,
  fileName: string,
  openInNewTab?: boolean
) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.display = 'none';
  if (openInNewTab) {
    link.target = '_blank';
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const downloadFile = async (params: DownloadFileProps) => {
  let url = params.fileUrl || '';
  if (params.type === 'xlsx' && params.data)
    url = window.URL.createObjectURL(new Blob([params.data]));
  downloadFileFromUrl(url, params.fileName);
};

const base64ToBlob = (base64: any, mime: any) => {
  const byteString = atob(base64);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mime });
};

export const downloadFileFromData = (data: any, fileName: string) => {
  const blob = base64ToBlob(data, 'application/pdf'); // Assuming the file is a PDF
  const url = window.URL.createObjectURL(blob);
  downloadFileFromUrl(url, fileName);
};

export const downloadQRCode = (
  canvasRef: RefObject<HTMLDivElement>,
  fileName: string
) => {
  const canvas = canvasRef?.current?.querySelector('canvas');
  const pngUrl = canvas?.toDataURL('image/png');
  const link = document.createElement('a');
  link.href = pngUrl ?? '';
  link.download = `${fileName.replaceAll(' ', '-')}.png`;
  link.click();
};
