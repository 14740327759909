import { forwardRef } from 'react';
import { Skeleton } from '@mui/material';
import clsx from 'clsx';

import CopyToClipboard from '@components/copy-to-clipboard/CopyToClipboard';
import testId from '@constants/testId';
import { sanitizeNumberInput } from '@utils/generic';
import { validateWithRegexp } from '@utils/validations';
import { InputProps } from 'types/input';

import Icon from '../icon/Icon';
import { inputStyles, labelStyles, sizeStyle } from './input.config';

const FloatingInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    label,
    error = false,
    errorMessage = '',
    className = '',
    inputStyle = '',
    type,
    name,
    placeholder = '',
    value,
    disabled,
    errorStyle = '',
    leftIconName,
    labelRequired = false,
    inputSize = 'medium',
    inputRegex,
    handleInputChange,
    isLoading = false,
    onClearIconAction,
    handleInputBlur,
    inputId,
    leftIconStyle,
    showTooltip,
    textToCopy,
    ...rest
  } = props;
  const inputClassNames = clsx(
    inputStyles.common,
    error ? inputStyles.error : inputStyles.common,
    leftIconName && 'pl-9'
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (rest.onChange) {
      rest.onChange(e);
    } else if (handleInputChange) {
      let inputValue = e.target.value;
      inputValue =
        type === 'number' || type === 'phone'
          ? sanitizeNumberInput(inputValue)
          : inputValue;
      if (type === 'phone' && inputValue !== '') {
        if (inputRegex && validateWithRegexp(inputRegex, inputValue))
          handleInputChange(inputValue);
        else handleInputChange(inputValue);
      } else if (inputValue !== '' && inputRegex) {
        if (inputRegex && validateWithRegexp(inputRegex, inputValue))
          handleInputChange(inputValue);
      } else handleInputChange(inputValue);
    }
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    rest.onBlur?.(e);

    if (handleInputBlur) {
      const inputValue = e.target.value.trim();
      handleInputBlur(inputValue);
    }
  };

  if (isLoading)
    return (
      <div className="w-full">
        <Skeleton
          height={53}
          key={Math.random()}
          animation="wave"
          variant="rounded"
        />
      </div>
    );

  return (
    <div
      className={`relative ${
        disabled ? 'cursor-not-allowed' : ''
      } ${className}`}
    >
      <div className="relative">
        {!!leftIconName && (
          <span
            className={`absolute top-1/2 ml-2.5 -translate-y-1/2 ${leftIconStyle}`}
            onClick={disabled ? () => {} : rest.onClick}
            role="presentation"
            id={`${inputId}-leftIcon`}
          >
            <Icon name={leftIconName} />
          </span>
        )}
        <input
          ref={ref}
          id={name}
          name={name}
          type={type === 'number' ? 'text' : type}
          className={clsx(
            inputClassNames,
            inputStyle,
            sizeStyle[inputSize],
            `no-spinners pl-3 ${disabled ? 'pointer-events-none' : ''}`
          )}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          {...rest}
          onChange={onInputChange}
          onBlur={onInputBlur}
        />
        {onClearIconAction && value && (
          <div
            role="presentation"
            className={`absolute right-3 top-1/2 -translate-y-1/2 ${
              disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
            onClick={disabled ? () => {} : onClearIconAction}
          >
            <Icon name="close" />
          </div>
        )}
        {showTooltip && textToCopy && (
          <div className="absolute right-3 top-1/2 -translate-y-1/2">
            <CopyToClipboard textToCopy={textToCopy} iconColor="text-primary" />
          </div>
        )}
        {labelRequired ? (
          <label className={labelStyles.common} htmlFor={name}>
            {label}
          </label>
        ) : null}
      </div>
      {error && errorMessage && (
        <div
          className={clsx(
            'absolute -bottom-5 mt-2 flex items-center text-xs text-error',
            errorStyle
          )}
          data-testid={testId.input.input_error}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
});

export default FloatingInput;
