import { NoResultImage } from '@images';

import { EmptyScreenProps } from '@types';

import Button from '../button/Button';

const EmptyScreen = ({
  buttonId,
  title = 'Results not found',
  description = 'We couldn’t find what you’re looking for',
  className = '',
  handleClick,
  emptyScreenImage: EmptyScreenImage = NoResultImage,
  buttonLabel,
  leftIconName,
  butttonStyle = 'my-8',
  buttonLabelStyle,
  leftIconStorke,
  variant,
  isButtonDisabled = false
}: EmptyScreenProps) => {
  const buttonVariant = variant || 'secondary';

  return (
    <div
      className={`flex size-full flex-col items-center justify-center ${className}`}
    >
      <EmptyScreenImage />
      {title && <div className="mt-8 text-2xl font-bold">{title}</div>}
      {!!description && (
        <div className="mt-2 text-sm font-normal text-grey ">{description}</div>
      )}
      {buttonLabel && (
        <Button
          id={buttonId}
          variant={buttonVariant}
          label={buttonLabel}
          size="large"
          handleClick={handleClick}
          className={butttonStyle}
          leftIconName={leftIconName}
          labelStyle={buttonLabelStyle}
          leftIconStroke={leftIconStorke}
          disabled={isButtonDisabled}
        />
      )}
    </div>
  );
};

export default EmptyScreen;
