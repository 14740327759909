import { useEffect } from 'react';

import { getLocalStorageItem } from '@utils/storage';
import {
  SemiCircleGuageChart,
  MultiProgressBar,
  Spinner
} from '@components/index';

import { useLazyGetPaymentLinksAndModesQuery } from '../queries';
import {
  formatPaymentLinksData,
  getFormattedDateForChartAPI,
  getPaymentModeData
} from '../helper';
import { DateRangeType } from '../types';

interface PaymentLinksAndModesProps {
  selectedDateRange: DateRangeType;
  selectedBranchId: string;
}

const PaymentLinksAndModes = ({
  selectedDateRange,
  selectedBranchId
}: PaymentLinksAndModesProps) => {
  const organizationId = getLocalStorageItem('organizationId');

  const [
    getPaymentLinksAndModes,
    { data: paymentLinksAndModesData, isLoading, isFetching, isUninitialized }
  ] = useLazyGetPaymentLinksAndModesQuery();

  const getPaymentLinksAndModesData = async () => {
    const { startDate, endDate } =
      getFormattedDateForChartAPI(selectedDateRange);

    await getPaymentLinksAndModes({
      organizationId,
      queryString: `?from_timestamp=${startDate}&to_timestamp=${endDate}&branch_id=${selectedBranchId}`
    });
  };

  const paymentLinkData = formatPaymentLinksData(paymentLinksAndModesData);
  const paymentModeData = getPaymentModeData(paymentLinksAndModesData);

  useEffect(() => {
    getPaymentLinksAndModesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange, selectedBranchId]);

  return (
    <div className="flex flex-wrap gap-4 pb-4 xl:flex-nowrap">
      <div className="relative h-72 w-full rounded-lg bg-white p-6 shadow-md xl:w-1/2 2xl:w-2/5">
        <SemiCircleGuageChart
          title="Payment Links"
          className="w-full"
          chartClassName="w-full absolute top-10"
          chartValues={paymentLinkData}
        />
      </div>
      <div className=" w-full rounded-lg bg-white p-6 shadow-md xl:w-1/2 2xl:w-3/5">
        <div className="flex items-center gap-2">
          <h1 className="text-lg font-bold text-content">Payment Modes</h1>
          {/* <InfoIconTooltip
            id="payment-mode-chart"
            tooltipText="Payment Mode Tooltip"
          /> */}
        </div>
        <div className="flex h-full items-center justify-center">
          {isLoading || isFetching || isUninitialized ? (
            <div className="flex h-50 items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <MultiProgressBar data={paymentModeData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentLinksAndModes;
