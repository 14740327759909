import { useState } from 'react';

import Input from '@components/input/Input';
import Label from '@components/label/Label';
import PaymentCollection from '@components/payment-collection/PaymentCollection';
import { PaymentCollectionType } from '@features/group-management/constants';
import { formatDueDatePlaceholder } from '@features/group-management/helper';
import { PaymentCollectionArg, PaymentCollectionComponentType } from '@types';

type DueDateType = {
  day: string;
  paymentCollectionType: PaymentCollectionType | '';
  interval: string;
};

interface MemberDueDateProps {
  dueDate: DueDateType;
  memberPaymentCollectionType: PaymentCollectionType | '';
  memberInterval: string;
  memberDueDate: string;
  paymentCollectionType: PaymentCollectionType | '';
  groupFrequency: number;
  onDueDateChange: (data: PaymentCollectionArg) => void;
}

const MemberDueDate = ({
  dueDate,
  memberPaymentCollectionType,
  memberInterval,
  memberDueDate,
  paymentCollectionType,
  groupFrequency,
  onDueDateChange
}: MemberDueDateProps) => {
  const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);

  const getSelectedDate = () => {
    if (dueDate) return dueDate.day;
    if (memberDueDate) return memberDueDate;
    return '';
  };

  return (
    <div className="gap-4">
      <Label
        label="Due Date"
        tooltipText="If not given, the due date of group will be taken into account"
        tooltipId="dueDate"
      />
      <Input
        leftIconName="calendar"
        value={
          getSelectedDate()
            ? (formatDueDatePlaceholder(
                dueDate?.paymentCollectionType ||
                  memberPaymentCollectionType ||
                  paymentCollectionType ||
                  PaymentCollectionType.MONTHLY,
                Number(dueDate?.interval) ||
                  Number(memberInterval) ||
                  Number(groupFrequency) ||
                  1,
                getSelectedDate()
              ) as string)
            : ''
        }
        onClick={() => setIsCalendarVisible(true)}
        placeholder="Select Date"
        readOnly
        disabled={paymentCollectionType === PaymentCollectionType.ONCE}
      />

      {isCalendarVisible && (
        <div
          className="fixed left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 rounded-lg border bg-theme shadow-lg"
          id="due-date-calendar"
        >
          <PaymentCollection
            type={paymentCollectionType as PaymentCollectionComponentType}
            isOpen={isCalendarVisible}
            confirmButtonLabel="Confirm"
            confirmButtonStyle="text-primary"
            handleConfirm={(date: PaymentCollectionArg) => {
              onDueDateChange(date);
              setIsCalendarVisible(false);
            }}
            cancelButtonLabel="Cancel"
            handleCancel={() => {
              setIsCalendarVisible(false);
            }}
            selectedDate={getSelectedDate()}
            selectedtype={
              dueDate?.paymentCollectionType ||
              memberPaymentCollectionType ||
              paymentCollectionType ||
              PaymentCollectionType.MONTHLY
            }
            selectedFrequency={
              dueDate?.interval || memberInterval || groupFrequency
            }
            source="member"
          />
        </div>
      )}
    </div>
  );
};

export default MemberDueDate;
