const bankIds = [
  'ybl',
  'paytm',
  'apl',
  'axl',
  'ibl',
  'upi',
  'icici',
  'postbank',
  'kotak',
  'sbi',
  'hdfcbank',
  'idfcbank',
  'axisbank',
  'barodampay',
  'dbs',
  'oksbi',
  'okaxis',
  'okhdfcbank',
  'okicici'
];

export default bankIds;

export const ifscCodeLength = 11;
