import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface BranchState {
  currentBranchFilter: {
    branchId: string;
    branchName: string;
  };
}
const initialState: BranchState = {
  currentBranchFilter: {
    branchId: '',
    branchName: ''
  }
};

export const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    updateBranchFilter: (
      state,
      action: PayloadAction<{ branchId: string; branchName: string }>
    ) => {
      state.currentBranchFilter = action.payload;
    },
    resetBranchState: () => initialState
  }
});

export const { updateBranchFilter, resetBranchState } = branchSlice.actions;
export default branchSlice.reducer;
